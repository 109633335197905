import React, { useRef } from 'react';
import { canvasConstants } from '../Test/canvasUtils';
import { calculateItemPositionAndSize } from './utils';

function SelectionArea({
  selectedUploadImages = [],
  onImageUploadSelection = () => {},
  onImageClick = () => {},
  currentlySelectedImage = null,
  initCanvasSize,
  selectedGsItems = [],
  onGsItemUpdate = () => {},
  currentlySelectedItemIndex,
}) {
  // const availableWidth = initCanvasSize.availableWidth;
  // const availableHeight = initCanvasSize.availableHeight;
  const scale = initCanvasSize.scale;
  const imgRefs = useRef([]);

  return (
    <div className="px-3 py-4 d-flex flex-column">
      <div style={{ flex: 1 }}>
        {selectedUploadImages.length <= 0 && (
          <div className="text-center">Select an image to get started</div>
        )}
        <div className="mt-2 d-flex justify-content-center">
          <label htmlFor="gs-file-upload" className="default-btn">
            Upload Image(s)
          </label>
          <input
            type="file"
            accept="image/*"
            id="gs-file-upload"
            hidden
            multiple
            onChange={(event) => {
              onImageUploadSelection(
                Array.from(event.target.files).map((item) => ({
                  ...item,
                  _originalElement: event.target,
                }))
              );
            }}
          />
        </div>
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(100px,1fr))',
            rowGap: 25,
            padding: '10px 15px',
          }}
        >
          {selectedUploadImages?.map((item, index) => {
            return (
              <div
                key={index}
                role="button"
                style={{
                  border: '1px solid black',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
                onClick={() => {
                  const imageElement = imgRefs.current[index];

                  const imageProps = { isLoaded: false };
                  if (imageElement) {
                    imageProps.width = imageElement.naturalWidth * scale;
                    imageProps.height = imageElement.naturalHeight * scale;
                    imageProps.initScale = scale;
                    imageProps.originalWidth = imageElement.naturalWidth;
                    imageProps.originalHeight = imageElement.naturalHeight;
                  }

                  imageProps.x = 0;
                  imageProps.y = 0;

                  const { x: newX = null, y: newY = null } =
                    calculateItemPositionAndSize(
                      imageProps,
                      initCanvasSize,
                      selectedGsItems
                    );

                  if (!newX && !newY && selectedGsItems.length > 0) {
                    return;
                  }

                  if (newX) {
                    imageProps.x = newX;
                  }

                  if (newY) {
                    imageProps.y = newY;
                  }

                  if (imageProps.width && imageProps.height) {
                    imageProps.ratio = imageProps.width / imageProps.height;
                  }

                  // Calculate its x,y,width and height
                  onImageClick({
                    file: item,
                    type: 'image',
                    ...imageProps,
                    dpi: canvasConstants.CANVAS_DPI,
                  });
                }}
              >
                <img
                  ref={(el) => {
                    imgRefs.current[index] = el;
                  }}
                  src={URL.createObjectURL(item)}
                  style={{ objectFit: 'contain' }}
                  width={100}
                  height={100}
                  alt="selected-images"
                />
              </div>
            );
          })}
        </div>
      </div>
      {/* Selected image edit */}
      {currentlySelectedImage && (
        <div style={{ flex: 1 }}>
          <div className="row">
            <div className="col-6">
              <label>Width (inches): </label>
            </div>
            <div className="col-6">
              <input
                type="number"
                className="w-100"
                min={0}
                step={0.02}
                value={(
                  currentlySelectedImage?.width / currentlySelectedImage.dpi
                ).toFixed(2)}
                onChange={(event) => {
                  const { value } = event.target;
                  const ratio = currentlySelectedImage.ratio;
                  onGsItemUpdate(currentlySelectedItemIndex, {
                    width: value * canvasConstants.CANVAS_DPI,
                    height: (value * canvasConstants.CANVAS_DPI) / ratio,
                  });
                }}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-6">
              <label>Height (inches): </label>
            </div>
            <div className="col-6">
              <input
                type="number"
                min={0}
                step={0.02}
                className="w-100"
                onChange={(event) => {
                  const { value } = event.target;
                  const ratio = currentlySelectedImage.ratio;
                  const updatedDpi = currentlySelectedImage.height / value;
                  onGsItemUpdate(currentlySelectedItemIndex, {
                    height: value * canvasConstants.CANVAS_DPI,
                    width: value * canvasConstants.CANVAS_DPI * ratio,
                    dpi: Math.floor(updatedDpi),
                  });
                }}
                // onChange}
                defaultValue={(
                  currentlySelectedImage?.height / currentlySelectedImage.dpi
                ).toFixed(2)}
              />
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-6">Aspect Ratio(L) :</div>
            <div className="col-6">
              {currentlySelectedImage.ratio.toFixed(2)}
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default SelectionArea;
