import './button.css';

const IconButton = ({
  icon,
  label,
  id = '',
  subtext = '',
  onClick = () => {},
}) => {
  return (
    <label
      htmlFor={id}
      className="icon-btn-container"
      onClick={onClick}
      style={{ maxWidth: 220 }}
    >
      <div className="icon-container" style={{ maxWidth: 48, maxHeight: 48 }}>
        {icon}
        {/* (<UploadIcon size="18" color="black" />) */}
      </div>
      <div className="icon-btn-text-wrapper">
        <div className="icon-btn-label">{label}</div>
        {subtext && (
          <div
            className="icon-btn-subtext"
            style={{
              whiteSpace: 'nowrap',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              maxWidth: 130,
            }}
          >
            {subtext}
          </div>
        )}
      </div>
    </label>
  );
};
export default IconButton;
