import React, { useState } from 'react';
import './gangSheet.css';
import GSViewPortContainer from './GSViewPortContainer';
import SelectionArea from './SelectionArea';

function GangSheetBuilder() {
  const [selectedOptions, setSelectedOptions] = useState({
    size: { width: 22, height: 24 },
  });
  const [redrawTriggerCount, setRedrawTriggerCount] = useState(0);
  const [redrawTriggerCountWithHandle, setRedrawTriggerCountWithHandle] =
    useState(0);
  const [currentlySelectedItemIndex, setCurrentlySelectedItemIndex] =
    useState(null);
  // For saving the init data.
  const [initCanvasSize, setInitCanvasSize] = useState({
    x: 0,
    y: 0,
    availableWidth: 0,
    availableHeight: 0,
    fullWidth: 0,
    fullHeight: 0,
  });
  const [selectedUploadImages, setSelectUploadImages] = useState([]);
  const [selectedGsItems, setSelectedGsItems] = useState([]); //contains images,text and their - width,height,x,y positions

  const updateSelectedGsItem = (index, updateObj) => {
    setSelectedGsItems((prev) =>
      prev.map((item, idx) => {
        if (idx === index) {
          return { ...item, ...updateObj };
        }
        return item;
      })
    );
    triggerRedraw();
  };

  const triggerRedraw = ({ withHandle = false } = { withHandle: false }) => {
    if (withHandle) {
      setRedrawTriggerCountWithHandle((prev) => prev + 1);
      return;
    }

    setRedrawTriggerCount((prev) => prev + 1);
  };

  return (
    <div className="gs-container">
      <SelectionArea
        selectedUploadImages={selectedUploadImages}
        onImageUploadSelection={(files) =>
          setSelectUploadImages((prev) => [...prev, ...files])
        }
        onImageClick={(selectedImage) =>
          setSelectedGsItems((prev) => [...prev, selectedImage])
        }
        selectedGsItems={selectedGsItems}
        currentlySelectedImage={selectedGsItems[currentlySelectedItemIndex]}
        currentlySelectedItemIndex={currentlySelectedItemIndex}
        initCanvasSize={initCanvasSize}
        onGsItemUpdate={updateSelectedGsItem}
      />
      <GSViewPortContainer
        selectedOptions={selectedOptions}
        selectedGsItems={selectedGsItems}
        onCanvasInit={setInitCanvasSize}
        initCanvasSize={initCanvasSize}
        updateGsItem={setSelectedGsItems}
        triggerRedraw={triggerRedraw}
        redrawTriggerCountWithHandle={redrawTriggerCountWithHandle}
        redrawTriggerCount={redrawTriggerCount}
        selectedState={[
          currentlySelectedItemIndex,
          setCurrentlySelectedItemIndex,
        ]}
        onOptionChange={(options) => {
          setSelectedOptions(options);
          triggerRedraw();
        }}
      />
      {/* <div>
        <GangSheetViewPort heightInches={22} widthInches={24} />
      </div> */}
    </div>
  );
}

export default GangSheetBuilder;
