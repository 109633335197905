import { useMutation } from '@tanstack/react-query';
import { saveAs } from 'file-saver';
import React, { useState } from 'react';
import { apiConstants } from '../Constants/apiurl.constant';
import { imageServices } from '../service/image.service';
import { getAllObject } from '../Utils/UtilFunctions';
import { CloseIcon } from './Icons';

function DtfConfirmationModal({
  canvas,
  sheetMap,
  onClose,
  currentSheetName,
  initCanvasMeasures,
}) {
  const [selectedDetails, setSelectedDetails] = useState({
    bgColor: '#ff01ff',
    hasDigitizingArtwork: false,
    level1: 0,
    level2: 0,
    level3: 0,
  });

  const handleChange = (event) => {
    const { value, name } = event.target;
    setSelectedDetails((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleDropDownChange = (event) => {
    const { checked, name } = event.target;
    setSelectedDetails((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };

  const { mutate, isPending } = useMutation({
    mutationFn: (payload) => imageServices.generateDtfConfirmation(payload),
    mutationKey: ['generate-dtf-sheet'],
    onSuccess: (response) => {
      const file = response.data[0];
      const fileName = file.name;
      const fileUrl = apiConstants.imgUrlS3 + fileName;
      saveAs(fileUrl);
    },
  });

  const handleSubmit = () => {
    // const { r, g, b } = hexToNormalizedRgb(selectedDetails.bgColor);

    const objects = getAllObject(
      canvas,
      currentSheetName,
      sheetMap,
      initCanvasMeasures
    );

    const { hasDigitizingArtwork, level1, level2, level3, bgColor } =
      selectedDetails;

    const payload = {
      objects: objects,
      options: {
        bgColor,
        isDigitizingArtwork: hasDigitizingArtwork,
        levels: [level1, level2, level3],
      },
    };

    mutate(payload);
  };

  return (
    <div className="modal-container">
      <div className="modal-content">
        <div className="d-flex align-items-center justify-content-between gap-2 px-2 py-1">
          <div className="modal-title ">Confirmation</div>
          <div className="modal-close" onClick={onClose} role="button">
            <CloseIcon size={15} color="black" />
          </div>
        </div>

        <div className="modal-main px-2 pb-2 mt-3">
          <div className="d-flex justify-content-between align-items-center">
            <div>Background color</div>
            <input
              type="color"
              name="bgColor"
              value={selectedDetails.bgColor}
              onChange={handleChange}
            />
          </div>
          <div className="mt-1">
            <input
              type="checkbox"
              name="hasDigitizingArtwork"
              checked={selectedDetails.hasDigitizingArtwork}
              onChange={handleDropDownChange}
            />
            <label className="ms-2 py-1">Digitizing artwork</label>
          </div>
          {selectedDetails.hasDigitizingArtwork && (
            <>
              <div className="mt-2">
                Level 1:
                <input
                  className="ms-2"
                  value={selectedDetails.level1}
                  onChange={handleChange}
                  name="level1"
                  type="number"
                />
              </div>
              <div className="mt-2">
                Level 2:
                <input
                  className="ms-2"
                  value={selectedDetails.level2}
                  onChange={handleChange}
                  name="level2"
                  type="number"
                />
              </div>
              <div className="mt-2 mb-2">
                Level 3:
                <input
                  className="ms-2"
                  value={selectedDetails.level3}
                  name="level3"
                  onChange={handleChange}
                  type="number"
                />
              </div>
            </>
          )}
        </div>
        <button
          className=" shdw-button primary-bg-color mt-3 h-fit "
          style={{ alignSelf: 'flex-end' }}
          disabled={isPending}
          onClick={handleSubmit}
        >
          Generat{isPending ? 'ing' : 'e'}
        </button>
      </div>
    </div>
  );
}

export default DtfConfirmationModal;
