import { Link } from 'react-router-dom';

const Dashboard = () => {
  const cards = [
    {
      path: '/availablecolors',
      image: '/img/available.png',
      label: 'Available Colors',
    },
    {
      path: '/imagecolorpicker',
      image: '/img/picker.png',
      label: 'Color Picker',
    },
    {
      path: '/imagecoloreplace',
      image: '/img/replace.png',
      label: 'Color Replace',
    },
    {
      path: '/colorconverter',
      image: '/img/converter.png',
      label: 'Shade Finder',
    },
    // {
    //   path: '/imageupscaler',
    //   image: '/img/scale.png',
    //   label: 'Image Upscaler',
    // },
    // {
    //   path: '/background-removal',
    //   image: '/img/scale.png',
    //   label: 'Background Removal',
    // },
    // {
    //   path: '/image-editor',
    //   image: '/img/scale.png',
    //   label: 'Image Editor',
    // },
    {
      path: '/gang-sheet-builder-v2',
      image: '/img/canvas.png',
      label: 'Gang Sheet Builder',
    },
  ];

  return (
    <div className="dashboard px-3 py-4">
      <div className="row">
        {cards.map((card, index) => (
          <div className="col-md-3" key={index}>
            <div className=" dashboard-card">
              <Link className="applink d-flex" to={card.path}>
                <img
                  src={card.image}
                  alt={card.label}
                  className="dashboard-card-img"
                />

                <div className="card-text">{card.label}</div>
              </Link>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Dashboard;
