import { ChevronLeft, ChevronRight, Trash } from 'lucide-react';
import { useRef, useState } from 'react';
import { Plus } from './Icons';

const CanvasListPreview = ({
  canvasList = [],
  onCanvasAdd = () => {},
  onCanvasSwitch = () => {},
  onSheetRemove = () => {},
  currentSheetName = null,
  selectedUnit,
}) => {
  const containerRef = useRef();
  const [isHidden, setIsHidden] = useState(false);

  return (
    <div
      className="gs-preview-container d-flex"
      style={{ right: isHidden ? '-185px' : '30px' }}
    >
      <div
        className="gs-preview-hide-icon py-3 px-1"
        onClick={() => setIsHidden((prev) => !prev)}
      >
        {isHidden ? <ChevronLeft size={15} /> : <ChevronRight size={15} />}
      </div>
      <div className="gs-canvas-panel " ref={containerRef}>
        <div
          className="pb-2 px-10px d-flex align-items-center"
          style={{ fontSize: 16, fontWeight: '600' }}
        >
          {/* <div
          onClick={() => {
            setIsHidden((prev) => !prev);
          }}
        >
          {'->'}
        </div> */}
          <div>Sheets ({canvasList.length ?? 0})</div>
        </div>
        <div className="canvas-preview-list px-10px">
          {canvasList.map((canvasDetails) => {
            return (
              <div
                className="gang-sheet-preview d-flex align-items-center"
                style={{
                  cursor: 'pointer',
                  outline:
                    currentSheetName === canvasDetails.sheetName
                      ? '1px solid blue'
                      : 'none',
                }}
                key={canvasDetails.sheetName}
                onClick={() =>
                  onCanvasSwitch(currentSheetName, canvasDetails.sheetName)
                }
              >
                <div style={{ flex: 1 }}>
                  <div className="fz-10px fw-600">
                    {(
                      canvasDetails?.selectedSize?.width * selectedUnit.value
                    ).toFixed(1)}{' '}
                    X{' '}
                    {(
                      canvasDetails?.selectedSize?.height * selectedUnit.value
                    ).toFixed(1)}
                  </div>
                  <div>{canvasDetails?.sheetName ?? 'Unknown'}</div>
                  <div className="fz-12px">
                    Items: {canvasDetails?.noOfItems ?? 0}
                  </div>
                </div>
                {canvasList.length !== 1 && (
                  <div
                    className="fz-10px canvas-remove"
                    onClick={(e) => {
                      e.stopPropagation();
                      onSheetRemove(canvasDetails);
                    }}
                  >
                    <Trash size={15} />
                  </div>
                )}
              </div>
            );
          })}
        </div>
        <div className="mt-3 fz-14px px-10px">
          <button className="plain-btn" onClick={() => onCanvasAdd()}>
            <Plus size={12} color="black" /> New Sheet
          </button>
          {/* (  <button className="plain-btn" onClick={() => {}}>
          Open previous
        </button>
        <button className="plain-btn" onClick={() => {}}>
          Autobuild
        </button>) */}
        </div>
      </div>
    </div>
  );
};

export default CanvasListPreview;
