import { AvailableColorComponent } from './AvailableColorComponent';

import ImageColorPicker from './ImageColorPicker';

import { BrowserRouter, Route, Routes } from 'react-router-dom';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import BackgroundRemoval from './BackgroundRemoval';
import ColorConverter from './ColorConverter';
import Layout from './Component/Layout';
import Dashboard from './Dashboard';
import DesignSurface from './DesignSurface/DesignSurface';
import GangSheetBuilder from './GangSheetBuilder/GangSheetBuilder';
import { ImageColorReplace } from './ImageColorReplace';
import ImageUpscaler from './ImageUpscaler';
import FabricTest from './Test/FabricTest';
import GangSheetBuilderRoot from './Test/GangSheetBuilderRoot';

const queryClient = new QueryClient();

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" exact element={<Layout>{<Dashboard />} </Layout>} />
            <Route
              path="/availablecolors"
              element={<Layout>{<AvailableColorComponent />} </Layout>}
            />
            <Route
              path="/imagecolorpicker"
              element={<Layout>{<ImageColorPicker />} </Layout>}
            />
            <Route
              path="/imagecoloreplace"
              element={<Layout>{<ImageColorReplace />} </Layout>}
            />
            <Route
              path="/colorconverter"
              element={<Layout>{<ColorConverter />} </Layout>}
            />
            <Route
              path="/imageupscaler"
              element={<Layout>{<ImageUpscaler />} </Layout>}
            />
            <Route
              path="/background-removal"
              element={<Layout>{<BackgroundRemoval />} </Layout>}
            />
            <Route
              path="/image-editor"
              element={<Layout>{<DesignSurface />} </Layout>}
            />
            <Route
              path="/gang-sheet-builder"
              element={<Layout>{<GangSheetBuilder />} </Layout>}
            />
            <Route
              path="/gang-sheet-builder-v2"
              element={<Layout>{<GangSheetBuilderRoot />} </Layout>}
            />
            <Route
              path="/fabric-test"
              element={<Layout>{<FabricTest />} </Layout>}
            />
          </Routes>
        </BrowserRouter>
      </div>
    </QueryClientProvider>
  );
};

export default App;
