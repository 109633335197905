import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { CloseIcon, HomeIcon, NavbarIcon } from '../Icons';
import './Menu.css';

const Menu = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);

  const handleToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  //   //    <Route
  //   path="/availablecolors"
  //   element={<AvailableColorComponent />}
  // />
  // <Route path="/imagecolorpicker" element={<ImageColorPicker />} />
  // <Route path="/imagecoloreplace" element={<ImageColorReplace />} />
  // <Route path="/colorconverter" element={<ColorConverter />} />
  return (
    <nav className="navbar header  navbar-expand-lg navbar-light light-solid ">
      <div className="container">
        <Link className="nav-link logo-link" to="/">
          <img src="/logo.png" alt="logo" className="logo" /> Digicolors
        </Link>
        <button
          className="navbar-toggler no-outline"
          type="button"
          onClick={handleToggle}
          aria-controls="navbarNav"
          aria-expanded={!isCollapsed}
          aria-label="Toggle navigation"
        >
          {isCollapsed ? (
            <NavbarIcon />
          ) : (
            <span className="close-icon">
              <CloseIcon />
            </span>
          )}
        </button>
        <div
          className={`collapse navbar-collapse ${isCollapsed ? 'collapsed' : 'show'}`}
          id="navbarNav"
        >
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <Link className="nav-link" to="/">
                <HomeIcon /> Home
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/availablecolors">
                <img
                  src="/img/available.png
              "
                  alt="Available Colors"
                  className="menu_icon"
                />
                Available Colors
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/imagecolorpicker">
                <img
                  src="/img/picker.png"
                  alt=" Color Picker"
                  className="menu_icon"
                />
                Color Picker
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/imagecoloreplace">
                <img
                  src="/img/replace.png"
                  alt="Color Replace"
                  className="menu_icon"
                />
                Color Replace
              </Link>
            </li>
            <li className="nav-item">
              <Link className="nav-link" to="/colorconverter">
                <img
                  src="/img/converter.png"
                  alt="Color Converter"
                  className="menu_icon"
                />
                Shade Finder
              </Link>
            </li>
            {/* <li className="nav-item">
              <Link className="nav-link" to="/imageupscaler">
                <img
                  src="/img/scale.png"
                  alt=" Upscaler"
                  className="menu_icon"
                />
                Image Upscaler
              </Link>
            </li> */}
            <li className="nav-item">
              <Link className="nav-link" to="/gang-sheet-builder-v2">
                <img src="/img/canvas.png" alt=" b" className="menu_icon" />
                Gang sheet builder
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Menu;
