import React, { useState } from 'react';

const ColorDropdown = ({ colors = [], handleColorSelect = () => {} }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  console.log('ColorDropdown:colors', colors);

  const filteredColors = colors
    .filter(
      (color) =>
        color.name.toLowerCase().includes(searchTerm) ||
        color.colorHex.toLowerCase().includes(searchTerm)
    )
    .slice(0, 10);
  const copyToClipboard = (color) => {
    navigator.clipboard.writeText(color).then(() => {
      console.log('Copied to clipboard:', color);
    });
  };

  const handleSelect = (color) => {
    handleColorSelect(color);
    copyToClipboard(color.colorHex + ' ' + color.name);
    alert('Copied to clipboard: ' + color.colorHex + ' ' + color.name);
    //setIsOpen(false);
  };

  return (
    <div className="color-dropdown">
      <input
        type="text"
        className="form-control"
        placeholder="Search colors...."
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        onFocus={() => setIsOpen(true)}
        onBlur={() => setTimeout(() => setIsOpen(false), 500)}
      />
      {isOpen && (
        <div className="dropdown-menu show" style={{ width: '100%' }}>
          {filteredColors.map((color) => (
            <button
              key={color.colorHex}
              className="dropdown-item d-flex align-items-center"
              onClick={() => handleSelect(color)}
              type="button"
            >
              <div
                style={{
                  width: '20px',
                  height: '20px',
                  backgroundColor: color.colorHex,
                  marginRight: '10px',
                  border: '1px solid #ccc',
                }}
              />
              <span>
                {color.name} {color.colorHex}
              </span>
            </button>
          ))}
        </div>
      )}
    </div>
  );
};

export default ColorDropdown;
