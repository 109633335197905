import React, { useState } from 'react';
import ColorBox from './ColorBox';

export const ColorBoxs = ({ colors, width = 50 }) => {
  const [copiedColor, setCopiedColor] = useState(null);

  const handleCopy = (colorHex) => {
    setCopiedColor(colorHex);
    setTimeout(() => {
      setCopiedColor(null);
    }, 2000);
  };

  return (
    <div className="d-flex flex-wrap">
      {colors.map((color, index) => {
        return (
          <div key={index}>
            <ColorBox
              color={color}
              width={width}
              onCopy={handleCopy}
              copied={copiedColor === color.colorHex}
            />
            <p className="text-center">{color.name}</p>
          </div>
        );
      })}
    </div>
  );
};
