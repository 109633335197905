import { rgb_to_lab } from 'color-diff';
import RgbQuant from 'rgbquant';

import {
  embroideryThreadColor,
  fabricColor,
  wovenThread,
} from './availableColors';
//import json data
import deleted_pantoneColors from './deleted_pantoneColors.json';

export const rgbToHex = (r, g, b) => {
  return (
    '#' +
    ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()
  );
};

export const getColorInfoFromPixel = (pixelData) => {
  const [r, g, b] = pixelData;
  const rgb = `rgb(${r}, ${g}, ${b})`;
  const hex = `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()}`;
  return { colorRgbString: rgb, colorHex: hex, colorRgb: { R: r, G: g, B: b } };
};
let cacheAvailableColors = {};

export const hexToRgb = (hex) => {
  hex = hex.replace(/^#/, '');
  const num = parseInt(hex, 16);
  return { R: num >> 16, G: (num >> 8) & 255, B: num & 255 };
};
export const rgbStringToHex = (rgbString) => {
  const rgbValues = rgbString.match(/\d+/g).map(Number);
  if (rgbValues.length !== 3) {
    throw new Error('Invalid RGB string format');
  }
  const [r, g, b] = rgbValues;

  // Ensure the RGB values are within the correct range
  if (r < 0 || r > 255 || g < 0 || g > 255 || b < 0 || b > 255) {
    throw new Error('RGB values must be between 0 and 255');
  }

  // Convert the RGB values to a hexadecimal string
  return (
    '#' +
    ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()
  );
};

export const isColorMatch = (pixelData, colorRgb) => {
  const [R, G, B] = pixelData;
  return R === colorRgb.R && G === colorRgb.G && B === colorRgb.B;
};

function calculateLabDistance(lab1, lab2) {
  const dl = lab1.L - lab2.L;
  const da = lab1.a - lab2.a;
  const db = lab1.b - lab2.b;
  return Math.sqrt(dl * dl + da * da + db * db);
}

export const resizeImageSize = (
  originalWidth,
  originalHeight,
  maxWidth = 300,
  maxHeight = 300
) => {
  const aspectRatio = originalWidth / originalHeight;
  let newWidth = originalWidth;
  let newHeight = originalHeight;
  if (originalWidth > maxWidth || originalHeight > maxHeight) {
    if (aspectRatio > 1) {
      // Wider than tall
      newWidth = maxWidth;
      newHeight = maxWidth / aspectRatio;
    } else {
      // Taller than wide or square
      newHeight = maxHeight;
      newWidth = maxHeight * aspectRatio;
    }
  }
  return { newWidth, newHeight };
};

export const rgbStringToRgbObject = (rgbString) => {
  const rgb = rgbString.match(/\d+/g).map(Number);
  return { R: rgb[0], G: rgb[1], B: rgb[2] };
};

//makealavaibale colors to colorRGB,colorLab
// const tempFun = () => {
//   //pantoneColor
//   const colorListWithRGB = deleted_pantoneColors.map((item) => {
//     const colorRgb = hexToRgb(item.colorHex);

//     return {
//       name: item.name,
//       colorHex: item.colorHex,
//       colorRgb: colorRgb,
//       colorLab: rgb_to_lab(colorRgb),
//     };
//   });
//   return colorListWithRGB;
// };
// console.log(JSON.stringify(tempFun()));
let _panToonColor = [];

export const getNearestPantoneColor = async (originalColors) => {
  // Load pantone colors if not already loaded
  if (_panToonColor.length === 0) {
    const response = await fetch('pantoneColor.json');
    _panToonColor = await response.json();
  }

  // Process each original color to find the nearest Pantone match
  return originalColors.map((color) => {
    console.log('color:', color);
    const colorLab = rgb_to_lab(color.colorRgb); // Convert to Lab color space
    let nearestPantone = null;
    let minDistance = Infinity;

    _panToonColor.forEach((pantoneColor) => {
      const distance = calculateLabDistance(colorLab, pantoneColor.colorLab); // Calculate distance
      if (distance < minDistance) {
        minDistance = distance;
        nearestPantone = pantoneColor;
      }
    });

    // Assign the nearest Pantone color to the original color
    return {
      ...color,
      pantone: nearestPantone,
    };
  });
};

export const getAvailableColorGenerated = (colorType = 'thread') => {
  const _availableColors =
    colorType === 'thread'
      ? embroideryThreadColor
      : colorType === 'fabric'
        ? fabricColor
        : wovenThread;

  if (cacheAvailableColors[colorType]) return cacheAvailableColors[colorType];

  const colorListWithRGB = _availableColors.map((item) => {
    const colorRgb = hexToRgb(item.colorHex);
    return {
      ...item,
      colorRgb: colorRgb,
      colorLab: rgb_to_lab(colorRgb),
    };
  });

  cacheAvailableColors[colorType] = colorListWithRGB;
  return colorListWithRGB;
};

export const getDistinctColors = (data) => {
  const colorMap = new Map();
  for (let i = 0; i < data.length; i += 4) {
    const color = `rgb(${data[i]},${data[i + 1]},${data[i + 2]})`;
    if (colorMap.has(color)) {
      colorMap.get(color).count++;
    } else {
      colorMap.set(color, {
        count: 1,
        colorRgb: { R: data[i], G: data[i + 1], B: data[i + 2] },
      });
    }
  }
  const colors = Array.from(colorMap.entries()).sort(
    (a, b) => b[1].count - a[1].count
  );
  return colors.map((colorEntry) => ({
    colorRgbString: colorEntry[0],
    count: colorEntry[1].count,
    colorRgb: colorEntry[1].colorRgb,
  }));
};

export const getTextColor = (backgroundColor) => {
  const color =
    backgroundColor.charAt(0) === '#'
      ? backgroundColor.substring(1, 7)
      : backgroundColor;
  const r = parseInt(color.substring(0, 2), 16);
  const g = parseInt(color.substring(2, 4), 16);
  const b = parseInt(color.substring(4, 6), 16);
  const brightness = (r * 299 + g * 587 + b * 114) / 1000;
  return brightness > 125 ? 'black' : 'white';
};

export const findNearestColors = (
  colorRgb,
  colorPalette = getAvailableColorGenerated()
) => {
  const colorLab = rgb_to_lab(colorRgb);

  const nearestColors = colorPalette.map((paletteColor) => {
    const paletteLab = paletteColor.colorLab;

    return {
      ...paletteColor,
      distance: calculateLabDistance(paletteLab, colorLab),
    };
  });

  nearestColors.sort((a, b) => a.distance - b.distance);

  return nearestColors.slice(0, 15);
};

//---List colors in same row-----
export const rowColorList = function (colorList) {
  const rowColorList = colorList.reduce((acc, color) => {
    if (acc[color.row]) {
      acc[color.row].push(color);
    } else {
      acc[color.row] = [color];
    }
    return acc;
  }, {});

  return rowColorList;
};

export function groupSimilarColors(colors, threshold) {
  const groupedColors = [];
  colors.forEach((color) => {
    const labColor = rgb_to_lab(color.colorRgb);
    let foundGroup = false;
    for (let group of groupedColors) {
      if (calculateLabDistance(labColor, group.labColor) <= threshold) {
        group.count += color.count;
        group.similarcolors.push(color.colorRgb);
        foundGroup = true;
        break;
      }
    }
    if (!foundGroup) {
      groupedColors.push({
        ...color,
        colorRgb: color.colorRgb,
        labColor: labColor,
        count: color.count,
        similarcolors: [],
      });
    }
  });
  return groupedColors.map(({ labColor, ...rest }) => rest);
}

//@=====================================@@@@@@@@

//-----------delete ----code
function colorDistance(color1, color2) {
  const dr = color1.r - color2.r;
  const dg = color1.g - color2.g;
  const db = color1.b - color2.b;
  return Math.sqrt(dr * dr + dg * dg + db * db);
}

function initializeCentroids(data, k) {
  const centroids = [];
  for (let i = 0; i < k; i++) {
    const randomIndex = Math.floor(Math.random() * data.length);
    centroids.push(data[randomIndex]);
  }

  return centroids;
}

function assignClusters(data, centroids) {
  const clusters = Array(centroids.length)
    .fill()
    .map(() => []);
  data.forEach((point) => {
    let minDistance = Infinity;
    let clusterIndex = 0;

    centroids.forEach((centroid, index) => {
      if (centroid === null) return; // Skip null centroids
      const distance = colorDistance(point, centroid);
      if (distance < minDistance) {
        minDistance = distance;
        clusterIndex = index;
      }
    });

    clusters[clusterIndex].push(point);
  });

  return clusters;
}

function updateCentroids(clusters) {
  const newCentroids = clusters.map((cluster) => {
    const length = cluster.length;
    if (length === 0) return null; // Handle empty clusters
    const avgColor = cluster.reduce(
      (avg, color) => {
        avg.r += color.r;
        avg.g += color.g;
        avg.b += color.b;
        return avg;
      },
      { r: 0, g: 0, b: 0 }
    );

    return {
      r: Math.round(avgColor.r / length),
      g: Math.round(avgColor.g / length),
      b: Math.round(avgColor.b / length),
    };
  });

  return newCentroids;
}

function hasConverged(prevCentroids, newCentroids) {
  for (let i = 0; i < prevCentroids.length; i++) {
    if (
      newCentroids[i] === null ||
      colorDistance(prevCentroids[i], newCentroids[i]) !== 0
    ) {
      return false;
    }
  }
  return true;
}

function kMeansClustering(data, k) {
  let centroids = initializeCentroids(data, k);
  let iterations = 0;
  let previousCentroids;

  do {
    const clusters = assignClusters(data, centroids);
    previousCentroids = [...centroids];
    centroids = updateCentroids(clusters);
    iterations++;
  } while (!hasConverged(previousCentroids, centroids) && iterations < 100);

  return centroids.filter((centroid) => centroid !== null); // Remove null centroids
}

export function reduceImageColorsUsingKMeans(imageData, width, height, k) {
  const data = [];

  // Extract RGB colors from imageData
  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
      const index = (y * width + x) * 4;
      const color = {
        r: imageData[index],
        g: imageData[index + 1],
        b: imageData[index + 2],
      };
      data.push(color);
    }
  }

  // Apply K-Means clustering
  const centroids = kMeansClustering(data, k);

  // Create a map of original color to nearest centroid
  const colorMap = new Map();
  data.forEach((color) => {
    let minDistance = Infinity;
    let nearestCentroid = null;

    centroids.forEach((centroid) => {
      const distance = colorDistance(color, centroid);
      if (distance < minDistance) {
        minDistance = distance;
        nearestCentroid = centroid;
      }
    });

    colorMap.set(`${color.r},${color.g},${color.b}`, nearestCentroid);
  });

  // Reconstruct the image with reduced colors
  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
      const index = (y * width + x) * 4;
      const colorKey = `${imageData[index]},${imageData[index + 1]},${imageData[index + 2]}`;
      const newColor = colorMap.get(colorKey);

      if (newColor) {
        imageData[index] = newColor.r;
        imageData[index + 1] = newColor.g;
        imageData[index + 2] = newColor.b;
      }
    }
  }

  return imageData;
}

//------------- OK

export function processXYImageData(imageData, vertical = true) {
  const width = imageData.width;
  const height = imageData.height;
  const data = imageData.data;

  //console.log('imageData:',{...});

  console.log('width:', width);
  console.log('height:', height);
  console.log('data:', data);

  function getColorAtIndex(index) {
    const r = data[index];
    const g = data[index + 1];
    const b = data[index + 2];
    return { R: r, G: g, B: b };
  }

  function setColorAtIndex(index, color) {
    data[index] = color.R;
    data[index + 1] = color.G;
    data[index + 2] = color.B;
  }

  function findNearestColor(x, y) {
    const index = (y * width + x) * 4;
    const labColor = rgb_to_lab(getColorAtIndex(index));
    const nextIndex = vertical
      ? (y * width + x + 1) * 4
      : ((y + 1) * width + x) * 4;
    const nextColor = rgb_to_lab(getColorAtIndex(nextIndex));
    const prvIndex = vertical
      ? (y * width + x - 1) * 4
      : ((y - 1) * width + x) * 4;
    const prevColor = rgb_to_lab(getColorAtIndex(prvIndex));

    const prevdistance = calculateLabDistance(labColor, prevColor);
    const nextdistance = calculateLabDistance(labColor, nextColor);
    if (nextdistance < prevdistance) {
      return nextColor;
    }
    return prevColor;
  }

  const tempColorSet = {};
  for (let y = 1; y < height; y++) {
    for (let x = 1; x < width; x++) {
      const index = (y * width + x) * 4;
      if (y < height - 1 && x < width - 1) {
        const nearestColor = findNearestColor(x, y);
        tempColorSet[index] = nearestColor;
        // setColorAtIndex(index, nearestColor);
      }
    }
  }

  for (let x = 1; x < width; x++) {
    for (let y = 1; y < height; y++) {
      const index = (y * width + x) * 4;
      if (y < height - 1 && x < width - 1) {
        const nearestColor = findNearestColor(x, y);
        tempColorSet[index] = nearestColor;
        // setColorAtIndex(index, nearestColor);
      }
    }
  }

  for (let i = 0; i < tempColorSet.length; i++) {
    setColorAtIndex(i, tempColorSet[i]);
  }

  return imageData;
}

export const sharpenImageData = (imageData, intensity = 10) => {
  const { width, height, data } = imageData;
  const output = new ImageData(width, height);

  // Scale the kernel based on the intensity
  const a = intensity;
  const kernel = [0, -a, 0, -a, 1 + 4 * a, -a, 0, -a, 0];

  const applyKernel = (x, y) => {
    const kernelSize = 3;
    const halfKernel = Math.floor(kernelSize / 2);
    let r = 0,
      g = 0,
      b = 0;

    for (let ky = -halfKernel; ky <= halfKernel; ky++) {
      for (let kx = -halfKernel; kx <= halfKernel; kx++) {
        const pixelX = Math.min(width - 1, Math.max(0, x + kx));
        const pixelY = Math.min(height - 1, Math.max(0, y + ky));
        const pixelIndex = (pixelY * width + pixelX) * 4;
        const kernelValue =
          kernel[(ky + halfKernel) * kernelSize + (kx + halfKernel)];

        r += data[pixelIndex] * kernelValue;
        g += data[pixelIndex + 1] * kernelValue;
        b += data[pixelIndex + 2] * kernelValue;
      }
    }

    return [r, g, b];
  };

  for (let y = 0; y < height; y++) {
    for (let x = 0; x < width; x++) {
      const index = (y * width + x) * 4;
      const [r, g, b] = applyKernel(x, y);

      output.data[index] = Math.min(255, Math.max(0, r));
      output.data[index + 1] = Math.min(255, Math.max(0, g));
      output.data[index + 2] = Math.min(255, Math.max(0, b));
      output.data[index + 3] = data[index + 3]; // Preserve alpha channel
    }
  }

  return output;
};

export const quantizeImage = (imageData, colorCount) => {
  const rq = new RgbQuant({
    colors: colorCount,
    method: 2, // Wu quantization
    boxSize: [64, 64],
    boxPxls: 2,
    initColors: 4096,
    minHueCols: 1,
    dithKern: null, // Set to null to disable dithering if desired
    palette: null, // Can set a fixed palette here
  });

  // Sample the image
  rq.sample(imageData);

  // Reduce the image
  const reducedImageDataArray = rq.reduce(imageData);

  // Convert reduced image data to ImageData object
  const reducedImageData = new ImageData(
    new Uint8ClampedArray(reducedImageDataArray),
    imageData.width,
    imageData.height
  );

  return reducedImageData;
};

//-----to dleet
// export const quantizeImage = (imageData, colorCount) => {
//   const pixelArray = [];
//   const data = imageData.data;

//   // Extract colors from image data while ignoring fully transparent pixels
//   for (let i = 0; i < data.length; i += 4) {
//     if (data[i + 3] > 0) {
//       pixelArray.push([data[i], data[i + 1], data[i + 2]]);
//     }
//   }

//   // Quantize colors and generate a limited palette
//   const cmap = quantize(pixelArray, colorCount);
//   const palette = cmap.palette();

//   // Helper function to find the closest color in the palette
//   const findClosestColor = (color) => {
//     let closestColor = palette[0];
//     let minDistance = Infinity;

//     palette.forEach((paletteColor) => {
//       const distance = Math.sqrt(
//         Math.pow(color[0] - paletteColor[0], 2) +
//           Math.pow(color[1] - paletteColor[1], 2) +
//           Math.pow(color[2] - paletteColor[2], 2)
//       );
//       if (distance < minDistance) {
//         minDistance = distance;
//         closestColor = paletteColor;
//       }
//     });

//     return closestColor;
//   };

//   // Map each pixel to the nearest color in the palette
//   for (let i = 0; i < data.length; i += 4) {
//     if (data[i + 3] > 0) {
//       // Ignore fully transparent pixels
//       const originalColor = [data[i], data[i + 1], data[i + 2]];
//       const closestColor = findClosestColor(originalColor);

//       // Replace the pixel color with the closest palette color
//       data[i] = closestColor[0];
//       data[i + 1] = closestColor[1];
//       data[i + 2] = closestColor[2];
//     }
//   }

//   return imageData;
// };

// export const quantizeImageData = (imageData, levelsPerChannel) => {
//   const { width, height, data } = imageData;
//   const output = new ImageData(width, height);

//   const levelStep = 255 / (levelsPerChannel - 1);

//   for (let i = 0; i < data.length; i += 4) {
//     output.data[i] = Math.round(data[i] / levelStep) * levelStep;
//     output.data[i + 1] = Math.round(data[i + 1] / levelStep) * levelStep;
//     output.data[i + 2] = Math.round(data[i + 2] / levelStep) * levelStep;
//     output.data[i + 3] = data[i + 3]; // Preserve alpha channel
//   }

//   return output;
// };

const extractUniqueColors = (imageData) => {
  const { data } = imageData;
  const colorSet = new Set();

  for (let i = 0; i < data.length; i += 4) {
    const color = `${data[i]},${data[i + 1]},${data[i + 2]}`;
    colorSet.add(color);
  }

  return Array.from(colorSet).map((color) => {
    const [r, g, b] = color.split(',').map(Number);
    return { r, g, b };
  });
};

// const posterizeWithPalette = (imageData, palette) => {
//   const { width, height, data } = imageData;
//   const output = new ImageData(width, height);

//   for (let i = 0; i < data.length; i += 4) {
//     const pixelColor = { r: data[i], g: data[i + 1], b: data[i + 2] };

//     // Find the nearest color in the palette
//     let nearestColor = palette[0];
//     let minDistance = colorDistance(pixelColor, nearestColor);

//     for (let j = 1; j < palette.length; j++) {
//       const distance = colorDistance(pixelColor, palette[j]);
//       if (distance < minDistance) {
//         minDistance = distance;
//         nearestColor = palette[j];
//       }
//     }

//     output.data[i] = nearestColor.r;
//     output.data[i + 1] = nearestColor.g;
//     output.data[i + 2] = nearestColor.b;
//     output.data[i + 3] = data[i + 3]; // Preserve alpha channel
//   }

//   return output;
// };

// const colorDistance = (c1, c2) => {
//   return (
//     (c1.r - c2.r) ** 2 +
//     (c1.g - c2.g) ** 2 +
//     (c1.b - c2.b) ** 2
//   );
// };

export const posterizeImageData = (imageData) => {
  const { width, height, data } = imageData;
  const palette = extractUniqueColors(imageData);
  const output = new ImageData(width, height);

  for (let i = 0; i < data.length; i += 4) {
    const pixelColor = { r: data[i], g: data[i + 1], b: data[i + 2] };

    // Find the nearest color in the palette
    let nearestColor = palette[0];
    let minDistance = colorDistance(pixelColor, nearestColor);

    for (let j = 1; j < palette.length; j++) {
      const distance = colorDistance(pixelColor, palette[j]);
      if (distance < minDistance) {
        minDistance = distance;
        nearestColor = palette[j];
      }
    }

    output.data[i] = nearestColor.r;
    output.data[i + 1] = nearestColor.g;
    output.data[i + 2] = nearestColor.b;
    output.data[i + 3] = data[i + 3]; // Preserve alpha channel
  }

  return output;
};
