import React, { useRef, useState, useEffect, useCallback } from 'react';
import {
  embroideryThreadColor,
  fabricColor,
  wovenThread,
} from './availableColors';
import {
  findNearestColors,
  getAvailableColorGenerated,
  getColorInfoFromPixel,
} from './_miscFunction';
import ColorDropdown from './Component/ColorDropDown';
import { ColorBoxs } from './Component/ColorBoxs';
import ColorBox from './Component/ColorBox';
import ImageUploadComponent from './Component/ImageUploadComponent';

const _availableColors = getAvailableColorGenerated();

const ImageColorPicker = ({ imageSrc }) => {
  const [availableColors, setAvailableColors] = useState(_availableColors);

  const canvasRef = useRef(null);
  const [tab, setTab] = useState(1);

  useEffect(() => {
    if (tab === 1) {
      setAvailableColors(getAvailableColorGenerated('thread'));
    } else if (tab === 2) {
      setAvailableColors(getAvailableColorGenerated('fabric'));
    } else if (tab === 3) {
      setAvailableColors(getAvailableColorGenerated('woven'));
    }
    console.log('tab', tab);
    console.log('availableColors');
  }, [tab]);

  const [colorInfo, setColorInfo] = useState({ rgb: '', hex: '' });
  const [recomendedColors, setRecomendedColors] = useState([]);

  const [selectedImage, setSelectedImage] = useState(imageSrc);
  const handleColorset = (_colorInfo) => {
    console.log('_colorInfo', _colorInfo, availableColors);
    const recomendedColorstemp = findNearestColors(
      _colorInfo.colorRgb,
      availableColors
    );
    setRecomendedColors(recomendedColorstemp);
  };

  useEffect(() => {
    // setSelectedColor(colorInfo);
    // const recomendedColorstemp = findNearestColors(
    //   colorInfo.colorRgb,
    //   availableColors
    // );
    // setRecomendedColors(recomendedColorstemp);
    // setColorInfo(colorInfo);
    console.log('availableColors');
    colorInfo && colorInfo.colorRgb && handleColorset(colorInfo);
  }, [availableColors, colorInfo]);

  const ImageInit = useCallback(
    (imageSrcArg) => {
      if (!imageSrcArg) return;
      setSelectedImage(imageSrcArg);
      const canvas = canvasRef.current;
      const ctx = canvas.getContext('2d');
      const img = new Image();
      img.src = imageSrcArg || imageSrc;

      img.onload = () => {
        const scaleFactor = canvas.clientWidth / img.width;
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        ctx.drawImage(img, 0, 0, img.width, img.height);
        canvas.style.width = `${img.width * scaleFactor}px`;
        canvas.style.height = `${img.height * scaleFactor}px`;
      };
    },
    [imageSrc]
  );

  useEffect(() => {
    const handleResize = () => {
      ImageInit();
    };

    ImageInit(selectedImage);
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [selectedImage, ImageInit]);

  const handleCanvasClick = (e) => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    const rect = canvas.getBoundingClientRect();
    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;
    const x = (e.clientX - rect.left) * scaleX;
    const y = (e.clientY - rect.top) * scaleY;
    const pixelData = ctx.getImageData(x, y, 1, 1).data;
    const colorInfo = getColorInfoFromPixel(pixelData);

    handleColorset(colorInfo);

    setColorInfo(colorInfo);
  };

  console.log('recomendedColors length', availableColors.length);

  return (
    <div className="card min-500">
      <div className="card-header">
        <img
          src="
          /img/picker.png"
          alt="Color Picker"
          className="mr-2 card-header-icon"
        />
        Image Color Picker
      </div>
      <div className="card-body">
        <div>
          <div className="row">
            <div className="col-md-6">
              {!imageSrc && !selectedImage && (
                <ImageUploadComponent onSelectImage={ImageInit} />
              )}

              <div className={imageSrc || selectedImage ? 'd-block' : 'd-none'}>
                <canvas
                  ref={canvasRef}
                  onClick={handleCanvasClick}
                  style={{ cursor: 'crosshair', width: '100%', height: 'auto' }}
                />
                <button
                  onClick={() => selectedImage(null)}
                  className="btn btn-light font-sm mt-3"
                  style={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                >
                  Change Image
                </button>
              </div>
            </div>
            <div className="col-md-6">
              <ul className="nav nav-tabs card-header-tabs">
                <li className="nav-item" onClick={() => setTab(1)}>
                  <a
                    className={'nav-link' + (tab === 1 ? ' active' : '')}
                    href="#tab1"
                    data-toggle="tab"
                  >
                    Embroidery Thread
                  </a>
                </li>
                <li className="nav-item" onClick={() => setTab(2)}>
                  <a
                    className={'nav-link' + (tab === 2 ? ' active' : '')}
                    href="#tab2"
                    data-toggle="tab"
                  >
                    Fabric Color
                  </a>
                </li>
                <li className="nav-item" onClick={() => setTab(3)}>
                  <a
                    className={'nav-link' + (tab === 3 ? ' active' : '')}
                    href="#tab3"
                    data-toggle="tab"
                  >
                    Woven Thread
                  </a>
                </li>
              </ul>
              <br />
              <ColorDropdown colors={availableColors} />
              <div className="mt-3 d-flex">
                {colorInfo?.colorHex && (
                  <div>
                    <p>Original</p>
                    <ColorBox color={colorInfo} width={100} />

                    <p>Hex: {colorInfo.colorHex}</p>
                  </div>
                )}
                {!!recomendedColors?.length && (
                  <div
                    style={{
                      marginLeft: '20px',
                    }}
                  >
                    <p>Recomended</p>
                    <ColorBox color={recomendedColors[0]} width={100} />

                    <p className="text-center">{recomendedColors[0].name}</p>
                  </div>
                )}
              </div>
              <div className="mt-3">
                <div className="d-flex flex-wrap">
                  {recomendedColors && (
                    <ColorBoxs colors={recomendedColors} width={100} />
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ImageColorPicker;
