/* eslint-disable react-hooks/exhaustive-deps */
import * as fabric from 'fabric';
import { useEffect, useRef } from 'react';
// import fabric from 'fabric'
// import  from 'fabric'

function useCanvasImagePlacer({
  canvasConfig,
  updateGsItem = () => {},
  selectedItems,
  setCurrentlySelectedItemIndex = () => {},
  currentlySelectedItemIndex,
  triggerRedraw,
  customRef = null,
}) {
  const {
    x,
    y,
    availableWidth,
    availableHeight,
    fullWidth,
    fullHeight,
    scale,
  } = canvasConfig;
  const outerCanvasRef = useRef();
  const preloadedImages = useRef(new Map());
  const fabricCanvasRef = useRef(null);

  const canvasRef = customRef ? customRef : outerCanvasRef;

  const preloadImages = () => {
    selectedItems.forEach((item) => {
      if (item.type === 'image' && !preloadedImages.current.has(item.file)) {
        const image = new Image();
        image.src = URL.createObjectURL(item.file);
        image.onload = () => {
          preloadedImages.current.set(item.file, image);
          // // Trigger an initial redraw when image is loaded
          // const ctx = outerCanvasRef.current.getContext('2d');
          // redrawImage({ ctx });
        };
      }
    });
  };

  useEffect(() => {
    // console.log('useEffectOne');

    preloadImages();
    const canvas = canvasRef.current;

    const handleKeyUp = (event) => {
      if (selectedItems.length < 0) {
        return;
      }

      if (event.key === 'Delete') {
        const ctx = canvasRef.current?.getContext('2d');
        setTimeout(() => {
          drawHandlerForSelectedImage(
            ctx,
            currentlySelectedItemIndex > 0
              ? selectedItems[currentlySelectedItemIndex - 1]
              : selectedItems[0]
          );
        }, 300);
        setCurrentlySelectedItemIndex((prev) => (prev > 0 ? prev - 1 : 0));
        updateGsItem((prev) =>
          prev.filter((_, index) => index !== currentlySelectedItemIndex)
        );
      }
    };

    const handleCanvasClick = (event) => {
      // Pick the image in the selected area from selectedItems
      // Get the click position relative to the canvas
      const rect = canvasRef.current?.getBoundingClientRect();
      const clickX = event.clientX - rect.left - x;
      const clickY = event.clientY - rect.top - y;
      const ctx = canvasRef.current?.getContext('2d');
      let selected = false;

      // Loop through the images to find if the click is inside any of them
      for (let i = 0; i < selectedItems.length; i++) {
        const item = selectedItems[i];
        if (
          clickX >= item.x &&
          clickX <= item.x + item.width &&
          clickY >= item.y &&
          clickY <= item.y + item.height
        ) {
          // redrawImage({ ctx });
          // drawHandlerForSelectedImage(ctx, item);
          triggerRedraw({ withHandle: true });
          setCurrentlySelectedItemIndex(i);
          selected = true;
          // Perform action, like selecting or moving the image
          break; // Optional: Stop if the first match is enough
        }
      }
      if (!selected) {
        triggerRedraw();
        setCurrentlySelectedItemIndex(null);
      }
    };

    canvas?.addEventListener('click', handleCanvasClick);
    document.addEventListener('keyup', handleKeyUp);

    return () => {
      canvas?.removeEventListener('click', handleCanvasClick);
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [selectedItems.length, currentlySelectedItemIndex]);

  //   When available width, height,x,y scale changed we need to update image position

  useEffect(() => {
    if (canvasRef.current) {
      canvasRef.current.width = fullWidth;
      canvasRef.current.height = fullHeight;
      canvasRef.current.style.width = `${fullWidth}px`;
      canvasRef.current.style.height = `${fullHeight}px`;
    }
    // const outerCtx = canvasRef.getContext('2d');
    // outerCtx.fillStyle = 'blue';
    // outerCtx.fillRect(x, y, availableWidth, availableHeight);
  }, [availableHeight, availableWidth]);

  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }
    let canvas = new fabric.Canvas(canvasRef.current, {
      width: fullWidth,
      height: fullHeight,
      overlayColor: 'red',
    });
    fabricCanvasRef.current = canvas;
    return () => {
      fabricCanvasRef.current = null;
      canvas?.dispose();
    };
  }, [availableWidth, availableHeight]);

  const translateImageCanvas = (translateX, translateY) => {
    if (!translateX && !translateY) {
      return;
    }

    // const ctx = canvasRef.getContext('2d');
    // ctx.clearRect(0, 0, fullWidth, fullHeight);

    // ctx.translate(availableWidth - translateX, availableHeight - translateY);
    const ctx = canvasRef.current?.getContext('2d');
    redrawImage({ ctx });
  };

  const redrawImage = ({
    ctx,
    scale = null,
    includeBorder = true,
    customItems = null,
  }) => {
    // ctx.clearRect(0, 0, fullWidth, fullHeight);
    const itemList = customItems ? customItems : selectedItems;
    // const canvas = new fabric.Canvas(canvasRef);
    // const fabCanvas = new fabric.Canvas(canvasRef, {
    //   width: availableWidth,
    //   height: availableHeight,
    // });
    // fabricCanvasRef.current?.clear();
    for (const item of itemList) {
      if (item.type === 'image') {
        const image = preloadedImages.current.get(item.file);
        if (image) {
          drawSingleImage(ctx, item, image, scale);

          // drawBorderAndHandlerForSelectedImage(ctx, item);
          if (includeBorder) {
            drawBorderForItem(ctx, item, scale);
          }
        }
      }
    }
    // fabCanvas.renderAll();
  };

  const drawSingleImage = (ctx, item, image, scale = null) => {
    const imageX = scale ? x + item.x / scale : x + item.x;
    const imageY = scale ? y + item.y / scale : y + item.y;
    const imageWidth = scale ? item.width / scale : item.width;
    const imageHeight = scale ? item.height / scale : item.height;

    // console.log(fabCanvas, fabricCanvasRef.current, 'testRef');

    // const canvas = new fabric.Canvas(canvasRef);

    // ctx.setWidth(availableWidth);
    // ctx.setHeight(availableHeight);

    // const fabImage = new fabric.FabricImage(image, {
    //   left: imageX,
    //   top: imageY,
    //   width: imageWidth,
    //   height: imageHeight,
    // });
    // fabric.FabricImage.fromURL(image.src, (fabImageParam) => {
    //   fabImageParam.set(fabImageParam, {
    //     left: imageX,
    //     top: imageY,
    //     width: imageWidth,
    //     height: imageHeight,
    //   });
    //   fabCanvas.add(fabImageParam);
    // });

    // canvas.add(fabricImage);
    // const isRotated = item?.rotated;
    ctx.drawImage(image, imageX, imageY, imageWidth, imageHeight);
    // drawImageWithOptionalRotation(
    //   ctx,
    //   image,
    //   imageX,
    //   imageY,
    //   imageWidth,
    //   imageHeight,
    //   isRotated
    // );
  };

  // function drawImageWithOptionalRotation(
  //   ctx,
  //   imageElement,
  //   imageX,
  //   imageY,
  //   imageWidth,
  //   imageHeight,
  //   rotated
  // ) {
  //   if (rotated) {
  //     // Rotate the image before drawing it
  //     const angleInDegrees = 90; // or any angle depending on your needs
  //     const angleInRadians = angleInDegrees * (Math.PI / 180);

  //     // Save the context state before transformation
  //     ctx.save();

  //     // Move the origin to the center of the image for rotation
  //     ctx.translate(imageX + imageWidth / 2, imageY + imageHeight / 2);

  //     // Rotate the canvas by the specified angle
  //     ctx.rotate(angleInRadians);

  //     // Swap width and height because of the rotation
  //     ctx.drawImage(
  //       imageElement,
  //       -imageHeight / 2,
  //       -imageWidth / 2,
  //       imageHeight,
  //       imageWidth
  //     );

  //     // Restore the context state
  //     ctx.restore();
  //   } else {
  //     // Draw the image without any rotation
  //     ctx.drawImage(imageElement, imageX, imageY, imageWidth, imageHeight);
  //   }
  // }

  const drawBorderForItem = (ctx, item, scale) => {
    const imageX = x + item.x;
    const imageY = y + item.y;
    const imageWidth = scale ? item.width / scale : item.width;
    const imageHeight = scale ? item.height / scale : item.height;
    // Selection border
    ctx.strokeStyle = 'black';
    ctx.lineWidth = 1;
    ctx.strokeRect(imageX, imageY, imageWidth, imageHeight);
  };

  const drawHandlerForSelectedImage = (ctx, item, scale = null) => {
    const imageX = x + item.x;
    const imageY = y + item.y;
    const imageWidth = scale ? item.width / scale : item.width;
    const imageHeight = scale ? item.height / scale : item.height;

    // handles (corners)
    const radius = 8;
    const corners = [
      { x: imageX, y: imageY },
      { x: imageX + imageWidth, y: imageY },
      { x: imageX, y: imageY + imageHeight },
      { x: imageX + imageWidth, y: imageY + imageHeight },
    ];

    ctx.fillStyle = 'black';
    corners.forEach((corner) => {
      ctx.beginPath();
      ctx.arc(corner.x, corner.y, radius, 0, 2 * Math.PI);
      ctx.fill();
    });
  };

  return {
    outerCanvasRef: canvasRef,
    redrawImage,
    translateImageCanvas,
    drawHandlerForSelectedImage,
  };
}

export default useCanvasImagePlacer;
