/* eslint-disable react/display-name */
import * as fabric from 'fabric';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useCanvasInteractions } from '../Hooks/useCanvasInteractions';
import { canvasUtils } from './canvasUtils';
import { Exclamation } from './Icons';

const DEV_MODE = process.env.NODE_ENV === 'development';

export const Canvas = ({
  onLoadSuccess,
  renderCount,
  triggerRender,
  refer,
  selectedDimensionsInInches,
  onPositionChange = () => {},
  onZoom = () => {},
  onObjectClick = () => {},
  isInPanMode = false,
  currentScale,
}) => {
  const canvasRef = useRef(null);
  const [overlappingObjects, setOverlappingObjects] = useState([]);

  const { height: selectedHeightInInches, width: selectedWidthInInches } =
    selectedDimensionsInInches.size;

  // For managing all the canvas interactions
  useCanvasInteractions(
    renderCount,
    canvasRef,
    refer.current,
    onZoom,
    onPositionChange,
    triggerRender,
    isInPanMode,
    currentScale
  );

  // Will run on canvas load
  const onLoad = useCallback(
    (canvas, prevObjects) => {
      const { fullWidth, fullHeight } = canvasUtils.findViewPortDimension();
      const viewPortMeasures = canvasUtils.findViewPortMeasures({
        fullWidth,
        fullHeight,
        viewportWidthInches: selectedWidthInInches,
        viewportHeightInches: selectedHeightInInches,
      });

      canvas.setWidth(fullWidth);
      canvas.setHeight(fullHeight);

      onLoadSuccess(
        { ...viewPortMeasures, fullWidth, fullHeight },
        canvas,
        prevObjects
      );
    },
    [refer, selectedHeightInInches, selectedWidthInInches]
  );

  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }

    function checkForOverlap(e) {
      const canvasInstance = e.target.canvas;
      const activeObject = e.target;

      if (!activeObject) return;

      const objects = canvasInstance.getObjects();
      for (let i = 0; i < objects.length; i++) {
        const obj = objects[i];
        if (obj === activeObject) continue; // Skip comparing with itself

        if (isOverlapping(activeObject, obj)) {
          // const requiredObjectName = activeObject.name;
          setOverlappingObjects([activeObject]);
          return; // Exit as soon as overlap is detected
        } else {
          setOverlappingObjects([]);
        }
      }
    }

    // Function to determine if two objects are overlapping
    function isOverlapping(obj1, obj2) {
      const obj1BoundingRect = obj1.getBoundingRect();
      const obj2BoundingRect = obj2.getBoundingRect();

      return !(
        obj1BoundingRect.left >
          obj2BoundingRect.left + obj2BoundingRect.width ||
        obj1BoundingRect.left + obj1BoundingRect.width <
          obj2BoundingRect.left ||
        obj1BoundingRect.top > obj2BoundingRect.top + obj2BoundingRect.height ||
        obj1BoundingRect.top + obj1BoundingRect.height < obj2BoundingRect.top
      );
    }

    const canvas = new fabric.Canvas(canvasRef.current);
    // canvas.toJSON();
    // if (!sheetMap[currentSheetName]) {
    //   createNewSheet(canvas, sheetMap);
    // }

    DEV_MODE && (window.canvas = canvas);

    if (typeof ref === 'function') {
      refer(canvas);
    } else if (typeof refer === 'object' && refer) {
      refer.current = canvas;
    }

    // it is crucial `onLoad` is a dependency of this effect
    // to ensure the canvas is disposed and re-created if it changes
    onLoad?.(canvas);

    const handleObjectModify = (e) => {
      triggerRender();
      checkForOverlap(e);
    };

    canvas.on('mouse:up', onObjectClick);
    canvas.on('object:added', triggerRender);
    canvas.on('object:removed', triggerRender);
    canvas.on('object:modified', triggerRender);
    canvas.on('object:scaling', handleObjectModify);
    canvas.on('object:moving', handleObjectModify);

    return () => {
      DEV_MODE && delete window.canvas;

      if (typeof ref === 'function') {
        refer(null);
      } else if (typeof refer === 'object' && refer) {
        refer.current = null;
      }
      canvas.off('mouse:up', onObjectClick);
      canvas.off('object:added', triggerRender);
      canvas.off('object:removed', triggerRender);
      canvas.off('object:modified', triggerRender);
      canvas.off('object:scaling', handleObjectModify);
      canvas.off('object:moving', handleObjectModify);

      canvas.dispose();
    };
  }, [canvasRef, onLoad, selectedHeightInInches, selectedWidthInInches]);

  const activeObject = refer.current?.getActiveObject?.() ?? null;
  const activeObjects = refer.current?.getActiveObjects?.() ?? [];
  const objects = refer.current?.getObjects?.() ?? [];

  return (
    <>
      <canvas ref={canvasRef} className="mt-0" />
      {overlappingObjects?.length > 0 &&
        activeObjects.length <= 1 &&
        objects.length > 1 &&
        activeObject && (
          <div className="warn-box">
            <Exclamation size={15} />
            <div>Images are overlapping</div>
          </div>
        )}
    </>
  );
};
