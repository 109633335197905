import React from 'react';

function LengthScaleContainer({ containerClass = 'x-length-scale', children }) {
  return <div className={`${containerClass} z-30 `}>{children}</div>;
}

const VerticalScale = ({
  height,
  offsetY,
  zoomScale = 0,
  selectedYSize = 0,
}) => {
  const number = 0;
  return (
    <div
      className="d-flex position-relative"
      style={{
        width: '1rem',
        height: height,
        transform: offsetY ? `translate(0px,${offsetY}px)` : 'translate(0px)',
        justifyContent: 'space-evenly',
        alignItems: 'flex-end',
        borderTop: '1px solid grey',
        borderBottom: '1px solid grey',
        flexDirection: 'column',
      }}
    >
      {Array(9)
        .fill('')
        .map((_, index) => {
          return (
            <div
              key={index}
              style={{
                height: '1px',
                width: '0.5rem',
                backgroundColor: 'grey',
              }}
            />
          );
        })}
      <div className="scale-text vertical-text start-length-vertical">
        {number}
      </div>
      <div className="scale-text vertical-text end-length end-length-vertical">
        {selectedYSize}
      </div>
    </div>
  );
};
const HorizontalScale = ({
  width,
  offsetX,
  zoomScale = 0,
  selectedXSize = 0,
}) => {
  const number = 0;
  return (
    <div
      className="d-flex"
      style={{
        width: width,
        height: '1rem',
        transform: offsetX ? `translate(${offsetX}px,0px)` : 'translate(0px)',
        justifyContent: 'space-evenly',
        alignItems: 'flex-end',
        borderLeft: '1px solid grey',
        borderRight: '1px solid grey',
      }}
    >
      {Array(9)
        .fill('')
        .map((_, index) => {
          return (
            <div
              key={index}
              style={{
                height: '0.5rem',
                width: '1px',
                backgroundColor: 'grey',
              }}
            />
          );
        })}
      <div className="scale-text start-length-horizontal">{number}</div>
      <div className="scale-text end-length-horizontal">{selectedXSize}</div>
    </div>
  );
};

export const LengthScale = {
  LengthScaleContainer,
  VerticalScale,
  HorizontalScale,
};
