import * as fabric from 'fabric';
import React, { createContext, useEffect, useRef, useState } from 'react';
import '../GangSheetBuilder/gangSheet.css';
import { GANG_SHEET_SIZE_OPTIONS } from '../GangSheetBuilder/utils';
import GangSheetViewPort from './GangSheetViewPort';
import SelectionAreaV2 from './SelectionAreaV2';
import { canvasConstants } from './canvasUtils';

// NOTES:
// For svg images, for obtaining 96dpi, adjusted it by scaling and shows as 300dpi. Since that's how vector images as handled to match physical measurement

const GsContext = createContext();

function GangSheetBuilderRoot() {
  const canvasRef = useRef(null);
  const imgRefs = useRef([]);

  const gsUnitValue = localStorage.getItem('gsUnitValue');
  const gsInitValue = gsUnitValue
    ? canvasConstants.GS_UNIT_VALUE[gsUnitValue]
    : canvasConstants.GS_UNIT_VALUE[25.4]; //mm(24.5) by default

  const getDefaultViewPort = () => {
    const defaultVewPortSize = GANG_SHEET_SIZE_OPTIONS.some(
      (options) => options.isDefault
    )
      ? GANG_SHEET_SIZE_OPTIONS.find((options) => options.isDefault)
      : GANG_SHEET_SIZE_OPTIONS[0];

    return defaultVewPortSize.value;
  };

  const [sheetMap, setSheetMap] = useState({
    Sheet1: {
      noOfItems: 0,
      canvas: {},
      selectedSize: getDefaultViewPort(),
    },
  });
  const [currentSheetName, setCurrentSheetName] = useState('Sheet1');
  const [isInUpload, setIsInUpload] = useState(false);
  const [selectedMargin, setSelectedMargin] = useState(0.5);
  const [selectedMultiplier, setSelectedMultiplier] = useState(1);
  const [selectedUnit, setSelectedUnit] = useState(gsInitValue);
  const [selectedOptions, setSelectedOptions] = useState({
    size: getDefaultViewPort(),
  });
  const [renderCount, setRenderCount] = useState(0);
  const [activeItem, setActiveItem] = useState(null);
  const [isInAutoBuild, setIsInAutoBuild] = useState(false);
  // For saving the init canvas measures(This will not change based on canvas zoom or position but viewPortMeasures state in GangSheetViewPort will change).
  const [initCanvasSize, setInitCanvasSize] = useState({
    x: 0,
    y: 0,
    availableWidth: 0,
    availableHeight: 0,
    fullWidth: 0,
    fullHeight: 0,
  });
  const [selectedUploadImages, setSelectUploadImages] = useState([]);

  const triggerRender = () => {
    setRenderCount((prev) => prev + 1);
  };

  useEffect(() => {
    if (canvasRef.current) {
      canvasRef.current.renderAll();
    }
  }, [renderCount]);

  return (
    <>
      <GsContext.Provider>
        <div className="gs-container">
          {/* // SELETION AREA */}
          <SelectionAreaV2
            selectedMarginInInches={selectedMargin}
            selectedMultiplier={selectedMultiplier}
            imgRefs={imgRefs}
            triggerRender={triggerRender}
            selectedOptions={selectedOptions}
            sheetMap={sheetMap}
            currentSheetName={currentSheetName}
            selectedUploadImages={selectedUploadImages}
            setSelectUploadImages={setSelectUploadImages}
            onImageUploadSelection={(files) => {
              setSelectUploadImages((prev) => [...prev, ...files]);
            }}
            isInAutoBuild={isInAutoBuild}
            onUploadFinish={() => {
              setIsInUpload(true);
            }}
            // onImageClick={(selectedImage) => {
            //   setSelectedGsItems((prev) => [...prev, selectedImage]);
            // }}
            initCanvasSize={initCanvasSize}
            selectedUnit={selectedUnit}
            canvas={canvasRef.current}
            activeItem={activeItem}
          />
          {/* // GANG SHEET AREA */}
          <GangSheetViewPort
            renderCount={renderCount}
            addedImageElements={selectedUploadImages}
            setSelectUploadImages={setSelectUploadImages}
            autoBuildPreviewState={[isInAutoBuild, setIsInAutoBuild]}
            setSelectedMultiplier={setSelectedMultiplier}
            selectedMultiplier={selectedMultiplier}
            selectedOptions={selectedOptions}
            onCanvasInit={setInitCanvasSize}
            onOptionChange={(options) => {
              const objects = canvasRef.current?._objects;

              setSelectedOptions(options);

              fabric.util.enlivenObjects(objects, (enlivenedObjects) => {
                enlivenedObjects.forEach((obj) => {
                  obj.scaleX *= initCanvasSize.scale;
                  obj.scaleY *= initCanvasSize.scale;
                  obj.left *= initCanvasSize.scale;
                  obj.top *= initCanvasSize.scale;
                  obj.setCoords();
                  canvasRef.current.add(obj);
                });
                canvasRef.current.renderAll();
              });
            }}
            canvasRef={canvasRef}
            sheetMapState={[sheetMap, setSheetMap]}
            currentSheetNameState={[currentSheetName, setCurrentSheetName]}
            onMarginUpdate={(newMargin) => setSelectedMargin(newMargin)}
            selectedMargin={selectedMargin}
            initCanvasMeasures={initCanvasSize}
            selectedUnit={selectedUnit}
            uploadState={[isInUpload, setIsInUpload]}
            onUnitUpdate={(newUnit) => setSelectedUnit(newUnit)}
            triggerRender={triggerRender}
            activeState={[activeItem, setActiveItem]}
          />
        </div>
      </GsContext.Provider>
    </>
  );
}

export default GangSheetBuilderRoot;
