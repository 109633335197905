import React from 'react';

function AlertMessagePopUp({
  message,
  isVisible = false,
  title,
  onClose = () => {},
}) {
  if (!message || !isVisible) {
    return null;
  }
  return (
    <div
      style={{
        position: 'fixed',

        zIndex: 99999,
        userSelect: 'none',
        inset: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0,0,0,0.5)',
      }}
    >
      <div
        style={{
          backgroundColor: 'white',
          padding: 15,
          display: 'flex',
          minWidth: 300,
          gap: 10,
          maxWidth: 400,
          flexDirection: 'column',
        }}
      >
        <div className="w-100">
          <div
            className="d-flex align-items-center"
            style={{ fontWeight: 600 }}
          >
            {title}
            {/* <div onClick={onClose} role="button">
              <X />
            </div> */}
          </div>
          <div className="mt-1" style={{ fontSize: 14 }}>
            {message}
          </div>
        </div>
        <div style={{ alignSelf: 'flex-end' }} className="w-fit mt-2">
          <div
            className="default-btn primary-bg-color"
            role="button"
            onClick={onClose}
          >
            OK
          </div>
        </div>
      </div>
    </div>
  );
}

export default AlertMessagePopUp;
