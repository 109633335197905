/* eslint-disable react-hooks/exhaustive-deps */
import * as fabric from 'fabric';
import React, { useEffect, useState } from 'react';
import { LengthScale } from '../GangSheetBuilder/LengthScale';
import SelectedImageDetails from '../GangSheetBuilder/SelectedImageDetails';
import {
  GANG_SHEET_SIZE_OPTIONS,
  getLabelInCustomUnit,
} from '../GangSheetBuilder/utils';
import AutoBuildButton from './AutoBuildButton';
import AutoBuildSelectionPreview, {
  EditableImageList,
} from './AutoBuildSelectionPreview';
import { Canvas } from './Canvas';
import { canvasConstants, canvasUtils } from './canvasUtils';
import FontOptions from './FontOptions';
import { DownloadIcon, HandIcon } from './Icons';
// import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import jsPDF from 'jspdf';
import JSZip from 'jszip';
import { Fullscreen, PackageCheck, Trash, ZoomIn, ZoomOut } from 'lucide-react';
import AlertActionPopUp from '../Component/AlertActionPopUp';
import AlertMessagePopUp from '../Component/AlertMessagePopUp';
import { apiConstants } from '../Constants/apiurl.constant';
import { imageServices } from '../service/image.service';
import {
  getOriginalValuesFromFabricObject,
  getScaledValueFromOriginalObject,
  loadFabricJsonData,
} from '../Utils/UtilFunctions';
import CanvasListPreview from './CanvasListPreview';
import CustomDropDown from './CustomDropDown';
import CustomInput from './CustomInput';
import DtfConfirmationModal from './DtfConfirmationModal';
import ExportOptions from './ExportOptions';

function GangSheetViewPort({
  selectedOptions,
  setSelectedMultiplier,
  selectedMultiplier,
  renderCount,
  addedImageElements = [],
  triggerRender,
  selectedUnit,
  onUnitUpdate = () => {},
  onOptionChange = () => {},
  onCanvasInit = () => {},
  setSelectUploadImages = () => {},
  canvasRef,
  activeState,
  initCanvasMeasures,
  onMarginUpdate,
  selectedMargin,
  currentSheetNameState,
  sheetMapState,
  uploadState,
  autoBuildPreviewState,
}) {
  const [isInAutoBuildPreview, setIsInAutoBuildPreview] = autoBuildPreviewState;
  const [isInUpload, setIsInUpload] = uploadState;
  const [gangSheetSizeOptions, setGangSheetSizeOptions] = useState(
    GANG_SHEET_SIZE_OPTIONS
  );
  const [isAutoBuilding, setIsAutoBuilding] = useState(false);
  const [pageLoadDetails, setPageLoadDetails] = useState({
    isLoading: false,
    totalItems: null,
    addedItems: 0,
    showCount: false,
  });

  const [autoBuildOptions, setAutoBuildOptions] = useState({
    isSmart: false,
    isMaxArea: true,
  });
  const [isInPanMode, setIsInPanMode] = useState(false);
  // const [quantity, setQuantity] = useState(1);
  const [sheetMap, setSheetMap] = sheetMapState;
  const [errorObject, setErrorObject] = useState({
    message: null,
    title: null,
    isVisible: false,
  });

  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);

  const [currentSheetName, setCurrentSheetName] = currentSheetNameState;
  const currentSheetDetails = sheetMap[currentSheetName] ?? null;
  const [processingDetails, setProcessingDetails] = useState({
    status: false,
    processedSheets: null,
    isMultiDownload: false,
    totalSheets: null,
  });
  const [sheetToRemove, setSheetToRemove] = useState(null);
  const [isExportVisible, setIsExportVisible] = useState(false);
  const [autoBuildSelectionList, setAutoBuildSelectionList] = useState([]);
  const [viewPortMeasures, setViewPortMeasures] = useState({
    x: 0,
    y: 0,
    fullWidth: 0,
    fullHeight: 0,
    availableWidth: 0,
    availableHeight: 0,
    scale: 0,
  });

  const [activeItem, setActiveItem] = activeState;

  const allCanvas = Object.entries(sheetMap).map(([key, value]) => {
    return { ...value, sheetName: key };
  });

  // TempSolution
  useEffect(() => {
    if (isInUpload) {
      const sheetMapArray = Object.entries(sheetMap)
        .map(([key, value]) => ({ ...value, sheetName: key }))
        .filter((item) => item.sheetName !== currentSheetName);

      const [...restObjects] = canvasRef.current._objects;
      const otherSheetObject = sheetMapArray.flatMap(
        (item) => JSON.parse(item.canvas).objects
      );

      const allObjects = [...restObjects, ...otherSheetObject];

      const selectionList = canvasUtils.generateSelectionItemsForAutoBuild({
        canvasItemList: allObjects ?? [],
        addedImageList: addedImageElements.filter(
          (item) => !item?.isUploading && !item?.isFailed
        ),
        viewPortMeasures: viewPortMeasures,
      });

      setAutoBuildSelectionList(selectionList);
      setIsInAutoBuildPreview(true);
    }
  }, [isInUpload]);

  const switchSheet = (
    sourceSheetName,
    targetSheetName,
    latestSheetMap = null
  ) => {
    if (!sourceSheetName || !targetSheetName) {
      return;
    }

    if (sourceSheetName === targetSheetName) {
      return;
    }

    canvasRef.current.renderAll();
    // const sheet = sheetMap[sheetName];

    const objectLength = canvasRef.current?.getObjects()?.length ?? 0;
    const stringifiedCanvas = JSON.stringify(canvasRef.current.toJSON());
    const canvasOptions = canvasUtils.getZoomAndTransformValues(
      canvasRef.current
    );
    setSheetMap((prev) => {
      return {
        ...prev,
        [sourceSheetName]: {
          canvas: stringifiedCanvas,
          selectedSize:
            prev?.[sourceSheetName]?.selectedSize ?? selectedOptions.size,
          noOfItems: objectLength,
          lastViewPortMeasures: { ...viewPortMeasures },
          lastInitMeasures: { ...initCanvasMeasures },
          canvasOptions,
        },
      };
    });

    const targetSheet = latestSheetMap
      ? latestSheetMap[targetSheetName]
      : sheetMap[targetSheetName];
    const targetJsonData = targetSheet.canvas;
    // If targetSheetContains the viewPortMeasure, take that and set it to viewPortMeasure. Otherwise find new one.
    if (targetSheet.lastViewPortMeasures) {
      // Setting the initial measure
      onCanvasInit(targetSheet.lastInitMeasures);
      // Set varying measures.
      setViewPortMeasures(targetSheet.lastViewPortMeasures);
    } else {
      const viewPortData = canvasUtils.findViewPortMeasures({
        fullWidth: viewPortMeasures.fullWidth,
        fullHeight: viewPortMeasures.fullHeight,
        viewportWidthInches: targetSheet.selectedSize.width,
        viewportHeightInches: targetSheet.selectedSize.height,
      });

      const viewPortFinalData = {
        ...viewPortData,
        fullWidth: viewPortMeasures.fullWidth,
        fullHeight: viewPortMeasures.fullHeight,
      };

      // Setting the initial measure
      onCanvasInit(viewPortFinalData);
      // Set varying measures.
      setViewPortMeasures(viewPortFinalData);
    }

    canvasRef.current.clear();

    // canvasRef.current.loadFromJSON(targetJsonData, (serializedObj) => {
    //   // canvasRef.current.getObjects().forEach((obj) => {
    //   serializedObj.borderColor = 'black';
    //   serializedObj.cornerColor = 'black';
    //   serializedObj.cornerStrokeColor = 'black';
    // });
    fabric.FabricObject.prototype.set({
      borderColor: 'black', // Set the border color for selection
      cornerColor: 'black', // Set the corner controls' fill color
      cornerStrokeColor: 'black', // Set the corner controls' stroke color
      transparentCorners: false, // Ensure corners are not transparent
      cornerSize: 12, // Set the size of the corner controls
      borderScaleFactor: 2, // Adjust the border thickness
      hasControls: true, // Ensure controls are enabled
    });

    canvasRef.current.loadFromJSON(
      targetJsonData,
      canvasRef.current.renderAll.bind(canvasRef.current),
      () => {
        // Ensure the canvas re-renders after making changes
        canvasRef.current.renderAll();
      }
    );
    canvasRef.current.setZoom(targetSheet.canvasOptions.zoom);
    canvasRef.current.viewportTransform = targetSheet.canvasOptions.transform;
    canvasRef.current.renderAll();
    setCurrentSheetName(targetSheetName);
    return canvasRef;
  };

  const createNewSheet = () => {
    const sheetName = canvasUtils.generateNextSheetName(sheetMap);

    if (currentSheetName) {
      canvasRef.current.renderAll();
      const objectLength = canvasRef.current?.getObjects()?.length ?? 0;
      const stringifiedCanvas = JSON.stringify(canvasRef.current.toJSON());
      setSheetMap((prev) => ({
        ...prev,
        [currentSheetName]: {
          canvas: stringifiedCanvas,
          selectedSize: selectedOptions.size,
          noOfItems: objectLength,
          lastViewPortMeasures: { ...viewPortMeasures },
          lastInitMeasures: { ...initCanvasMeasures },
          canvasOptions: canvasUtils.getZoomAndTransformValues(
            canvasRef.current
          ),
        },
      }));
    }
    canvasRef.current.clear();

    const viewPortData = canvasUtils.findViewPortMeasures({
      fullWidth: viewPortMeasures.fullWidth,
      fullHeight: viewPortMeasures.fullHeight,
      viewportWidthInches: selectedOptions.size.width,
      viewportHeightInches: selectedOptions.size.height,
    });

    const viewPortFinalData = {
      ...viewPortData,
      fullWidth: viewPortMeasures.fullWidth,
      fullHeight: viewPortMeasures.fullHeight,
    };

    setCurrentSheetName(sheetName);
    const stringifiedCanvas = JSON.stringify(canvasRef.current.toJSON());
    // New Sheet
    setSheetMap((prev) => ({
      ...prev,
      [sheetName]: {
        canvas: stringifiedCanvas,
        selectedSize: selectedOptions.size,
        lastViewPortMeasures: { ...viewPortFinalData },
        lastInitMeasures: { ...viewPortFinalData },
        canvasOptions: canvasUtils.getZoomAndTransformValues(canvasRef.current),
      },
    }));

    // Setting the initial measure
    onCanvasInit(viewPortFinalData);
    // Set varying measures.
    setViewPortMeasures(viewPortFinalData);

    // switchSheet(currentSheetName, sheetName);
  };

  const removeSheet = (sheetDetails) => {
    const sheetName = sheetDetails.sheetName;

    const keys = Object.keys(sheetMap);

    const index = keys.indexOf(sheetName);

    const nextKey =
      index > 0 ? keys[index - 1] : keys[index + 1] ? keys[index + 1] : null;

    if (!nextKey) {
      return;
    }
    switchSheet(sheetName, nextKey);

    setSheetMap((prevState) => {
      // const { [sheetName]: _, ...newState } = prevState;
      const newData = { ...prevState };
      delete newData[sheetName];

      return newData;
    });
  };

  const generateZipFromUrl = async (urlsWithFileName = []) => {
    if (urlsWithFileName.length <= 0) {
      return null;
    }

    const zip = new JSZip();

    // Loop through each file URL and fetch the content
    await Promise.all(
      urlsWithFileName.map(async (fileUrlWithName, index) => {
        const response = await fetch(fileUrlWithName.url);
        const blob = await response.blob();

        // Extract the file extension from the URL (e.g., .eps)
        const fileExtension = fileUrlWithName.url.split('.').pop(); // get the extension from the URL

        // Add each file to the zip folder using the correct extension
        zip.file(
          `${fileUrlWithName?.name ?? `file${index + 1}`}.${fileExtension}`,
          blob
        );
      })
    );

    return zip;
  };

  const downloadEpsFile = async (
    epsMethodTwo,
    canvas,
    customInitMeasures = null
  ) => {
    const { fabricObj } = canvasUtils.generateDownloadableCanvasForSvg(
      customInitMeasures ?? initCanvasMeasures,
      canvas
    );

    const svg = fabricObj.toSVG({
      enableRetinaScaling: false,
      suppressPreamble: false,
    });

    const blob = new Blob([svg], { type: 'image/svg+xml' });
    const file = new File([blob], 'exported_img.svg', {
      type: 'image/svg',
    });

    try {
      const res = await (epsMethodTwo
        ? imageServices.convertSvgToRasterizedEpsV3ViaPdf(file)
        : imageServices.convertSvgToRasterizedEpsV3(file));
      return res;
    } catch (err) {
      console.log(err, 'err');
    }
  };

  const handleFileDownload = async (type, otherOptions) => {
    const {
      externalDownload = false,
      highProcess,
      downloadAllSheets = true,
      processEmbedded,
      epsMethodTwo,
    } = otherOptions;

    // setIsDownloading(true);
    const DPI = canvasConstants.CANVAS_DPI;
    const selectedHeight = selectedOptions.size.height * DPI;
    const currentHeight = viewPortMeasures.availableHeight;
    const multiplier = selectedHeight / currentHeight;

    try {
      if (type === 'EPS') {
        if (downloadAllSheets) {
          const sheets = Object.entries(sheetMap)
            .filter(
              ([key, value]) => JSON.parse(value?.canvas)?.objects?.length > 0
            )
            .map(([key, value]) => ({
              ...value,
              name: key,
            }));

          setProcessingDetails((prev) => ({
            ...prev,
            status: true,
            isMultiDownload: true,
            totalSheets: sheets.length,
            processedSheets: 0,
          }));

          const fileUrlsWithSheetName = [];
          // const promises = [];

          for (const sheet of sheets) {
            try {
              const objects = await loadFabricJsonData(sheet.canvas);

              const updatedSheet = {
                ...sheet,
                getObjects: () => {
                  return objects;
                },
              };

              const result = await downloadEpsFile(
                epsMethodTwo,
                updatedSheet,
                sheet.lastInitMeasures
              );

              fileUrlsWithSheetName.push({
                url: apiConstants.imgUrlS3 + result.data[0].key,
                name: sheet.name,
              });
              setProcessingDetails((prev) => ({
                ...prev,
                processedSheets: (prev?.processedSheets ?? 0) + 1,
              }));
            } catch (error) {
              console.error('Error downloading file:', error);
              // Handle the error or continue based on your requirement
            }
          }

          if (fileUrlsWithSheetName.length <= 0) {
            setProcessingDetails({ status: false });
            return;
          }
          try {
            const zip = await generateZipFromUrl(fileUrlsWithSheetName);
            zip.generateAsync({ type: 'blob' }).then((blob) => {
              saveAs(blob, 'compressed_file.zip');
            });
            setIsExportVisible(false);
          } catch (err) {
            // Error on zip generation
          }
          setProcessingDetails({ status: false });
          return;
        }

        // If single sheet download
        try {
          setProcessingDetails({ status: true });
          const res = await downloadEpsFile(epsMethodTwo, canvasRef.current);
          // const link = document.createElement('a');
          saveAs(apiConstants.imgUrlS3 + res.data[0].key, res.data[0].key);
          // link.href = apiConstants.imgUrlS3 + res.data[0].key;
          // link.download = res.data[0].key;
          // link.target = '_blank';
          // link.click();
          setIsExportVisible(false); // Close only if success
        } catch (err) {
          console.log(err, 'err');
        } finally {
          setProcessingDetails({ status: false });
        }
        return;
      }

      if (type === 'SVG') {
        // const svg = canvasRef.current.toSVG();
        const { fabricObj } = canvasUtils.generateDownloadableCanvasForSvg(
          initCanvasMeasures,
          canvasRef.current
        );

        const svg = fabricObj.toSVG({
          enableRetinaScaling: false,
          suppressPreamble: false,
        });

        const blob = new Blob([svg], { type: 'image/svg+xml' });
        const file = new File([blob], 'exported_img.svg', {
          type: 'image/svg',
        });
        if (externalDownload) {
          try {
            setProcessingDetails({ status: true });
            const res = await (processEmbedded
              ? imageServices.processSvgWithUrlV2(file)
              : imageServices.processSvgWithUrl(file));

            const link = document.createElement('a');
            link.href = apiConstants.imgUrlS3 + res.data[0].key;
            link.download = res.data[0].key;
            link.target = '_blank';
            link.click();
          } catch (err) {
            console.log(err, 'err');
          } finally {
            setProcessingDetails({ status: false });
          }
        } else {
          const url = URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = url;
          link.download = 'exported_img.svg';
          link.click();
          URL.revokeObjectURL(url);
        }

        setIsExportVisible(false);
        return;
      }

      if (type === 'PNG') {
        // 1st image
        const croppedImage = canvasRef.current.toDataURL({
          left: viewPortMeasures.x,
          top: viewPortMeasures.y,
          width: viewPortMeasures.availableWidth,
          height: viewPortMeasures.availableHeight,
          quality: 1,
          multiplier: multiplier,
          format: 'png',
        });
        const link = document.createElement('a');
        link.href = croppedImage;
        link.download = 'exported_img.png';
        link.click();

        // 2nd image

        const { fabricObj } = canvasUtils.generateDownloadCanvas(
          initCanvasMeasures,
          canvasRef.current
        );

        const hiRes = fabricObj.toDataURL({
          quality: 1,
          format: 'png',
          multiplier: selectedMultiplier ? parseInt(selectedMultiplier) : 1,
        });

        const link1 = document.createElement('a');
        link1.href = hiRes;
        link1.download = 'exported_img_2.png';
        link1.click();

        setIsExportVisible(false);
        return;
      }
      if (type === 'JPEG') {
        // 1st image
        const croppedImage = canvasRef.current.toDataURL({
          left: viewPortMeasures.x,
          top: viewPortMeasures.y,
          width: viewPortMeasures.availableWidth,
          height: viewPortMeasures.availableHeight,
          quality: 1,
          multiplier: multiplier,
          format: 'jpeg',
        });
        const link = document.createElement('a');
        link.href = croppedImage;
        link.download = 'exported_img.jpeg';
        link.click();

        // 2nd image
        const { fabricObj } = canvasUtils.generateDownloadCanvas(
          initCanvasMeasures,
          canvasRef.current
        );

        const hiRes = fabricObj.toDataURL({
          quality: 1,
          format: 'jpeg',
          multiplier: selectedMultiplier ? parseInt(selectedMultiplier) : 1,
        });

        const link1 = document.createElement('a');
        link1.href = hiRes;
        link1.download = 'exported_img_2.jpeg';
        link1.click();

        setIsExportVisible(false);
        return;
      }

      if (type === 'PDF') {
        const { fabricObj } = canvasUtils.generateDownloadableCanvasForSvg(
          initCanvasMeasures,
          canvasRef.current
        );
        if (externalDownload) {
          const svg = fabricObj.toSVG({
            enableRetinaScaling: false,
            suppressPreamble: false,
          });

          const blob = new Blob([svg], { type: 'image/svg+xml' });
          const file = new File([blob], 'exported_img.svg', {
            type: 'image/svg',
          });
          try {
            setProcessingDetails({ status: true });
            const res = await imageServices.convertSvgToPdf(file);

            const link = document.createElement('a');
            link.href = apiConstants.imgUrlS3 + res.data[0].key;
            link.download = res.data[0].key;
            link.target = '_blank';
            link.click();
          } catch (err) {
            console.log(err, 'err');
          } finally {
            setProcessingDetails({ status: false });
          }
          return;
        }
        // const { fabricObj } = canvasUtils.generateDownloadCanvas(
        //   viewPortMeasures,
        //   canvasRef.current
        // );

        // const imgData = fabricObj.toDataURL({
        //   quality: 1,
        // });

        // Create a new jsPDF instance
        const orientation1 =
          viewPortMeasures.availableWidth > viewPortMeasures.availableHeight
            ? 'l'
            : 'p';
        const pdf1 = new jsPDF({
          orientation: orientation1,
          unit: 'px',
          format: [
            viewPortMeasures.availableWidth,
            viewPortMeasures.availableHeight,
          ],
        });
        // Add the image to the PDF
        try {
          pdf1.addImage(
            fabricObj.toCanvasElement(),
            'PNG',
            0,
            0,
            viewPortMeasures.availableWidth,
            viewPortMeasures.availableHeight
          );
        } catch (err) {
          console.error(err, 'pdf Error caught');
        }

        // Save the PDF
        pdf1.save('canvas1-cca.pdf');

        // Export the canvas to a data URL as an image
        const imgData = fabricObj?.toDataURL({
          left: viewPortMeasures.x,
          top: viewPortMeasures.y,
          width: viewPortMeasures.availableWidth,
          height: viewPortMeasures.availableHeight,
          quality: 1,
          multiplier: multiplier,
          format: 'jpeg',
        });

        // Create a new jsPDF instance
        const orientation =
          viewPortMeasures.availableWidth > viewPortMeasures.availableHeight
            ? 'l'
            : 'p';
        const pdf = new jsPDF({
          orientation: orientation,
          unit: 'px',
          format: [
            viewPortMeasures.availableWidth,
            viewPortMeasures.availableHeight,
          ],
        });

        // Add the image to the PDF
        pdf.addImage(
          imgData,
          'JPEG',
          0,
          0,
          viewPortMeasures.availableWidth,
          viewPortMeasures.availableHeight
        );

        // Save the PDF
        pdf.save('canvas1.pdf');
        setIsExportVisible(false);
      }
    } catch (err) {
      setProcessingDetails({ status: false });
    }
  };

  const activeObject = canvasRef.current?.getActiveObject();

  const addAutoBuildItemsToCanvas = async (sheets) => {
    setPageLoadDetails({ isLoading: true });
    const totalLength = sheets.reduce((acc, currentItem) => {
      return acc + (currentItem?.objects?.length ?? 0);
    }, 0);
    const { sheets: macRectFInalSheets, tempSheetMap } =
      await canvasUtils.generateAutoBuildObject({
        canvasRef,
        initCanvasMeasures,
        selectedOptions,
        viewPortMeasures: initCanvasMeasures, //We have reset viewPortMeasure to initCanvasMeasure above
        sheets: sheets,
        getImageElement: (obj) =>
          addedImageElements.find(
            (item) => item?._originalElement.src === obj.src
          )?._originalElement,
        onImageAdd: () => {
          setPageLoadDetails((prev) => ({
            ...prev,
            addedItems: (prev?.addedItems ?? 0) + 1,
            totalItems: totalLength,
          }));
        },
        onSingleImageDrawFinish: () => {
          setIsInAutoBuildPreview(false);
          setIsInUpload(false);
        },
      });
    setIsInAutoBuildPreview(false);
    setIsInUpload(false);
    setSheetMap(tempSheetMap);
    const hasMultiplePage = Object.keys(tempSheetMap).length > 1;
    const sourceSheetName =
      macRectFInalSheets[macRectFInalSheets.length - 1].name;
    const targetSheetName = macRectFInalSheets[0].name;
    if (hasMultiplePage) {
      switchSheet(sourceSheetName, targetSheetName, tempSheetMap);
    } else {
      setCurrentSheetName(targetSheetName);
    }
    // Switch to first page
    setPageLoadDetails({ isLoading: false });
  };

  const { hasObjects } = canvasUtils.hasObjectsInCanvas(
    canvasRef?.current,
    sheetMap,
    currentSheetName
  );

  return (
    <div className="gs-viewport-container position-relative">
      {isInAutoBuildPreview && (
        <AutoBuildSelectionPreview
          selectedOptions={selectedOptions}
          addAutoBuildItemsToCanvas={addAutoBuildItemsToCanvas}
          isAutoBuilding={isAutoBuilding}
          initCanvasMeasures={initCanvasMeasures}
          setSelectionList={setAutoBuildSelectionList}
          onRefresh={() => {
            const canvasObjects = canvasRef.current.getObjects();

            const selectionList =
              canvasUtils.generateSelectionItemsForAutoBuild({
                canvasItemList:
                  canvasObjects.filter(
                    (obj) => obj?.type?.toLowerCase() === 'image'
                  ) ?? [],
                addedImageList: addedImageElements.filter(
                  (item) => !item?.isUploading && !item?.isFailed
                ),
                viewPortMeasures: viewPortMeasures,
              });

            setAutoBuildSelectionList(selectionList);
          }}
          autoBuildOptions={autoBuildOptions}
          onAutoBuildOptionChange={(name, value) => {
            setAutoBuildOptions((prev) => ({
              ...prev,
              [name]: value,
            }));
          }}
          selectedUnit={selectedUnit}
          addedImageList={addedImageElements}
          defaultMargin={selectedMargin}
          viewportMeasures={viewPortMeasures}
          onSubmit={async (allItems, marginsInUnit) => {
            const { availableWidth, availableHeight, scale } =
              initCanvasMeasures;

            // const tempSheetMap = {};
            const { artBoardMargin, imageMargin } = marginsInUnit;
            const margins = {
              artBoardMargin:
                (artBoardMargin * canvasConstants.CANVAS_DPI * scale) /
                selectedUnit.value,
              imageMargin:
                (imageMargin * canvasConstants.CANVAS_DPI * scale) /
                selectedUnit.value,
            };
            canvasUtils.resetTheZoomTransformation(canvasRef.current);
            setViewPortMeasures(initCanvasMeasures);

            setIsAutoBuilding(true);

            const { canvasDetails, objects } =
              getOriginalValuesFromFabricObject({
                marginsInUnit: marginsInUnit,
                allObjects: allItems,
                selectedUnit: selectedUnit,
                viewportMeasures: initCanvasMeasures,
              });

            const payload = {
              canvasDetails,
              objects,
              options: autoBuildOptions,
            };

            let { newSheets: maxRectSheets, overSizedImages } =
              await canvasUtils.generateSheetsFromMaxRectAlgo({
                availableHeight: availableHeight,
                availableWidth: availableWidth,
                allItems,
                autoBuildOptions,
                margins,
                payloadData: payload,
                payloadFromOutside: true,
              });

            const updatedMaxRect = getScaledValueFromOriginalObject({
              objects: maxRectSheets,
              scale,
            });

            console.log('ALL_OBJECTS MAX RECT', updatedMaxRect);

            maxRectSheets = updatedMaxRect;
            setIsAutoBuilding(false);

            if (overSizedImages.length > 0) {
              setErrorObject({
                message: `${overSizedImages.length} oversized image(s) found. They will be ignored.`,
                isVisible: true,
                title: 'Oversized Images Detected',
              });
            }

            if (!maxRectSheets || maxRectSheets.length <= 0) {
              setIsInAutoBuildPreview(false);
              setIsInUpload(false);
              return;
            }

            // If sheet length is 1, we can directly apply to the canvas
            if (maxRectSheets.length <= 1) {
              addAutoBuildItemsToCanvas(maxRectSheets);
              return null;
            }

            return maxRectSheets;
          }}
          selectionList={autoBuildSelectionList}
          onClose={() => {
            setIsInAutoBuildPreview(false);
            setIsInUpload(false);
            setIsAutoBuilding(false);
          }}
        />
      )}
      <div className="gs-vp-toolbar d-flex align-items-center justify-content-between px-3 gap-2">
        {/* ToolBar */}
        <div>
          <FontOptions
            canvas={canvasRef.current}
            onFontChange={(font) => {
              const activeObject = canvasRef.current.getActiveObject();
              activeObject.set('fontFamily', font);
              activeItem.setCoords();
              triggerRender();
            }}
            onColorChange={(color) => {
              const activeObject = canvasRef.current.getActiveObject();
              activeObject.set('fill', color);
              triggerRender();
            }}
          />
        </div>

        <div className="d-flex gap-2">
          {activeObject && (
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                border: '1px solid grey',
                width: 30,
                height: 30,
                cursor: 'pointer',
                display: 'flex',
                borderRadius: 4,
              }}
              onClick={() => {
                canvasUtils.removeActiveObjectFromCanvas({
                  canvas: canvasRef.current,
                });
              }}
            >
              <Trash size={16} />
            </div>
          )}
          <div
            role="button"
            onClick={() => {
              setIsInPanMode((prev) => !prev);
              canvasRef.current.renderAll();
            }}
            className="d-flex align-items-center justify-content-center"
            style={{
              border: '1px solid grey',
              width: 30,
              height: 30,
              cursor: 'pointer',
              display: 'flex',
              borderRadius: 4,
              backgroundColor: isInPanMode ? 'black' : 'transparent',
            }}
          >
            {/* <Hand
              height={13}
              width={10}
              color={isInPanMode ? 'white' : 'black'}
            /> */}
            <HandIcon size={16} color={isInPanMode ? 'white' : 'black'} />
          </div>
          <div
            className="d-flex align-items-center gap-2 px-2"
            style={{
              borderRight: '1px solid #e6e6e6',
              borderLeft: '1px solid #e6e6e6',
              userSelect: 'none',
            }}
          >
            <div
              className="grey-button"
              onClick={() => {
                onUnitUpdate(canvasConstants.GS_UNIT_VALUE[25.4]);
                localStorage.setItem('gsUnitValue', 25.4);
              }}
              style={{
                //   fontWeight: selectedUnit.value !== 1 ? '400' : '600',
                backgroundColor:
                  selectedUnit.value !== 1 ? 'black' : 'rgb(242, 242, 242)',
                color: selectedUnit.value !== 1 ? 'white' : 'initial',
                //   cursor: 'pointer',
              }}
            >
              mm
            </div>

            <div
              className="grey-button"
              style={{
                backgroundColor:
                  selectedUnit.value === 1 ? 'black' : 'rgb(242, 242, 242)',
                color: selectedUnit.value === 1 ? 'white' : 'initial',
              }}
              onClick={() => {
                onUnitUpdate(canvasConstants.GS_UNIT_VALUE[1]);
                localStorage.setItem('gsUnitValue', 1);
              }}
            >
              inch
            </div>
          </div>

          <CustomInput
            value={selectedMargin * selectedUnit.value}
            label="Margin"
            minWidth={10}
            maxWidth={'none'}
            postText={selectedUnit.value === 1 ? 'in' : 'mm'}
            step={0.5}
            onChange={(value) => {
              onMarginUpdate(value / (selectedUnit.value ?? 1));
            }}
          />

          <CustomDropDown
            value={
              gangSheetSizeOptions?.find(
                (item) =>
                  item.value.width === currentSheetDetails.selectedSize.width &&
                  item.value.height === currentSheetDetails.selectedSize.height
              )?.key
            }
            hasCustomOption={true}
            renderCustomOption={({ onClose }) => {
              return (
                <CustomViewPortInput
                  onClose={onClose}
                  selectedUnit={selectedUnit}
                  onSubmit={(selectedSize) => {
                    const isOptionAlreadyExist = gangSheetSizeOptions.find(
                      (item) =>
                        item.value.width === selectedSize.width &&
                        item.value.height === selectedSize.height
                    );

                    if (!isOptionAlreadyExist) {
                      setGangSheetSizeOptions((prev) => [
                        ...prev,
                        {
                          label: `${selectedSize.width.toFixed(2)}"x${selectedSize.height.toFixed(2)}"`,
                          value: {
                            width: selectedSize.width,
                            height: selectedSize.height,
                          },
                          key: `W${selectedSize.width}H${selectedSize.height}`,
                        },
                      ]);
                    }

                    onOptionChange({
                      size: {
                        height: selectedSize.height,
                        width: selectedSize.width,
                      },
                    });
                    setSheetMap((prev) => ({
                      ...prev,
                      [currentSheetName]: {
                        ...prev[currentSheetName],
                        selectedSize: {
                          height: selectedSize.height,
                          width: selectedSize.width,
                        },
                      },
                    }));
                  }}
                />
              );
            }}
            options={gangSheetSizeOptions}
            getOptionsLabel={(option) =>
              selectedUnit.value === 1
                ? option.label
                : getLabelInCustomUnit(
                    option.value.width,
                    option.value.height,
                    selectedUnit.value
                  )
            }
            getOptionsValue={(option) => option.key}
            onChange={(option) => {
              onOptionChange({
                size: {
                  height: option.value.height,
                  width: option.value.width,
                },
              });
              setSheetMap((prev) => ({
                ...prev,
                [currentSheetName]: {
                  ...prev[currentSheetName],
                  selectedSize: {
                    height: option.value.height,
                    width: option.value.width,
                  },
                },
              }));
            }}
          />

          <button
            className="shdw-button primary-bg-color"
            onClick={() => {
              setIsExportVisible(true);
            }}
          >
            <div>
              <DownloadIcon />
            </div>
            <div> Download</div>
          </button>
          <button
            disabled={!hasObjects}
            className={`shdw-button ${!hasObjects ? 'disabled-btn' : 'primary-bg-color'}`}
            onClick={() => setIsConfirmModalVisible(true)}
          >
            <div>
              <PackageCheck size={15} />
            </div>
            Approval
          </button>
        </div>
      </div>
      <div className="position-relative gs-vp-content">
        {/* Selected image details */}
        <SelectedImageDetails
          selectedItem={activeItem}
          viewportMeasures={viewPortMeasures}
          initCanvasMeasures={initCanvasMeasures}
          selectedUnit={selectedUnit}
        />
        {/* SCALE START */}
        {/* Absolute top length scale */}
        <LengthScale.LengthScaleContainer>
          <LengthScale.HorizontalScale
            width={viewPortMeasures.availableWidth}
            offsetX={viewPortMeasures.x}
            selectedXSize={(
              selectedOptions.size.width * selectedUnit.value
            ).toFixed()}
          />
        </LengthScale.LengthScaleContainer>
        {/* Absolute left length scale */}
        <LengthScale.LengthScaleContainer containerClass="y-length-scale">
          <LengthScale.VerticalScale
            height={viewPortMeasures.availableHeight}
            offsetY={viewPortMeasures.y}
            selectedYSize={(
              selectedOptions.size.height * selectedUnit.value
            ).toFixed()}
          />
        </LengthScale.LengthScaleContainer>

        {/* Zoom Reset Options */}
        <CanvasOptions
          onReset={() => {
            canvasUtils.resetTheZoomTransformation(canvasRef.current);
            setViewPortMeasures(initCanvasMeasures);
          }}
          canvas={canvasRef.current}
          canvasMeasures={viewPortMeasures}
          setViewPortMeasures={setViewPortMeasures}
        />

        {/* Canvas Selection */}
        <CanvasListPreview
          canvasList={allCanvas}
          onCanvasAdd={createNewSheet}
          selectedUnit={selectedUnit}
          onCanvasSwitch={switchSheet}
          onSheetRemove={(sheetDetails) => {
            const sheetName = sheetDetails.sheetName;
            // If sheet contains items then show warning popUp before delete
            if (currentSheetName === sheetName) {
              const objectLength = canvasRef.current?.getObjects()?.length ?? 0;
              if (objectLength > 0) {
                // SHow popup and return
                setSheetToRemove(sheetDetails);
                return;
              }
            }

            const noOfItems = sheetMap[sheetName].noOfItems;
            if (noOfItems > 0) {
              // SHow popup and return
              setSheetToRemove(sheetDetails);
              return;
            }

            // Otherwise delete
            removeSheet(sheetDetails);
          }}
          currentSheetName={currentSheetName}
        />
        {/* // Bottom Button */}
        <AutoBuildButton
          onAutoBuildClick={() => {
            const sheetMapArray = Object.entries(sheetMap)
              .map(([key, value]) => ({ ...value, sheetName: key }))
              .filter(
                (item) =>
                  item.sheetName !== currentSheetName &&
                  item?.lastInitMeasures?.scale?.toFixed(2) ===
                    initCanvasMeasures?.scale?.toFixed(2)
              );

            const [...restObjects] = canvasRef.current._objects;
            const otherSheetObject = sheetMapArray.flatMap(
              (item) => JSON.parse(item.canvas).objects
            );

            const allObjects = [...restObjects, ...otherSheetObject];

            const selectionList =
              canvasUtils.generateSelectionItemsForAutoBuild({
                canvasItemList: allObjects ?? [],
                addedImageList: addedImageElements.filter(
                  (item) => !item?.isUploading && !item?.isFailed
                ),
                viewPortMeasures: viewPortMeasures,
              });

            setAutoBuildSelectionList(selectionList);
            setIsInAutoBuildPreview(true);
          }}
        />
        {/* <MeasureOverview fabricCanvas={canvasRef.current} /> */}
        <div className="scroll-bar-bottom z-30"></div>
        <div className="scroll-bar-right z-30"></div>
        <div className="scale-junction"></div>
        {/* SCALE END*/}
        {/* Toolbar */}
        {/* Visible View Port  */}
        <div
          className="gs-editor-container"

          // ref={containerRef}
        >
          <div
            className="edit-area light-canvas-img "
            // dark-canvas-img
            style={{
              minWidth: viewPortMeasures.availableWidth,
              maxWidth: viewPortMeasures.availableWidth,
              minHeight: viewPortMeasures.availableHeight,
              maxHeight: viewPortMeasures.availableHeight,
              transform:
                viewPortMeasures.x || viewPortMeasures.y
                  ? `translate(${viewPortMeasures.x}px,${viewPortMeasures.y}px)`
                  : 'translate(0px)',
            }}
          />
          <div className="gs-canvas-wrapper">
            <Canvas
              renderCount={renderCount}
              triggerRender={triggerRender}
              isInPanMode={isInPanMode}
              onObjectClick={() => {
                const activeItem = canvasRef.current.getActiveObject();
                setActiveItem(activeItem);
              }}
              refer={canvasRef}
              currentScale={viewPortMeasures.scale}
              currentSheetName={currentSheetName}
              setCurrentSheetName={setCurrentSheetName}
              onLoadSuccess={(viewPortMeasures, canvas) => {
                // Setting the initial measure
                onCanvasInit(viewPortMeasures);
                // Set varying measures.
                setViewPortMeasures(viewPortMeasures);
                // const currentObjects = canvasRef.current.getObjects();
              }}
              onPositionChange={(transformX, transformY, zoom) => {
                setViewPortMeasures((prev) => ({
                  ...prev,
                  x: prev.x + transformX,
                  y: prev.y + transformY,
                }));
              }}
              onZoom={(zoom, offsetX, offsetY, scaleFactor) => {
                setViewPortMeasures((prev) => {
                  return {
                    ...prev,
                    prevZoom: zoom,
                    availableWidth: prev.availableWidth * scaleFactor,
                    availableHeight: prev.availableHeight * scaleFactor,
                    // prev.x * scaleFactor + (cursorX - prev.x) * (scaleFactor - 1)
                    x: (prev.x - offsetX) * scaleFactor + offsetX,
                    y: (prev.y - offsetY) * scaleFactor + offsetY,
                  };
                });
              }}
              selectedDimensionsInInches={selectedOptions}
            />
          </div>
        </div>
        {/* View Port */}
      </div>
      {isExportVisible && (
        <ExportOptions
          processingDetails={processingDetails}
          sheetMap={sheetMap}
          onDownload={handleFileDownload}
          onClose={() => setIsExportVisible(false)}
          selectedMultiplier={selectedMultiplier}
          setSelectedMultiplier={setSelectedMultiplier}
        />
      )}
      {isConfirmModalVisible && (
        <DtfConfirmationModal
          canvas={canvasRef.current}
          currentSheetName={currentSheetName}
          initCanvasMeasures={initCanvasMeasures}
          sheetMap={sheetMap}
          onClose={() => setIsConfirmModalVisible(false)}
        />
      )}
      {sheetToRemove && (
        <AlertActionPopUp
          message={'Are you sure you want to delete this sheet?'}
          title={'Remove Sheet'}
          positiveButtonText="Confirm"
          negativeButtonText="Cancel"
          onClose={() => setSheetToRemove(null)}
          onSubmit={() => {
            removeSheet(sheetToRemove);
          }}
        />
      )}

      <AlertMessagePopUp
        isVisible={errorObject.isVisible}
        message={errorObject.message}
        title={errorObject.title}
        onClose={() => {
          setErrorObject({
            message: null,
            isVisible: false,
            title: null,
          });
        }}
      />
      {pageLoadDetails.isLoading && (
        <PageLoader pageDetails={pageLoadDetails} />
      )}
      {false && (
        <UploadPopUp
          initCanvasSize={initCanvasMeasures}
          selectedUnit={selectedUnit}
          canvasRef={canvasRef}
          onClose={() => setIsInUpload(false)}
          selectedUploadImages={addedImageElements}
          setSelectUploadImages={setSelectUploadImages}
          onSubmit={async () => {
            const selectionList =
              canvasUtils.generateSelectionItemsForAutoBuild({
                canvasItemList: [],
                addedImageList: addedImageElements,
                viewPortMeasures: initCanvasMeasures,
              });

            const result = await canvasUtils.autoBuildItemsUsingMaxRect({
              canvasRef: canvasRef,
              allItems: selectionList,
              initCanvasMeasures: initCanvasMeasures,
              autoBuildOptions: autoBuildOptions,
              marginsInUnit: {
                artBoardMargin: selectedMargin * selectedUnit.value,
                imageMargin: selectedMargin * selectedUnit.value,
              },
              selectedUnit: selectedUnit,
            });

            if (!result?.maxRectSheets) {
              setIsInUpload(false);
              return;
            }
            addAutoBuildItemsToCanvas(result.maxRectSheets);
            setIsInUpload(false);
          }}
        />
      )}
    </div>
  );
}

function PageLoader({ pageDetails }) {
  const itemCount = pageDetails?.addedItems ?? 0;
  const totalCount = pageDetails?.totalItems ?? 0;

  // const count = Object.values(sheetMap).reduce((acc, currentItem) => {
  //   return acc + currentItem.noOfItems;
  // }, canvasLength);
  // const currentSheetData = sheetMap
  return (
    <div className="loading-overlay">
      Building...{Boolean(totalCount) && `(${itemCount}/${totalCount})`}
    </div>
  );
}

function UploadPopUp({
  selectedUploadImages,
  setSelectUploadImages,
  selectedUnit,
  initCanvasSize,
  canvasRef,
  onClose,
  onSubmit = () => {},
}) {
  const isLoading = selectedUploadImages
    .filter((item) => !item?.isEdited || !item?.isFailed)
    .some((item) => item.isUploading);

  // const selectionList = canvasUtils.generateSelectionItemsForAutoBuild({
  //   canvasItemList: canvasRef.current.getObjects() ?? [],
  //   addedImageList: selectedUploadImages,
  //   viewPortMeasures: initCanvasSize,
  // });
  return (
    <div
      style={{
        position: 'fixed',
        inset: '0px',
        height: '100vh',
        backgroundColor: 'rgba(0,0,0,0.5)',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: '93px',
        zIndex: 999999,
        justifyContent: 'center',
        width: '100vw',
      }}
    >
      <div
        className=" w-fit h-fit "
        style={{
          background: 'white',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          padding: '10px',
        }}
      >
        <div className={`d-flex ${isLoading ? 'm-auto' : ''}`}>
          <EditableImageList
            isLoading={isLoading}
            allItems={selectedUploadImages}
            selectedUnit={selectedUnit}
            viewportMeasures={initCanvasSize}
            isPlaceable={false}
            onHeightUpdate={(event, it, index) => {
              const { value } = event.target;

              // if (!item.isRatioLocked) activeItem.scaleX = requiredScale;

              setSelectUploadImages((prev) =>
                prev.map((item, idx) => {
                  if (idx === it.index) {
                    const requiredScale =
                      (value *
                        (canvasConstants.CANVAS_DPI * initCanvasSize.scale)) /
                      (item.height * (selectedUnit.value ?? 1));

                    const aspectRatio = item.scaleX / item.scaleY;
                    const newScaleX = item.ratioUnlocked
                      ? item.scaleX
                      : aspectRatio * requiredScale;

                    return {
                      ...item,
                      scaleY: requiredScale,
                      scaleX: newScaleX,
                    };
                  }
                  return { ...item };
                })
              );
            }}
            onWidthUpdate={(event, itm, index) => {
              const { value } = event.target;
              setSelectUploadImages((prev) =>
                prev.map((item, idx) => {
                  if (idx === itm.index) {
                    const requiredScale =
                      (value *
                        (canvasConstants.CANVAS_DPI * initCanvasSize.scale)) /
                      (item.width * (selectedUnit.value ?? 1));

                    const aspectRatio = item.scaleX / item.scaleY;
                    const newScaleY = item.ratioUnlocked
                      ? item.scaleY
                      : aspectRatio * requiredScale;

                    return {
                      ...item,
                      scaleX: requiredScale,
                      scaleY: newScaleY,
                    };
                  }

                  return { ...item };
                })
              );
            }}
            onAspectRatioToggle={(idx) => {
              setSelectUploadImages((prev) =>
                prev.map((itm, index) => {
                  if (index === idx) {
                    return {
                      ...itm,
                      ratioUnlocked: !itm.ratioUnlocked,
                    };
                  }
                  return itm;
                })
              );
            }}
            updateSelectedItem={setSelectUploadImages}
          />
        </div>
        <button
          className="default-btn mt-3"
          onClick={async () => {
            setSelectUploadImages((prev) =>
              prev.map((item) => ({ ...item, isEdited: true }))
            );
            onSubmit();
          }}
        >
          Confirm
        </button>
      </div>
    </div>
  );
}

const CustomViewPortInput = ({
  onClose = () => {},
  selectedUnit,
  onSubmit = () => {},
}) => {
  const [selectedSize, setSelectedSize] = useState({
    width: '',
    height: '',
  });

  const handleSubmit = () => {
    if (
      !selectedSize.width ||
      !selectedSize.height ||
      selectedSize.width <= 1 ||
      selectedSize.height <= 1
    ) {
      return;
    }
    onSubmit(selectedSize);
    onClose();
  };

  return (
    <div
      style={{
        position: 'fixed',
        inset: 0,
        backgroundColor: 'rgba(0,0,0,0.5)',
        zIndex: 99999,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <div style={{ backgroundColor: 'white', padding: '10px' }}>
        <div style={{ fontWeight: 600 }}>Custom Input</div>
        <div className="mt-3">
          <input
            value={
              selectedSize.width
                ? Math.round(
                    selectedSize.width * selectedUnit.value * 10000000000
                  ) / 10000000000
                : ''
            }
            autoFocus
            type="number"
            min="0"
            placeholder={`Width in ${selectedUnit.unit}`}
            onChange={(e) => {
              setSelectedSize((prev) => ({
                ...prev,
                width: e.target.value / selectedUnit.value,
              }));
            }}
          />
          <input
            value={
              selectedSize.height
                ? Math.round(
                    selectedSize.height * selectedUnit.value * 10000000000
                  ) / 10000000000
                : ''
            }
            type="number"
            min="0"
            placeholder={`Height in ${selectedUnit.unit}`}
            onChange={(e) => {
              setSelectedSize((prev) => ({
                ...prev,
                height: e.target.value / selectedUnit.value,
              }));
            }}
          />
        </div>
        <div className="d-flex gap-2 justify-content-end mt-2">
          <button
            className="default-btn primary-bg-color px-4"
            style={{ fontSize: 14 }}
            onClick={handleSubmit}
          >
            Set
          </button>
          <button
            onClick={onClose}
            className="default-btn primary-bg-color px-4"
            style={{ fontSize: 14 }}
          >
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

const CanvasOptions = ({
  onReset,
  canvas,
  canvasMeasures,
  setViewPortMeasures,
}) => {
  const zoomCanvas = (delta) => {
    // let delta = -0.5;
    const centerX = canvas.getWidth() / 2;
    const centerY = canvas.getHeight() / 2;
    let zoom = canvas.getZoom();
    const oldZoom = zoom;
    if (Math.abs(delta) > 100) {
      // Large delta value: Mouse wheel - reduce the zoom increment
      delta = delta * 0.1; // Reduce mouse zoom sensitivity
    }
    zoom *= 0.959 ** delta;
    if (zoom > 20) zoom = 20;
    if (zoom < 0.01) zoom = 0.01;
    // canvas.setZoom(zoom);
    canvas.zoomToPoint({ x: centerX, y: centerY }, zoom);
    const scaleFactor = zoom / oldZoom;
    setViewPortMeasures((prev) => {
      return {
        ...prev,
        prevZoom: zoom,
        availableWidth: prev.availableWidth * scaleFactor,
        availableHeight: prev.availableHeight * scaleFactor,
        // prev.x * scaleFactor + (cursorX - prev.x) * (scaleFactor - 1)
        x: (prev.x - centerX) * scaleFactor + centerX,
        y: (prev.y - centerY) * scaleFactor + centerY,
      };
    });
    // onZoom(zoom, centerX, centerY, scaleFactor);
    // triggerRender();
  };
  const zoomHundred = () => {
    // let delta = -0.5;
    const centerX = canvas.getWidth() / 2;
    const centerY = canvas.getHeight() / 2;
    let zoom = canvas.getZoom();
    const oldZoom = zoom;

    zoom = 1;

    // canvas.setZoom(zoom);
    canvas.zoomToPoint({ x: centerX, y: centerY }, zoom);
    const scaleFactor = zoom / oldZoom;
    setViewPortMeasures((prev) => {
      return {
        ...prev,
        prevZoom: zoom,
        availableWidth: prev.availableWidth * scaleFactor,
        availableHeight: prev.availableHeight * scaleFactor,
        // prev.x * scaleFactor + (cursorX - prev.x) * (scaleFactor - 1)
        x: (prev.x - centerX) * scaleFactor + centerX,
        y: (prev.y - centerY) * scaleFactor + centerY,
      };
    });
    // onZoom(zoom, centerX, centerY, scaleFactor);
    // triggerRender();
  };

  return (
    <div
      style={{
        position: 'absolute',
        right: 25,
        top: 25,
        height: 'fit-content',
        zIndex: 999,
        display: 'flex',
      }}
    >
      <div className="d-flex align-items-center gap-2 px-2">
        {/* <button className="default-btn d-flex" onClick={() => zoomHundred(0.5)}>
          100%
        </button> */}
        <button className="default-btn d-flex" onClick={() => zoomCanvas(0.5)}>
          <ZoomOut size={13} />
        </button>
        {(canvas?.getZoom() * canvasMeasures.scale * 100).toFixed(1)}
        <button className="default-btn d-flex" onClick={() => zoomCanvas(-0.5)}>
          <ZoomIn size={13} />
        </button>
      </div>
      <button className="default-btn d-flex" onClick={onReset}>
        <Fullscreen size={15} />
      </button>
    </div>
  );
};

export default GangSheetViewPort;
