// src/ColorConverter.js

import React, { useState } from 'react';
import { HexColorPicker } from 'react-colorful';

import NearestColorFinder from './Component/NearestColorFinder';

const ColorConverter = () => {
  const [hex, setHex] = useState('#aabbcc');
  const [rgb, setRgb] = useState({ r: 170, g: 187, b: 204 });
  const [nearestColors, setNearestColors] = useState([]);

  const handleHexChange = (color) => {
    setHex(color);
    const rgb = hexToRgb(color);
    setRgb(rgb);
    findNearestColors(rgb);
  };

  const handleHexInputChange = (event) => {
    const color = event.target.value;
    if (color.length === 7 && /^#[0-9A-Fa-f]{6}$/i.test(color)) {
      handleHexChange(color);
    }
    setHex(color); // Update hex state to reflect the input field value even if not complete
  };

  const handleRgbChange = (r, g, b) => {
    setRgb({ r, g, b });
    const hex = rgbToHex(r, g, b);
    setHex(hex);
    findNearestColors({ r, g, b });
  };

  const hexToRgb = (hex) => {
    const bigint = parseInt(hex.slice(1), 16);
    return {
      r: (bigint >> 16) & 255,
      g: (bigint >> 8) & 255,
      b: bigint & 255,
    };
  };

  const rgbToHex = (r, g, b) => {
    return (
      '#' +
      ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1).toUpperCase()
    );
  };

  const findNearestColors = (rgb) => {
    // Dummy function for nearest colors
    // Replace with actual logic or API call
    const dummyColors = [
      { colorHex: '#AABBCC', colorRgb: { r: 170, g: 187, b: 204 } },
      { colorHex: '#BBCCDD', colorRgb: { r: 187, g: 204, b: 221 } },
    ];
    setNearestColors(dummyColors);
  };

  return (
    <div>
      <div className="card mb-4">
        <div className="card-header">
          <img
            src="
          /img/converter.png"
            alt="Color Wheel"
            className="mr-2 card-header-icon"
          />
          Color Converter
        </div>
        <div className="card-body">
          <HexColorPicker color={hex} onChange={handleHexChange} />
          <input
            type="text"
            className="form-control mt-3"
            value={hex}
            onChange={handleHexInputChange}
            maxLength="7"
          />
          <p className="card-text mt-3">Hex: {hex}</p>
          <p className="card-text">
            RGB: ({rgb.r}, {rgb.g}, {rgb.b})
          </p>
        </div>
      </div>
      <div className="card mb-4">
        <div className="card-header">RGB to Hex</div>
        <div className="card-body">
          <div className="form-group d-flex align-items-center">
            <label className="mr-2">R:</label>
            <input
              type="number"
              className="form-control mb-2 mr-2"
              style={{ width: '70px' }}
              value={rgb.r}
              onChange={(e) => handleRgbChange(+e.target.value, rgb.g, rgb.b)}
            />
            <label className="mr-2">G:</label>
            <input
              type="number"
              className="form-control mb-2 mr-2"
              style={{ width: '70px' }}
              value={rgb.g}
              onChange={(e) => handleRgbChange(rgb.r, +e.target.value, rgb.b)}
            />
            <label className="mr-2">B:</label>
            <input
              type="number"
              className="form-control mb-2"
              style={{ width: '70px' }}
              value={rgb.b}
              onChange={(e) => handleRgbChange(rgb.r, rgb.g, +e.target.value)}
            />
          </div>
          <p className="card-text">Hex: {hex}</p>
        </div>
      </div>
      <NearestColorFinder />
    </div>
  );
};

export default ColorConverter;
