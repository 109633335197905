import { useState } from 'react';
import { CloseIcon } from './Icons';

const ExportOptions = ({
  onDownload = () => {},
  onClose = () => {},
  setSelectedMultiplier,
  processingDetails = {
    status: false,
    processedSheets: null,
    isMultiDownload: false,
    totalSheets: null,
  },
  selectedMultiplier,
  sheetMap = null,
}) => {
  const [selectedFileType, setSelectedFileType] = useState('SVG');
  const [otherOptions, setOtherOptions] = useState({
    externalDownload: true,
    highProcess: false,
    processEmbedded: true,
    downloadAllSheets: false,
    epsMethodTwo: false,
  });

  const hasMultipleSheets = Object.keys(sheetMap ?? {}).length > 1;

  return (
    <div
      style={{
        position: 'fixed',
        zIndex: 99999,
        userSelect: 'none',
        inset: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0,0,0,0.5)',
      }}
    >
      <div
        style={{
          backgroundColor: 'white',
          padding: 15,
          display: 'flex',
          minWidth: 300,
          gap: 10,
        }}
      >
        <div className="w-100">
          <div style={{ fontWeight: 600 }}>Download options:</div>

          <div className="d-flex gap-2 mt-4">
            {/* <div
              onClick={() => setSelectedFileType('PNG')}
              style={{
                backgroundColor:
                  selectedFileType === 'PNG' ? 'rgb(0,0,0)' : '#f2f2f2',
                padding: '5px 10px',
                color: selectedFileType === 'PNG' ? '#FFF' : '#000',
                borderRadius: 5,
              }}
              role="button"
            >
              PNG
            </div> */}
            {/* <div
              onClick={() => setSelectedFileType('JPEG')}
              style={{
                backgroundColor:
                  selectedFileType === 'JPEG' ? 'rgb(0,0,0)' : '#f2f2f2',
                padding: '5px 10px',
                color: selectedFileType === 'JPEG' ? '#FFF' : '#000',
                borderRadius: 5,
              }}
              role="button"
            >
              JPEG
            </div> */}
            <div
              onClick={() => setSelectedFileType('PDF')}
              style={{
                backgroundColor:
                  selectedFileType === 'PDF' ? 'rgb(0,0,0)' : '#f2f2f2',
                padding: '5px 10px',
                color: selectedFileType === 'PDF' ? '#FFF' : '#000',
                borderRadius: 5,
              }}
              role="button"
            >
              PDF
            </div>

            <div
              onClick={() => setSelectedFileType('SVG')}
              style={{
                backgroundColor:
                  selectedFileType === 'SVG' ? 'rgb(0,0,0)' : '#f2f2f2',
                padding: '5px 10px',
                color: selectedFileType === 'SVG' ? '#FFF' : '#000',
                borderRadius: 5,
              }}
              role="button"
            >
              SVG
            </div>
            <div
              onClick={() => setSelectedFileType('EPS')}
              style={{
                backgroundColor:
                  selectedFileType === 'EPS' ? 'rgb(0,0,0)' : '#f2f2f2',
                padding: '5px 10px',
                color: selectedFileType === 'EPS' ? '#FFF' : '#000',
                borderRadius: 5,
              }}
              role="button"
            >
              EPS
            </div>
          </div>

          {(selectedFileType === 'SVG' || selectedFileType === 'PDF') && (
            <div className="d-flex gap-2 mt-3 align-items-center fz-13px">
              <input
                type="checkbox"
                id="external-process"
                checked={otherOptions.externalDownload}
                onChange={(event) =>
                  setOtherOptions({
                    ...otherOptions,
                    externalDownload: event.target.checked,
                  })
                }
              />
              <label htmlFor="external-process" style={{ userSelect: 'none' }}>
                Enable Server Processing
              </label>
            </div>
          )}
          {selectedFileType === 'SVG' && (
            <div className="d-flex gap-2 mt-3 align-items-center fz-13px">
              <input
                type="checkbox"
                id="process-embedded"
                checked={otherOptions.processEmbedded}
                onChange={(event) =>
                  setOtherOptions({
                    ...otherOptions,
                    processEmbedded: event.target.checked,
                  })
                }
              />
              <label htmlFor="process-embedded" style={{ userSelect: 'none' }}>
                Process Embedded Files
              </label>
            </div>
          )}

          {selectedFileType === 'EPS' && (
            <div className="d-flex gap-2 mt-3 align-items-center fz-13px">
              <input
                type="checkbox"
                id="high-process-check"
                checked={otherOptions.epsMethodTwo}
                onChange={(event) =>
                  setOtherOptions({
                    ...otherOptions,
                    epsMethodTwo: event.target.checked,
                  })
                }
              />
              <label
                htmlFor="high-process-check"
                style={{ userSelect: 'none' }}
              >
                Alternative Conversion Method
              </label>
            </div>
          )}
          {/* Multi sheet download */}
          {selectedFileType === 'EPS' && (
            <div className="d-flex gap-2 mt-3 align-items-center fz-13px">
              <div className="d-flex gap-1 align-items-center">
                <input
                  type="radio"
                  id="download-current-sheet"
                  checked={!otherOptions.downloadAllSheets}
                  onChange={() => {
                    setOtherOptions({
                      ...otherOptions,
                      downloadAllSheets: false,
                    });
                  }}
                />
                <label htmlFor="download-current-sheet">Current sheet</label>
              </div>
              {hasMultipleSheets && (
                <div className="d-flex gap-1 align-items-center">
                  <input
                    id="download-all-sheets"
                    checked={otherOptions.downloadAllSheets}
                    type="radio"
                    onChange={() => {
                      setOtherOptions({
                        ...otherOptions,
                        downloadAllSheets: true,
                      });
                    }}
                  />
                  <label htmlFor="download-all-sheets"> All sheets</label>
                </div>
              )}
              {/* <input
                type="checkbox"
                id="download-all-sheet"
                checked={otherOptions.downloadAllSheets}
                onChange={(event) =>
                  setOtherOptions({
                    ...otherOptions,
                    downloadAllSheets: event.target.checked,
                  })
                }
              />
              <label
                htmlFor="download-all-sheet"
                style={{ userSelect: 'none' }}
              >
                Download all sheets in a single ZIP
              </label> */}
            </div>
          )}

          {/* {hasMultipleSheets && (
            <>
              <div className="d-flex gap-2 mt-2 align-items-center fz-13px">
                <input
                  id="all-sheet-radio"
                  type="radio"
                  name="sheets"
                  checked={otherOptions.downloadAllSheets}
                  onChange={() =>
                    setOtherOptions({
                      ...otherOptions,
                      downloadAllSheets: true,
                    })
                  }
                />
                <label htmlFor="all-sheet-radio"> All Sheets</label>
                <input
                  id="current-sheet-radio"
                  type="radio"
                  name="sheets"
                  checked={!otherOptions.downloadAllSheets}
                  onChange={() =>
                    setOtherOptions({
                      ...otherOptions,
                      downloadAllSheets: false,
                    })
                  }
                />
                <label htmlFor="current-sheet-radio">Current</label>
              </div>
            </>
          )} */}

          {(selectedFileType === 'PNG' ||
            (selectedFileType === 'PDF' && !otherOptions.externalDownload) ||
            selectedFileType === 'JPEG') && (
            <>
              <div className="d-flex gap-1 my-3 align-items-center gap-2">
                <div className="fz-13px">Multiplier:</div>
                <input
                  value={selectedMultiplier}
                  onChange={(event) =>
                    setSelectedMultiplier(event.target.value)
                  }
                  type="number"
                  style={{ width: '8ch' }}
                />
              </div>
              <div style={{ fontSize: 10, marginTop: 10 }}>
                * Updating the multiplier will resize the image
              </div>
            </>
          )}
          {processingDetails.status && (
            <div style={{ fontSize: 13, marginTop: 5 }}>
              Processing...This might take some time
              {processingDetails.isMultiDownload && (
                <>{` (${processingDetails?.processedSheets ?? 0}/${processingDetails?.totalSheets ?? 0})`}</>
              )}
            </div>
          )}

          <div className="d-flex align-item-center justify-content-end">
            <div
              role="button"
              className=" shdw-button primary-bg-color mt-3 "
              onClick={() => {
                if (!processingDetails.status && selectedFileType) {
                  // if (otherOptions.downloadAllSheets) {
                  //   Object.keys(sheetMap).map((sheet)=>{
                  //     onDownload(selectedFileType, otherOptions);
                  //   })
                  // } else {
                  onDownload(selectedFileType, otherOptions);
                  // }
                }
              }}
            >
              Download
            </div>
          </div>
        </div>
        <div role={'button'} onClick={onClose}>
          <CloseIcon size={15} color="black" />
        </div>
        {/* (<div> Print</div>) */}
      </div>
    </div>
  );
};

export default ExportOptions;
