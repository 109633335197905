import * as fabric from 'fabric';
import React, { useEffect, useRef } from 'react';

const DEV_MODE = process.env.NODE_ENV === 'development';

export const ViewableCanvas = ({
  fabricData,
  refer,
  prevMeasures,
  loadFromJson = false,
  onLoad = () => {},
}) => {
  const canvasRef = useRef(null);

  // const { height: selectedHeightInInches, width: selectedWidthInInches } =
  //   selectedDimensionsInInches.size || { height: 400, width: 100 }; // Add default values

  useEffect(() => {
    if (!canvasRef.current) {
      return;
    }

    const canvas = new fabric.Canvas(canvasRef.current, {
      width: prevMeasures.width,
      height: prevMeasures.height,
      selection: false,
      notAllowedCursor: true,
      backgroundColor: '#f7f7f7',
    });

    // Expose the canvas instance to the parent component if necessary
    DEV_MODE && (window.canvas = canvas);

    if (typeof refer === 'function') {
      refer(canvas);
    } else if (typeof refer === 'object' && refer) {
      refer.current = canvas;
    }

    onLoad?.(canvas, canvasRef);
    if (loadFromJson) {
      const fabricDataObject = JSON.parse(fabricData);
      // Load the canvas from the JSON data
      canvas.loadFromJSON(fabricDataObject, () => {
        canvas.renderAll();
      });
    }

    // Clean up on unmount
    return () => {
      DEV_MODE && delete window.canvas;

      if (typeof refer === 'function') {
        refer(null);
      } else if (typeof refer === 'object' && refer) {
        refer.current = null;
      }

      canvas.dispose();
    };
  }, [fabricData, refer]); // Add necessary dependencies

  return (
    <>
      <canvas ref={canvasRef} className="mt-0" />
    </>
  );
};
