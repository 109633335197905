const AutoBuildButton = ({ onAutoBuildClick, onResetClick }) => {
  return (
    <div className="auto-build-button-container">
      {/* (<button className="default-btn" onClick={onResetClick}>
        Reset
      </button>) */}
      <button className="default-btn" onClick={onAutoBuildClick}>
        Autobuild
      </button>
    </div>
  );
};

export default AutoBuildButton;
