// import React, { useEffect, useRef } from 'react';

function ViewPort({
  selectedImageUrl,
  imageAttributes = null,
  isProcessing = false,
  borderWidth = 10,
}) {
  // const canvasRef = useRef(null);

  // useEffect(() => {
  //   const canvas = canvasRef.current;
  //   const ctx = canvas.getContext('2d');
  //   const image = new Image();

  //   // Handle cross-origin images
  //   image.crossOrigin = 'Anonymous';

  //   image.onload = () => {
  //     const { width, height } = image;
  //     canvas.width = width + 2 * borderWidth;
  //     canvas.height = height + 2 * borderWidth;

  //     // Draw the image onto the canvas
  //     ctx.drawImage(image, borderWidth, borderWidth, width, height);

  //     // Get image data
  //     const imageData = ctx.getImageData(
  //       borderWidth,
  //       borderWidth,
  //       width,
  //       height
  //     );
  //     const data = imageData.data;

  //     // Create a new canvas for the border
  //     const borderCanvas = document.createElement('canvas');
  //     borderCanvas.width = width + 2 * borderWidth;
  //     borderCanvas.height = height + 2 * borderWidth;
  //     const borderCtx = borderCanvas.getContext('2d');

  //     // Copy image data to border canvas
  //     borderCtx.drawImage(image, borderWidth, borderWidth, width, height);

  //     // Get border canvas data
  //     const borderData = borderCtx.getImageData(
  //       0,
  //       0,
  //       borderCanvas.width,
  //       borderCanvas.height
  //     );
  //     const borderPixelData = borderData.data;

  //     // Create border effect
  //     for (let y = 0; y < height; y++) {
  //       for (let x = 0; x < width; x++) {
  //         const index =
  //           ((y + borderWidth) * borderData.width + (x + borderWidth)) * 4;
  //         if (data[(y * width + x) * 4 + 3] > 0) {
  //           for (let dy = -borderWidth; dy <= borderWidth; dy++) {
  //             for (let dx = -borderWidth; dx <= borderWidth; dx++) {
  //               const borderIndex =
  //                 ((y + borderWidth + dy) * borderData.width +
  //                   (x + borderWidth + dx)) *
  //                 4;
  //               const distance = Math.sqrt(dx * dx + dy * dy);
  //               if (distance <= borderWidth) {
  //                 borderPixelData[borderIndex] = 255;
  //                 borderPixelData[borderIndex + 1] = 255;
  //                 borderPixelData[borderIndex + 2] = 255;
  //                 borderPixelData[borderIndex + 3] = 255;
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }

  //     // Put modified border data back on the border canvas
  //     borderCtx.putImageData(borderData, 0, 0);

  //     // Draw the border canvas on the main canvas
  //     ctx.drawImage(borderCanvas, 0, 0);

  //     // Draw the image again on top
  //     ctx.drawImage(image, borderWidth, borderWidth, width, height);
  //   };

  //   image.src = selectedImageUrl;
  // }, [selectedImageUrl, borderWidth]);

  return (
    <>
      <div className="view-port-container d-flex align-items-center justify-content-center position-relative">
        {/* <div className="selected-image-container"> */}
        {isProcessing && <div className="view-port-loader">Loading...</div>}
        {/* <canvas ref={canvasRef} /> */}
        {selectedImageUrl && (
          <img
            src={selectedImageUrl}
            alt="selected-image-for-editing "
            className="selected-image"
          />
        )}

        {/* </div> */}
      </div>
      {imageAttributes && (
        <div>
          Resolution:{imageAttributes.width} X {imageAttributes.height}
        </div>
      )}
    </>
  );
}

export default ViewPort;
