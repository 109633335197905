import { useEffect, useRef } from 'react';
import { canvasUtils } from '../Test/canvasUtils';

export const useCanvasInteractions = (
  renderCount,
  canvasRef,
  canvas,
  onZoom,
  onPositionChange,
  triggerRender,
  isHandMode = false
) => {
  const isDraggingRef = useRef(false);
  const lastPosXRef = useRef(0);
  const lastPosYRef = useRef(0);

  useEffect(() => {
    // ZOOM
    // const handleZoom = (options) => {
    //   const currentZoom = canvas.getZoom();
    //   // Add a min zoom for the canvas
    //   const isMinZoomReached = currentZoom <= 0.1 && options.e.deltaY > 0;
    //   if (isHandMode || isMinZoomReached) {
    //     return;
    //   }

    //   let delta = options.e.deltaY;
    //   let zoom = canvas.getZoom();
    //   const oldZoom = zoom;
    //   if (Math.abs(delta) > 100) {
    //     // Large delta value: Mouse wheel - reduce the zoom increment
    //     delta = delta * 0.1; // Reduce mouse zoom sensitivity
    //   }
    //   zoom *= 0.959 ** delta;
    //   if (zoom > 20) zoom = 20;
    //   if (zoom < 0.01) zoom = 0.01;
    //   // canvas.setZoom(zoom);
    //   canvas.zoomToPoint({ x: options.e.offsetX, y: options.e.offsetY }, zoom);
    //   const scaleFactor = zoom / oldZoom;
    //   onZoom(zoom, options.e.offsetX, options.e.offsetY, scaleFactor);
    //   triggerRender();
    //   options.e.preventDefault();
    //   options.e.stopPropagation();
    // };
    const handleZoom = (options) => {
      const currentZoom = canvas.getZoom();

      // Add a min zoom for the canvas
      const isMinZoomReached = currentZoom <= 0.1 && options.e.deltaY > 0;
      if (isHandMode || isMinZoomReached) {
        return;
      }

      let delta = options.e.deltaY;
      let zoom = canvas.getZoom();
      const oldZoom = zoom;

      // Apply different zoom sensitivity based on input type (mouse or trackpad)
      if (Math.abs(delta) > 50) {
        // Mouse scroll usually has large delta values
        // Apply reduced sensitivity for mouse scroll
        delta = delta * 0.05; // Adjust this value for mouse scroll sensitivity
      } else {
        // Trackpad zoom is more sensitive and should remain faster
        delta = delta * 1; // Keep trackpad zoom behavior unchanged
      }

      // Apply zoom factor
      zoom *= 1.05 ** -delta;

      // Ensure zoom stays within a reasonable range
      if (zoom > 20) zoom = 20;
      if (zoom < 0.01) zoom = 0.01;

      // Zoom to the point where the mouse pointer is
      canvas.zoomToPoint({ x: options.e.offsetX, y: options.e.offsetY }, zoom);

      const scaleFactor = zoom / oldZoom;
      onZoom(zoom, options.e.offsetX, options.e.offsetY, scaleFactor);

      triggerRender();

      options.e.preventDefault();
      options.e.stopPropagation();
    };

    // PAN
    const handleMouseDown = (opt) => {
      const evt = opt.e;
      if (evt.shiftKey || isHandMode) {
        isDraggingRef.current = true;
        canvas.selection = false;
        lastPosXRef.current = evt.clientX;
        lastPosYRef.current = evt.clientY;
      }
      triggerRender();
    };
    const handleMouseMove = (opt) => {
      if (isDraggingRef.current) {
        const e = opt.e;
        const vpt = canvas.viewportTransform;
        const dx = e.clientX - lastPosXRef.current;
        const dy = e.clientY - lastPosYRef.current;
        vpt[4] += dx;
        vpt[5] += dy;
        canvas.requestRenderAll();
        lastPosXRef.current = e.clientX;
        lastPosYRef.current = e.clientY;

        const zoom = canvas.getZoom();

        onPositionChange(dx, dy, zoom);
        triggerRender();
      }
    };
    const handleMouseUp = (opt) => {
      canvas.setViewportTransform(canvas.viewportTransform);
      isDraggingRef.current = false;
      canvas.selection = true;
      triggerRender();
    };

    const handleKeyUp = (event) => {
      // If input box is in focus, we can remove the canvas element, since delete/backspace for input already exist.
      const isInputInFocus =
        document.activeElement.tagName === 'INPUT' ||
        document.activeElement.tagName === 'TEXTAREA';

      if (isInputInFocus) {
        return;
      }

      if (event.key === 'Delete' || event.key === 'Backspace') {
        canvasUtils.removeActiveObjectFromCanvas({ canvas });
      }
    };

    if (canvas) {
      canvas.on('mouse:wheel', handleZoom);
      canvas.on('mouse:down', handleMouseDown);
      canvas.on('mouse:move', handleMouseMove);
      canvas.on('mouse:up', handleMouseUp);
    }

    document.addEventListener('keyup', handleKeyUp);

    return () => {
      if (canvas) {
        canvas.off('mouse:wheel', handleZoom);
        canvas.off('mouse:down', handleMouseDown);
        canvas.off('mouse:move', handleMouseMove);
        canvas.off('mouse:up', handleMouseUp);
      }
      document.removeEventListener('keyup', handleKeyUp);
    };
  }, [canvas, renderCount, isHandMode]);
};
