/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef } from 'react';
import useCanvasDrawer from '../Hooks/useCanvasDrawer';

import useCanvasImagePlacer from '../Hooks/useCanvasImagePlacer';
import usePinchHandler from '../Hooks/usePinchHandler';
import { LengthScale } from './LengthScale';
import SelectedImageDetails from './SelectedImageDetails';
import { calculateItemPositionAndSize, GANG_SHEET_SIZE } from './utils';

function GSViewPortContainer({
  selectedGsItems = [],
  updateGsItem = () => {},
  selectedOptions,
  onOptionChange = () => {},
  onCanvasInit = () => {},
  selectedState = [],
  triggerRedraw = () => {},
  redrawTriggerCount,
  initCanvasSize,
  redrawTriggerCountWithHandle,
}) {
  const [currentlySelectedItemIndex, setCurrentlySelectedItemIndex] =
    selectedState;

  const animationId = useRef(null);
  // const selectedSize = {
  //   width: selectedOptions.size.width,
  //   height: selectedOptions.size.height,
  // };
  //   const [handleMode, setHandleMode] = useState(0); //0 default;1: selection mode
  //   const { containerRef } = usePinchHandler();

  const {
    innerCanvasRef,
    // getInitVPSizeAndPosition,
    resetPosition,
    drawViewPort,
    // fitViewportOnCanvas,
    findScaleAndDimensionAfterScreenFit,
    // addImageToViewPort,
    canvasSize,
  } = useCanvasDrawer({
    selectedResolutionInInch: selectedOptions.size,
    onCanvasInit: onCanvasInit,
  });

  const { outerCanvasRef, redrawImage, drawHandlerForSelectedImage } =
    useCanvasImagePlacer({
      canvasConfig: canvasSize,
      selectedItems: selectedGsItems,
      setCurrentlySelectedItemIndex,
      currentlySelectedItemIndex,
      updateGsItem,
      triggerRedraw,
      customRef: innerCanvasRef,
    });

  const { containerRef } = usePinchHandler({
    canvasConfig: canvasSize,
    dependency: [canvasSize.x, canvasSize.y],
    drawViewPort,
    onChange: () => {
      const ctx = outerCanvasRef.current.getContext('2d');
      redrawImage({ ctx });
    },
  });
  // Redraw on selection change
  useEffect(() => {
    cancelAnimationFrame(animationId.current);
    const ctx = outerCanvasRef.current.getContext('2d');
    animationId.current = window.requestAnimationFrame(() => {
      redrawImage({ ctx });
    });
  }, [redrawTriggerCount, animationId.current]);

  useEffect(() => {
    if (currentlySelectedItemIndex !== null) {
      cancelAnimationFrame(animationId.current);
      const ctx = outerCanvasRef.current.getContext('2d');
      animationId.current = window.requestAnimationFrame(() => {
        redrawImage({ ctx });
        drawHandlerForSelectedImage(
          ctx,
          selectedGsItems[currentlySelectedItemIndex]
        );
      });
    }
  }, [redrawTriggerCountWithHandle]);

  useEffect(() => {
    const ctx = outerCanvasRef.current.getContext('2d');
    redrawImage({ ctx });
  }, [selectedGsItems.length]);

  const updateImagePositionAndSize = (selectedSizeInPixel, resizeScale) => {
    const updatedGsItems = selectedGsItems.reduce((acc, currentItem) => {
      const imageProps = {};
      imageProps.width = currentItem.originalWidth * resizeScale;
      imageProps.height = currentItem.originalHeight * resizeScale;

      imageProps.x = 0;
      imageProps.y = 0;

      const { x: newX = null, y: newY = null } = calculateItemPositionAndSize(
        imageProps,
        selectedSizeInPixel,
        acc
      );
      if (!newX && !newY && acc.length > 0) {
        return acc;
      }

      if (newX) {
        imageProps.x = newX;
      }

      if (newY) {
        imageProps.y = newY;
      }

      if (imageProps.width && imageProps.height) {
        imageProps.ratio = imageProps.width / imageProps.height;
      }

      return acc.concat({ ...currentItem, ...imageProps });
    }, []);
    return updatedGsItems;
    // updateGsItem(updatedGsItems);
    // triggerRedraw();
  };

  //   const { width, height } = getInitVPSizeAndPosition();

  return (
    <div className="gs-viewport-container">
      <div className="gs-vp-toolbar d-flex align-items-center px-3 gap-2">
        {/* ToolBar */}

        <select
          onChange={(event) => {
            const key = event.target.value;
            const selectedDimension = GANG_SHEET_SIZE[key];
            onOptionChange({
              size: {
                height: selectedDimension.height,
                width: selectedDimension.width,
              },
            });

            const { scale, viewportHeightPixels, viewportWidthPixels } =
              findScaleAndDimensionAfterScreenFit({
                fullHeight: canvasSize.fullHeight,
                fullWidth: canvasSize.fullWidth,
                viewportHeightInches: selectedDimension.height,
                viewportWidthInches: selectedDimension.width,
              });
            const updatedGsItems = updateImagePositionAndSize(
              {
                availableHeight: viewportHeightPixels,
                availableWidth: viewportWidthPixels,
              },
              scale
            );

            updateGsItem(updatedGsItems);
            // triggerRedraw();
          }}
        >
          <option value={GANG_SHEET_SIZE.WH2224.key}>
            22&ldquo;x24&ldquo;
          </option>
          <option value={GANG_SHEET_SIZE.WH2260.key}>
            22&ldquo;x60&ldquo;
          </option>
          <option value={GANG_SHEET_SIZE.WH22120.key}>
            22&ldquo;x120&ldquo;
          </option>
        </select>

        {/* <button
          className="default-btn"
          onClick={() => {
            // dispatch({
            //   type: TRANSFORM_ACTION.ZOOM_IN,
            // });

            drawViewPort({
              width: canvasSize.availableWidth * 1.2,
              height: canvasSize.availableHeight * 1.2,
            });
          }}
        >
          Zoom in
        </button>
        <button
          className="default-btn"
          onClick={() => {
            // dispatch({
            //   type: TRANSFORM_ACTION.ZOOM_OUT,
            // });

            drawViewPort({
              width: canvasSize.availableWidth * 0.8,
              height: canvasSize.availableHeight * 0.8,
            });
          }}
        >
          Zoom out
        </button> */}
        <button
          className="default-btn"
          onClick={() => {
            // dispatch({
            //   type: TRANSFORM_ACTION.RESET_ALL,
            // });
            resetPosition();
          }}
        >
          Reset position
        </button>
        {/* <div>
          Margin
          <input type="number" />
        </div> */}
        <button
          className="default-btn"
          onClick={() => {
            // const startX = canvasSize.x;
            // const startY = canvasSize.y;
            const width = initCanvasSize.availableWidth / initCanvasSize.scale;
            const height =
              initCanvasSize.availableHeight / initCanvasSize.scale;

            // Create a temp offscreen canvas
            const offscreenCanvas = document.createElement('canvas');
            offscreenCanvas.width = width;
            offscreenCanvas.height = height;
            const offscreenCtx = offscreenCanvas.getContext('2d');

            // const updatedItems = updateImagePositionAndSize(
            //   { availableWidth: width, availableHeight: height },
            //   canvasSize.scale
            // );

            redrawImage({
              ctx: offscreenCtx,
              scale: initCanvasSize.scale,
              includeBorder: false,
              // customItems: updatedItems,
            });

            // offscreenCtx.drawImage(
            //   outerCanvasRef.current,
            //   startX,
            //   startY,
            //   width,
            //   height,
            //   0,
            //   0,
            //   width,
            //   height
            // );

            offscreenCanvas.toBlob(function (blob) {
              const link = document.createElement('a');
              link.href = URL.createObjectURL(blob);

              link.download = 'canvas-image.png';

              link.click();

              // Cleanup
              URL.revokeObjectURL(link.href);
            }, 'image/png');
          }}
        >
          Download
        </button>
      </div>
      <div className="position-relative gs-vp-content">
        {/* Selected image details */}
        <SelectedImageDetails
          selectedItem={selectedGsItems[currentlySelectedItemIndex]}
        />

        {/* SCALE START */}
        {/* Absolute top length scale */}
        <LengthScale.LengthScaleContainer>
          <LengthScale.HorizontalScale
            width={canvasSize.availableWidth}
            offsetX={canvasSize.x}
            selectedXSize={selectedOptions.size.width}
          />
        </LengthScale.LengthScaleContainer>
        {/* Absolute left length scale */}
        <LengthScale.LengthScaleContainer containerClass="y-length-scale">
          <LengthScale.VerticalScale
            height={canvasSize.availableHeight}
            offsetY={canvasSize.y}
            selectedYSize={selectedOptions.size.height}
          />
        </LengthScale.LengthScaleContainer>

        <div className="scroll-bar-bottom z-30"></div>
        <div className="scroll-bar-right z-30"></div>
        <div className="scale-junction"></div>
        {/* SCALE END*/}

        {/* Toolbar */}
        {/* Visible View Port  */}
        <div className="gs-editor-container" ref={containerRef}>
          <div
            className="edit-area"
            style={{
              width: canvasSize.availableWidth,
              height: canvasSize.availableHeight,
              transform:
                canvasSize.x || canvasSize.y
                  ? `translate(${canvasSize.x}px, ${canvasSize.y}px)`
                  : 'translate(0px)',
            }}
          />
          <div className="gs-canvas-container">
            <canvas
              ref={innerCanvasRef}
              className="m-0 border-none editor-canvas"
            />
            <canvas
              ref={outerCanvasRef}
              style={{ position: 'absolute', inset: 0 }}
              className="m-0 editor-canvas border-none"
            />
            {/* <canvas /> */}
          </div>
        </div>
        {/* View Port */}
      </div>
    </div>
  );
}

export default GSViewPortContainer;
