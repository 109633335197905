import React from 'react';

function Toolbar({
  onBackgroundRemove = () => {},
  onImageUpscale = () => {},
  onImageEditClick = () => {},
  hasImage = true,
}) {
  return (
    <div>
      <div className="toolbar-item" role="button" onClick={onBackgroundRemove}>
        Remove background
      </div>
      <div className="toolbar-item" role="button">
        Replace Color
      </div>
      <div className="toolbar-item" role="button" onClick={onImageUpscale}>
        Upscale
      </div>
      <div role="button" className="toolbar-item">
        Color Picker
      </div>
      {hasImage && (
        <div className="toolbar-item" role="button" onClick={onImageEditClick}>
          Edit
        </div>
      )}
      {/* <div>
        <button onClick={onBackgroundRemove}> Color Picker</button>
      </div> */}
      {/* <button onClick={onBackgroundRemove}> Remove BG</button> */}
    </div>
  );
}

export default Toolbar;
