import React from 'react';

const CustomInput = ({
  value,
  onChange,
  label,
  placeholder = '',
  minWidth = 120,
  maxWidth = 'none',
  step = 1,
  postText = 'in',
}) => {
  const handleChange = (e) => {
    const newValue = e.target.value;
    if (/^\d*\.?\d*$/.test(newValue)) {
      onChange(newValue);
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        position: 'relative',
        minWidth: minWidth,
        // maxWidth: maxWidth,
      }}
    >
      {label && (
        <label
          style={{
            marginRight: 8,
            fontSize: 16,
            color: '#555',
          }}
        >
          {label}
        </label>
      )}
      <div
        style={{
          border: '1px solid #ccc',
          borderRadius: 4,
          padding: '0px 8px',
          backgroundColor: '#f7f7f7',
          fontSize: 16,
          fontWeight: 600,
          outline: 'none',
          width: 'calc(100% - 50px)', // Adjust width to leave space for the "inch" label
          boxSizing: 'border-box',
          maxWidth: maxWidth,
          display: 'flex',
          alignItems: 'center',
          gap: 5,
        }}
      >
        <input
          className="custom-input-number"
          type="number"
          value={value}
          step={step}
          onChange={handleChange}
          placeholder={placeholder}
          style={{
            border: 'none',
            flex: 1,
            backgroundColor: 'transparent',
            width: '10ch',
          }}
        />
        {postText && (
          <span
            style={{
              padding: '4px 8px',
              fontSize: 16,
              color: '#555',
              backgroundColor: '#f7f7f7',
            }}
          >
            {postText}
          </span>
        )}
      </div>
    </div>
  );
};

export default CustomInput;
