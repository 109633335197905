import React, { useState } from 'react';

const ColorBox = ({ color, width, onCopy = () => {} }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = () => {
    navigator.clipboard
      .writeText(`${color.name} ${color.colorHex}`)
      .then(() => {
        setCopied(true);
        onCopy(color.colorHex);
        setTimeout(() => {
          setCopied(false);
        }, 2000);
      });
  };

  return (
    <div
      className="tooltip-container m-1 position-relative"
      style={{
        backgroundColor: color.colorHex,
        width: width + 'px',
        height: width + 'px',
        border: '1px solid #747474',
        cursor: 'pointer',
      }}
      onClick={handleCopy}
    >
      <span className="tooltip-text">
        {color.name} {color.colorHex}
      </span>
      {copied && (
        <div
          className="position-absolute"
          style={{
            top: '0',
            left: '0',
            width: '100%',
            height: '100%',
            backgroundColor: 'rgba(0, 255, 0, 0.5)',
            display: 'flex',
            alignItems: 'center',
            fontSize: '.7rem',
            justifyContent: 'center',
            color: '#fff',
          }}
        >
          Copied!
        </div>
      )}
    </div>
  );
};

export default ColorBox;
