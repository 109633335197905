import React, { useState } from 'react';

const ImageUploadComponent = ({ onSelectImage, minHeight = 300 }) => {
  const [dragging, setDragging] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);

  const handleImageChange = (file) => {
    if (file) {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (typeof onSelectImage === 'function') {
          onSelectImage(event.target.result);
        }
        setImageSrc(event.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleFileInputChange = (e) => {
    if (e.target.files && e.target.files[0]) {
      handleImageChange(e.target.files[0]);
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    setDragging(true);
  };

  const handleDragLeave = () => {
    setDragging(false);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    setDragging(false);
    if (e.dataTransfer.files && e.dataTransfer.files[0]) {
      handleImageChange(e.dataTransfer.files[0]);
    }
  };

  return (
    <div
      className={`mb-3 ${dragging ? 'border-primary' : 'border-secondary'}`}
      style={{
        border: '2px dashed',
        width: '100%',
        minHeight: `${minHeight}px`,
        position: 'relative',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDrop}
    >
      {imageSrc ? (
        <img
          src={imageSrc}
          alt="Uploaded"
          style={{ maxWidth: '100%', maxHeight: '100%' }}
        />
      ) : (
        <div className="text-center">
          <p>Drag & Drop an image here or</p>
          <label className="btn btn-primary">
            Select Image
            <input
              id="imageInput"
              type="file"
              accept="image/*"
              onChange={handleFileInputChange}
              hidden
            />
          </label>
        </div>
      )}
    </div>
  );
};

export default ImageUploadComponent;
