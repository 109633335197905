import * as fabric from 'fabric';
import React, { useCallback, useRef } from 'react';
import { Canvas } from './Canvas';

function FabricTest() {
  //   const fabRef = useRef(null);
  const ref = useRef(null);

  const onLoad = useCallback(
    (canvas) => {
      canvas.setDimensions({
        width: window.innerWidth,
        height: 500,
      });
      const textValue = 'SMAK sandbox';
      const text = new fabric.Textbox(textValue, {
        originX: 'center',
        top: 20,
        textAlign: 'center',
        styles: fabric.util.stylesFromArray(
          [
            {
              style: {
                fontWeight: 'bold',
                fontSize: 64,
              },
              start: 0,
              end: 9,
            },
          ],
          textValue
        ),
      });
      canvas.add(text);
      canvas.centerObjectH(text);

      const animate = (toState) => {
        text.animate(
          { scaleX: Math.max(toState, 0.1) * 2 },
          {
            onChange: () => canvas.renderAll(),
            onComplete: () => animate(Number(!toState)),
            duration: 1000,
            easing: toState
              ? fabric.util.ease.easeInOutQuad
              : fabric.util.ease.easeInOutSine,
          }
        );
      };
      animate(1);
    },
    [ref]
  );

  return (
    <div>
      {/* <canvas ref={testCanvas} /> */}
      <Canvas refer={ref} onLoad={onLoad} />
      <button>Draw Rect</button>
    </div>
  );
}

export default FabricTest;
