import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { getTextColor } from './_miscFunction';

export const SelectBox = ({
  colorObj,
  handleColorChange,
  selects,
  otherColors = [],
}) => {
  const [selectedColor, setSelectedColor] = useState(colorObj.colorSelected);

  useEffect(() => {
    setSelectedColor(colorObj.colorSelected);
  }, [colorObj.colorSelected]);

  // Combine selects and otherColors, remove duplicates
  const combinedColors = [...selects, ...otherColors];
  const uniqueColors = combinedColors.filter(
    (color, index, self) =>
      index === self.findIndex((c) => c.colorHex === color.colorHex)
  );

  // Map colors to options for react-select
  const options = uniqueColors.map((color) => ({
    value: color.colorHex,
    label: color.name + ' (' + color.colorHex + ')',
    colorHex: color.colorHex,
  }));

  const handleSelectChange = (value) => {
    const selectColorObj = uniqueColors.find(
      (colorObj) => colorObj.colorHex === value
    );
    setSelectedColor(selectColorObj);
    handleColorChange(colorObj.colorSelected.colorHex, selectColorObj);
  };

  return (
    <div key={colorObj.colorSelected.colorHex} className="mb-3">
      <Select
        value={options.find(
          (option) => option.value === selectedColor.colorHex
        )}
        onChange={(option) => handleSelectChange(option.value)}
        options={options}
        styles={{
          option: (provided, { data }) => ({
            ...provided,
            backgroundColor: data.colorHex,
            color: getTextColor(data.colorHex),
          }),
          control: (provided) => ({
            ...provided,
            backgroundColor: selectedColor.colorHex,
            color: getTextColor(selectedColor.colorHex),
          }),
          singleValue: (provided, { data }) => ({
            ...provided,
            color: getTextColor(data.colorHex),
          }),
        }}
      />
    </div>
  );
};
