import React, { useState } from 'react';
import { ReactPhotoEditor } from 'react-photo-editor';
import { imageServices } from '../service/image.service';
import Toolbar from './Toolbar';
import ViewPort from './ViewPort';
import './surface.css';

function DesignSurface() {
  const [image, setImage] = useState(null);
  const [isImageProcessing, setIsImageProcessing] = useState(false);
  const [isEditorOpen, setIsEditorOpen] = useState(false);

  const localApiRoot = `http://localhost:8000`;
  const imageUrl = image
    ? image.isLocal
      ? URL.createObjectURL(image.file)
      : localApiRoot + image.file
    : null;

  const handleImageSelection = async (event) => {
    const file = event.target.files[0];
    const fileSizeInMB = file.size / (1024 * 1024);
    if (fileSizeInMB > 10) {
      alert('File size exceeds 10 MB.');
      return;
    }

    const attributes = await getImageAttributes(file);

    setImage({ file: file, isLocal: true, attributes, originalImage: file });
    setIsEditorOpen(true);
  };

  const getImageAttributes = (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onloadend = () => {
        const img = new Image();

        img.onload = () => {
          resolve({
            width: img.width,
            height: img.height,
            size: file.size,
          });
        };

        img.onerror = reject; // Handle image load errors
        img.src = reader.result;
      };

      reader.onerror = reject; // Handle file read errors
      reader.readAsDataURL(file);
    });
  };

  const downloadImage = (file) => {
    if (!file) return;

    // Create a temporary link element
    const link = document.createElement('a');
    link.href = URL.createObjectURL(file);
    link.download = 'downloaded-image.png'; // Specify the default file name

    // Append the link to the body (required for some browsers)
    document.body.appendChild(link);

    // Programmatically click the link to trigger the download
    link.click();

    // Remove the link from the document
    document.body.removeChild(link);
    URL.revokeObjectURL(link.href);
  };

  const handleBackgroundRemoval = async () => {
    if (!image || !image.file) {
      alert('Please select an image');
      return;
    }

    try {
      setIsImageProcessing(true);
      const result = await imageServices.removeImageBackground(image.file);
      const imageUrl = localApiRoot + result.processed_image;
      const blob = await imageServices.fileUrlToBlob(imageUrl);
      const processedFile = new File([blob], `processed_${image.file.name}`, {
        type: 'image/jpeg',
      });
      const attributes = await getImageAttributes(processedFile);

      setImage({ file: processedFile, isLocal: true, attributes });
    } catch (err) {
      console.log(err);
    } finally {
      setIsImageProcessing(false);
    }
  };

  const handleImageUpscale = async () => {
    if (!image || !image.file) {
      alert('Please select an image');
      return;
    }

    if (image.attributes.width > 3000 || image.attributes.height > 3000) {
      alert('Image dimensions exceed 1000x1000 pixels.');
      return;
    }

    try {
      setIsImageProcessing(true);
      const result = await imageServices.upscaleImage(image.file);
      const imageUrl = localApiRoot + result.upscaled_image;
      const blob = await imageServices.fileUrlToBlob(imageUrl);
      const upscaleFile = new File([blob], `upscaled_${image.file.name}`, {
        type: blob.type,
      });
      const attributes = await getImageAttributes(upscaleFile);
      setImage({ file: upscaleFile, isLocal: true, attributes });
    } catch (err) {
      console.log(err);
    } finally {
      setIsImageProcessing(false);
    }
  };

  return (
    <div className="surface-container">
      <div className="d-flex gap-3">
        DesignSurface
        <div>Open File</div>
        <input name="image" onChange={handleImageSelection} type="file" />
        {image?.file && (
          <button onClick={() => downloadImage(image.file)}>Download</button>
        )}
      </div>

      <div className="d-flex gap-2 p-2 h-100">
        <Toolbar
          onBackgroundRemove={handleBackgroundRemoval}
          onImageUpscale={handleImageUpscale}
          onImageEditClick={() => setIsEditorOpen(true)}
          hasImage={Boolean(image?.file)}
        />
        <ViewPort
          selectedImageUrl={imageUrl}
          imageAttributes={image?.attributes}
          isProcessing={isImageProcessing}
        />
      </div>
      {isEditorOpen && (
        <div
          className={`editor-wrapper model-wrapper ${image.attributes.width > image.attributes.height ? 'width-adjust' : 'height-adjust'}`}
        >
          <ReactPhotoEditor
            open={isEditorOpen}
            file={image?.file}
            onClose={() => setIsEditorOpen(false)}
            onSaveImage={async (file) => {
              const attributes = await getImageAttributes(file);
              setImage({ file: file, isLocal: true, attributes });
              setIsEditorOpen(false);
            }}
          />
        </div>
      )}
    </div>
  );
}

export default DesignSurface;
