export function logout() {
  localStorage.removeItem('user');
  localStorage.removeItem('company_id');
  if (!window.location.href.includes('/login')) {
    window.location.href = '/login';
  }
}

export function handleResponse(response) {
  return new Promise(function (resolve, reject) {
    response.text().then((text) => {
      let data = {};
      try {
        data = text && JSON.parse(text);
      } catch (e) {
        reject({
          error_code: 1,
          message: 'Something went wrong. Please try again later.',
        });
      }
      if (response.status === 401) {
        logout();
        //location.reload(true);
        return reject(data);
      }
      if (response.status === 200 || response.status === 201) {
        return resolve(data);
      }
      // if (response.status !== 200 || data.error_code !== 0) {

      // }
      return reject(data);

      // return resolve(data);
    });
  });
}

export function axiosResponse(response) {
  return new Promise(function (resolve, reject) {
    response.data = response.data || {};
    if (response.status === 401) {
      logout();
      //location.reload(true);
      return reject(response.data);
    }
    if (response.status === 200 || response.status === 201) {
      return resolve(response.data);
    }
    // Handle other HTTP response statuses or error cases
    return reject(response.data);
  });
}
