import * as fabric from 'fabric';
import { imageServices } from '../service/image.service';
import { isSvgUrl } from '../Utils/UtilFunctions';

const convertInchesToPixels = (inches, dpi = 300) => {
  return inches * dpi;
};

const GS_UNIT_VALUE = {
  25.4: { short: 'mm', unit: 'mm', value: 25.4 },
  1: { short: 'in', unit: 'inch', value: 1 },
};

export const canvasConstants = {
  CANVAS_DPI: 300,
  CANVAS_SVG_DPI: 96,
  SCREEN_DPI: 72,
  GS_UNIT_VALUE,
};

const findViewPortMeasures = ({
  fullWidth,
  fullHeight,
  padding = 50,
  viewportWidthInches,
  viewportHeightInches,
  dpi = 300,
}) => {
  // Subtract padding from the available space
  const paddedWidth = fullWidth - padding * 2;
  const paddedHeight = fullHeight - padding * 2;

  // Convert the viewport dimensions from inches to pixels
  let viewportWidthPixels = convertInchesToPixels(viewportWidthInches, dpi);
  let viewportHeightPixels = convertInchesToPixels(viewportHeightInches, dpi);

  // Calculate the scaling factor to fit the viewport within the padded space
  const widthScale = paddedWidth / viewportWidthPixels;
  const heightScale = paddedHeight / viewportHeightPixels;
  const scale = Math.min(widthScale, heightScale);

  viewportWidthPixels *= scale;
  viewportHeightPixels *= scale;

  const xPosition = (fullWidth - viewportWidthPixels) / 2;
  const yPosition = (fullHeight - viewportHeightPixels) / 2;

  return {
    scale,
    availableHeight: viewportHeightPixels,
    availableWidth: viewportWidthPixels,
    widthInInches: viewportWidthInches,
    heightInInches: viewportHeightInches,
    paddedWidth,
    paddedHeight,
    x: xPosition,
    y: yPosition,
  };
};

async function fetchAndResizeSVG(url, width, height) {
  try {
    const response = await fetch(url);
    const svgText = await response.text();
    // Create a new DOM element to parse SVG
    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(svgText, 'image/svg+xml');
    const svgElement = svgDoc.documentElement;
    // Set new width and height
    svgElement.setAttribute('width', width);
    svgElement.setAttribute('height', height);
    svgElement.setAttribute('viewBox', `0 0 ${width} ${height}`);
    return svgElement;
  } catch (error) {
    console.error('Error fetching or resizing SVG:', error);
  }
}
/**
 * Fetches, updates, and scales the SVG source from a URL and returns the modified SVG as an SVGElement.
 * @param {string} svgUrl - The URL of the SVG source.
 * @param {number} newWidth - The desired width of the SVG in pixels.
 * @param {number} newHeight - The desired height of the SVG in pixels.
 * @returns {Promise<SVGElement>} - A promise that resolves to the updated SVGElement.
 */
async function getUpdatedSvgElement(svgUrl, newWidth, newHeight) {
  try {
    // Fetch the SVG source text
    const response = await fetch(svgUrl);
    const svgText = await response.text();

    // Create a temporary DOM element to parse the SVG text
    const parser = new DOMParser();
    const svgDoc = parser.parseFromString(svgText, 'image/svg+xml');
    const svgElement = svgDoc.documentElement;

    // Get current viewBox values or default values
    const viewBox = svgElement
      .getAttribute('viewBox')
      ?.split(' ')
      .map(Number) || [0, 0, newWidth, newHeight];
    const [minX, minY, oldWidth, oldHeight] = viewBox;

    // Update width and height attributes
    svgElement.setAttribute('width', newWidth);
    svgElement.setAttribute('height', newHeight);

    // Calculate new viewBox values
    const newViewBoxWidth = oldWidth * (newWidth / oldWidth);
    const newViewBoxHeight = oldHeight * (newHeight / oldHeight);
    svgElement.setAttribute(
      'viewBox',
      `${minX} ${minY} ${newViewBoxWidth} ${newViewBoxHeight}`
    );

    // Scaling factor
    const scaleX = newWidth / oldWidth;
    const scaleY = newHeight / oldHeight;

    // Update elements based on their type
    const elements = svgElement.querySelectorAll('*');
    elements.forEach((element) => {
      if (element.tagName === 'text') {
        // Update font size for text elements
        const fontSize = parseFloat(element.getAttribute('font-size') || 16);
        const newFontSize = fontSize * scaleY;
        element.setAttribute('font-size', `${newFontSize}px`);
      } else if (element.tagName === 'rect') {
        // Scale rect attributes
        element.setAttribute(
          'x',
          parseFloat(element.getAttribute('x')) * scaleX
        );
        element.setAttribute(
          'y',
          parseFloat(element.getAttribute('y')) * scaleY
        );
        element.setAttribute(
          'width',
          parseFloat(element.getAttribute('width')) * scaleX
        );
        element.setAttribute(
          'height',
          parseFloat(element.getAttribute('height')) * scaleY
        );
      } else if (element.tagName === 'circle') {
        // Scale circle attributes
        element.setAttribute(
          'cx',
          parseFloat(element.getAttribute('cx')) * scaleX
        );
        element.setAttribute(
          'cy',
          parseFloat(element.getAttribute('cy')) * scaleY
        );
        element.setAttribute(
          'r',
          parseFloat(element.getAttribute('r')) * Math.min(scaleX, scaleY)
        );
      } else if (element.tagName === 'ellipse') {
        // Scale ellipse attributes
        element.setAttribute(
          'cx',
          parseFloat(element.getAttribute('cx')) * scaleX
        );
        element.setAttribute(
          'cy',
          parseFloat(element.getAttribute('cy')) * scaleY
        );
        element.setAttribute(
          'rx',
          parseFloat(element.getAttribute('rx')) * scaleX
        );
        element.setAttribute(
          'ry',
          parseFloat(element.getAttribute('ry')) * scaleY
        );
      } else if (element.tagName === 'path') {
        // Scale path 'd' attribute
        const pathData = element.getAttribute('d');
        const scaledPathData = scalePathData(pathData, scaleX, scaleY);
        element.setAttribute('d', scaledPathData);
      }
    });

    return svgElement;
  } catch (error) {
    console.error('Error fetching or updating SVG:', error);
    throw error;
  }
}

/**
 * Scales the path data (`d` attribute) for SVG paths.
 * @param {string} pathData - The original path data.
 * @param {number} scaleX - The scaling factor for the x-axis.
 * @param {number} scaleY - The scaling factor for the y-axis.
 * @returns {string} - The scaled path data.
 */
function scalePathData(pathData, scaleX, scaleY) {
  const commands = pathData.match(/[a-z][^a-z]*/gi);
  return commands
    .map((command) => {
      const type = command[0];
      const numbers = command
        .slice(1)
        .trim()
        .split(/[\s,]+/)
        .map(Number);

      for (let i = 0; i < numbers.length; i += 2) {
        numbers[i] *= scaleX; // Scale X coordinate
        if (numbers[i + 1] !== undefined) numbers[i + 1] *= scaleY; // Scale Y coordinate
      }

      return type + numbers.join(' ');
    })
    .join(' ');
}

const findViewPortDimension = () => {
  const topBarHeight = getComputedStyle(
    document.documentElement
  ).getPropertyValue('--top-bar-height');

  const fullWidth = window.innerWidth - 300;
  const fullHeight = window.innerHeight - (parseInt(topBarHeight) + 40);

  return { fullWidth, fullHeight };
};

/***
 * For removing the currently selected item in from the canvas.
 * @param param
 * @param canvas - The canvas object
 */
const removeActiveObjectFromCanvas = ({ canvas }) => {
  if (!canvas) {
    return;
  }
  const activeItems = canvas.getActiveObjects();
  if (activeItems) {
    for (const activeItem of activeItems) {
      canvas.remove(activeItem);
    }
    canvas.discardActiveObject();
    canvas.renderAll();
    return;
  }

  const activeItem = canvas.getActiveObject();
  if (activeItem) {
    canvas.remove(activeItem);
    canvas.discardActiveObject();
    canvas.renderAll();
  }
};

const drawImageFromURL = async ({
  item,
  shouldPlaceCenterOnRotate,
  imageHeight,
  imageWidth,
  imageX,
  imageY,
  newScaleX,
  newScaleY,
  image,
}) => {
  const hasRotation = Boolean(item.rotation);
  if (hasRotation) {
    const correctedRotation = shouldPlaceCenterOnRotate
      ? imageHeight * item.scaleX
      : 0;

    const fabImage = await fabric.FabricImage.fromURL(
      image.src,
      { crossOrigin: 'anonymous' },
      {
        width: imageWidth,
        height: imageHeight,
        cornerColor: 'black',
        // cornerStyle: 'circle',
        hasBorders: true,
        borderColor: 'black',
        left: imageX + correctedRotation,
        top: imageY,
        scaleX: newScaleX,
        snapThreshold: 1,
        scaleY: newScaleY,
        transparentCorners: false,
        ...(hasRotation ? { angle: item.rotation } : {}),
      }
    );

    return { fabImage };
  }

  const fabImage = await fabric.FabricImage.fromURL(
    image.src,
    { crossOrigin: 'anonymous' },
    {
      width: imageWidth,
      height: imageHeight,
      cornerColor: 'black',
      // cornerStyle: 'circle',
      borderColor: 'black',
      hasBorders: true,
      left: imageX,
      top: imageY,
      scaleX: newScaleX,
      snapThreshold: 1,
      scaleY: newScaleY,
      transparentCorners: false,
    }
  );

  return { fabImage };
};
const drawImageFromImageElement = ({
  item,
  shouldPlaceCenterOnRotate,
  imageHeight,
  imageWidth,
  imageX,
  imageY,
  newScaleX,
  newScaleY,
  image,
}) => {
  const hasRotation = Boolean(item.rotation);
  if (hasRotation) {
    const correctedRotation = shouldPlaceCenterOnRotate
      ? imageHeight * item.scaleX
      : 0;

    const fabImage = new fabric.FabricImage(image, {
      width: imageWidth,
      height: imageHeight,
      cornerColor: 'black',
      // cornerStyle: 'circle',
      hasBorders: true,
      borderColor: 'black',
      left: imageX + correctedRotation,
      top: imageY,
      scaleX: newScaleX,
      snapThreshold: 1,
      scaleY: newScaleY,
      transparentCorners: false,
      ...(hasRotation ? { angle: item.rotation } : {}),
    });

    return { fabImage };
  }

  // const fabImage = await fabric.FabricImage.fromURL(
  //   image.src,
  //   { crossOrigin: 'anonymous' },
  //   {
  //     width: imageWidth,
  //     height: imageHeight,
  //     cornerColor: 'black',
  //     // cornerStyle: 'circle',
  //     borderColor: 'black',
  //     hasBorders: true,
  //     left: imageX,
  //     top: imageY,
  //     scaleX: newScaleX,
  //     snapThreshold: 1,
  //     scaleY: newScaleY,
  //     transparentCorners: false,
  //   }
  // );

  const fabImage = new fabric.FabricImage(image, {
    width: imageWidth,
    height: imageHeight,
    cornerColor: 'black',
    // cornerStyle: 'circle',
    borderColor: 'black',
    hasBorders: true,
    left: imageX,
    top: imageY,
    scaleX: newScaleX,
    snapThreshold: 1,
    scaleY: newScaleY,
    transparentCorners: false,
  });

  return { fabImage };
};

const getImageFabricBasedOnRotation = async ({
  image,
  item,
  viewPortConfig,
  takeItemScale,
  isAdd,
  shouldPlaceCenterOnRotate,
  canvas,
}) => {
  // console.log('TestingItem', item);
  // Find image is svg or not
  const isSvg = item.isSvg;

  const adjustedSvgScale =
    isSvg && isAdd
      ? canvasConstants.CANVAS_DPI / canvasConstants.CANVAS_SVG_DPI
      : 1;

  let newScaleX =
    adjustedSvgScale *
    (takeItemScale ? item.scaleX : viewPortConfig.scale ?? item.scaleX);
  let newScaleY =
    adjustedSvgScale *
    (takeItemScale ? item.scaleY : viewPortConfig.scale ?? item.scaleY);

  const imageWidth = item.originalWidth;
  const imageHeight = item.originalHeight;

  let imageX = viewPortConfig.x + item.x;
  const imageY = viewPortConfig.y + item.y;
  return await drawImageFromURL({
    imageHeight,
    imageWidth,
    imageX,
    imageY,
    item,
    newScaleX,
    newScaleY,
    shouldPlaceCenterOnRotate,
    image,
  });
  // return drawImageFromImageElement({
  //   imageHeight,
  //   imageWidth,
  //   imageX,
  //   imageY,
  //   item,
  //   newScaleX,
  //   newScaleY,
  //   shouldPlaceCenterOnRotate,
  //   image,
  // });
};

const drawSingleImage = async ({
  canvas,
  item,
  image,
  viewPortConfig,
  initCanvasMeasures = null,
  takeItemScale = false,
  isAdd = false,
  shouldPlaceCenterOnRotate = false,
}) => {
  if (!canvas) {
    return { fabImage: null };
  }

  const { fabImage } = await getImageFabricBasedOnRotation({
    image,
    item,
    viewPortConfig,
    takeItemScale,
    isAdd,
    shouldPlaceCenterOnRotate,
    canvas,
  });

  if (!fabImage) {
    return;
  }

  if (initCanvasMeasures) {
    const clipRect = new fabric.Rect({
      left: initCanvasMeasures.x,
      top: initCanvasMeasures.y,
      width: initCanvasMeasures.availableWidth,
      height: initCanvasMeasures.availableHeight,
      absolutePositioned: true,
    });

    // Show only corner handlers
    fabImage.setControlsVisibility({
      mt: false, // middle top
      mb: false, // middle bottom
      ml: false, // middle left
      mr: false, // middle right
      bl: true, // bottom left
      br: true, // bottom right
      tl: true, // top left
      tr: true, // top right
    });

    fabImage.set({
      clipPath: clipRect,
    });

    canvas.add(fabImage);
    canvas.setActiveObject(fabImage);

    requestAnimationFrame(() => {
      canvas.renderAll();
    });

    return { fabImage };
  }
};

const drawTextOnViewPort = ({ canvas, viewPortConfig }) => {
  const text = new fabric.IText('Hello, World!', {
    left: viewPortConfig.x + 10,
    top: viewPortConfig.y + 10,
    fontFamily: 'Arial',
    fontSize: 30,
    fill: 'black',
    selectable: true,
    cornerColor: 'black',
    borderColor: 'black',
    transparentCorners: false,
  });

  const clipRect = new fabric.Rect({
    left: viewPortConfig.x,
    top: viewPortConfig.y,
    width: viewPortConfig.availableWidth,
    height: viewPortConfig.availableHeight,
    absolutePositioned: true,
  });

  text.set({
    clipPath: clipRect,
  });

  // Add the text object to the canvas
  canvas.add(text);

  // Render the canvas
  canvas.renderAll();
};

// Draw Image
const drawImageOnViewPort = async ({
  canvas,
  items = [],
  scale = null,
  viewPortConfig,
  preloadedImages,
}) => {
  for (const item of items) {
    if (item.type === 'image') {
      const image = preloadedImages.current.get(item.file);

      if (image?.src) {
        await drawSingleImage({ canvas, item, image, viewPortConfig });
      }
    }
  }
};

const preloadImages = async ({
  selectedItems,
  preloadedImages,
  viewPortMeasures,
  canvas,
}) => {
  selectedItems.forEach((item) => {
    if (item.type === 'image' && !preloadedImages.current.has(item.file)) {
      const image = new Image();
      image.src = URL.createObjectURL(item.file);
      image.onload = async () => {
        preloadedImages.current.set(item.file, image);

        await drawSingleImage({
          canvas,
          item,
          image,
          viewPortConfig: viewPortMeasures,
        });

        // // Trigger an initial redraw when image is loaded
        // const ctx = outerCanvasRef.current.getContext('2d');
        // redrawImage({ ctx });
      };
    }
  });
};

const generateIdForAList = (list = []) => {
  if (!Array.isArray(list)) {
    return 0;
  }

  const lastIndex = list.length - 1;
  const lastItem = list?.[lastIndex];
  if (!lastItem || !lastItem?.id) {
    return 0;
  }

  return lastItem.id + 1;
};

function generateNextIdForMap(map) {
  if (!map || map.size === 0) {
    return 1; // Start from 1 if map is empty
  }

  const keys = Array.from(map.keys());
  const maxKey = Math.max(...keys);

  return maxKey + 1; // Next key is the max key + 1
}

// FInd position

export function calculateItemPositionAndSize(item, canvasConfig, allItems) {
  const calcObj = {};

  const isSvg = item?.isSvg;

  // For svg we need to adjust the scale ratio by multiplying with 300/96
  const sizeToAdjustForSvg = isSvg
    ? canvasConstants.CANVAS_DPI / canvasConstants.CANVAS_SVG_DPI
    : 1;

  const position = findFitPosition({
    imageWidth: item.width * sizeToAdjustForSvg,
    imageHeight: item.height * sizeToAdjustForSvg,
    placedItems: allItems,
    canvasConfig,
    isSvg,
  });

  // if (position?.x && position?.y) {
  calcObj.x = position?.x ? position.x : null;
  calcObj.y = position?.y ? position.y : null;
  // }

  return { ...calcObj, isImage: true };
}

function findFitPosition({
  imageWidth,
  imageHeight,
  placedItems,
  canvasConfig,
  isSvg,
}) {
  let position = null;

  const {
    availableWidth: canvasWidth,
    availableHeight: canvasHeight,
    // scale: canvasScale,
    x: canvasX,
    y: canvasY,
    margin = 0,
  } = canvasConfig;

  const startX = 0 + (margin || 0);
  const startY = 0 + (margin || 0);

  for (let y = startY; y <= canvasHeight - imageHeight - margin; ) {
    for (let x = startX; x <= canvasWidth - imageWidth - margin; ) {
      let fits = true;

      // Checking overlap in each item
      for (let item of placedItems) {
        if (
          isOverlapping(x, y, imageWidth, imageHeight, item, canvasX, canvasY)
        ) {
          fits = false;
          x = item.left - canvasX + item.width * item.scaleX + margin;

          // x = x + item.width * item.scaleX;

          break;
        }
      }

      if (fits) {
        position = { x, y };
        break;
      }
    }
    if (position) break;
    // y += 1;
    y += imageHeight + margin;
  }

  return position;
}

function isOverlapping(x1, y1, w1, h1, item, canvasX, canvasY) {
  // const isRotated = Boolean(item.angle);
  const itemLeft = item.left - canvasX;
  const itemTop = item.top - canvasY;
  // const itemWidth = item.width * (item.scaleX / canvasScale + canvasScale);
  let itemWidth = item.width * item.scaleX;

  // const itemHeight = item.height * (item.scaleY / canvasScale + canvasScale);
  let itemHeight = item.height * item.scaleY;

  if (Boolean(item.angle)) {
    const temp = itemWidth;
    itemWidth = itemHeight;
    itemHeight = temp;

    return !(
      x1 + w1 <= itemLeft - itemHeight || // Adjust the itemLeft by reducing the itemHeight
      x1 >= itemLeft + itemWidth ||
      y1 + h1 <= itemTop ||
      y1 >= itemTop + itemHeight
    );
  }

  return !(
    x1 + w1 <= itemLeft ||
    x1 >= itemLeft + itemWidth ||
    y1 + h1 <= itemTop ||
    y1 >= itemTop + itemHeight
  );
}

/***
 * Generate auto build selection images
 */
const generatePlainObject = ({
  canvasItemList = [],
  viewPortMeasures,
  selectedUnit,
}) => {
  const combinedList = [...canvasItemList];

  const getSource = (item) => {
    if (item?._originalElement?.src) {
      return item._originalElement.src;
    }
    return item.src;
  };

  const selectionList = combinedList.reduce((acc, currentItem) => {
    const isSvg = isSvgUrl(getSource(currentItem));
    const svgAdjustment = isSvg
      ? canvasConstants.CANVAS_DPI / canvasConstants.CANVAS_SVG_DPI
      : 1;
    const scaleX = viewPortMeasures.scale * svgAdjustment;
    const scaleY = viewPortMeasures.scale * svgAdjustment;

    const itemWidth = (
      ((currentItem.width * scaleX) /
        (canvasConstants.CANVAS_DPI * viewPortMeasures.scale)) *
      25.4
    ).toFixed(2);
    const itemHeight = (
      ((currentItem.height * scaleY) /
        (canvasConstants.CANVAS_DPI * viewPortMeasures.scale)) *
      25.4
    ).toFixed(2);
    // Check if item already added to selectedItems
    const existingItem = acc.find(
      (itm) =>
        itm?.width === itemWidth &&
        itm?.height === itemHeight &&
        getSource(itm) === getSource(currentItem)
    );

    if (currentItem.isAddedImage) {
      acc.push({
        scaleX: scaleX,
        scaleY: scaleY,
        width: itemWidth,
        height: itemHeight,
        src: getSource(currentItem),
        noOfItems: 0,
      });
    } else if (existingItem) {
      existingItem.noOfItems += 1;
    } else {
      acc.push({
        scaleX: scaleX,
        scaleY: scaleY,
        width: itemWidth,
        height: itemHeight,
        src: getSource(currentItem),
        noOfItems: 1,
      });
    }

    return acc;
  }, []);

  return selectionList;
};
/***
 * Generate auto build selection images
 */
const generateSelectionItemsForAutoBuild = ({
  addedImageList = [],
  canvasItemList = [],
  viewPortMeasures,
}) => {
  const combinedList = [...addedImageList, ...canvasItemList];
  const getSource = (item) => {
    if (item?._originalElement?.src) {
      return item._originalElement.src;
    }
    return item.src;
  };

  const selectionList = combinedList
    .filter(
      (item) => item?.isAddedImage || item?.type?.toLowerCase() === 'image'
    )
    .reduce((acc, currentItem) => {
      // Check if item already added to selectedItems
      const existingItem = acc.find(
        (itm) =>
          (itm?.width * itm?.scaleX).toFixed() ===
            (currentItem?.width * currentItem?.scaleX).toFixed() &&
          (itm?.height * itm?.scaleX).toFixed() ===
            (currentItem?.height * currentItem?.scaleX).toFixed() &&
          getSource(itm) === getSource(currentItem)
      );

      const isSvg = isSvgUrl(getSource(currentItem));
      const svgAdjustment = isSvg
        ? canvasConstants.CANVAS_DPI / canvasConstants.CANVAS_SVG_DPI
        : 1;

      if (currentItem.isAddedImage) {
        acc.push({
          ...currentItem,
          scaleX: viewPortMeasures.scale * svgAdjustment,
          scaleY: viewPortMeasures.scale * svgAdjustment,
          noOfItems: 0,
        });
      } else if (existingItem) {
        existingItem.noOfItems += 1;
      } else {
        acc.push({
          ...currentItem,
          noOfItems: 1,
        });
      }

      return acc;
    }, []);

  return selectionList;
};

// Auto BUild function

function placeItems(viewPortMeasures, canvas, items) {
  const { availableWidth: canvasWidth, availableHeight: canvasHeight } =
    viewPortMeasures;
  // Sort items by area (largest first)
  items.sort((a, b) => b.width * b.height - a.width * a.height);

  const updatedItems = items.reduce((acc, currentItem) => {
    const requiredArray = Array.from({ length: currentItem.noOfItems }, () => ({
      ...currentItem,
    }));

    return acc.concat(requiredArray);
  }, []);

  let occupiedSpaces = [];

  for (let item of updatedItems) {
    let placed = false;

    const imageHeight = item.height * item.scaleY;
    const imageWidth = item.width * item.scaleX;

    // Try to place without rotation
    for (let y = 0; y <= canvasHeight - imageHeight; y++) {
      for (let x = 0; x <= canvasWidth - imageWidth; x++) {
        const itemObject = {
          x,
          y,
          width: imageWidth,
          height: imageHeight,
        };

        if (
          checkFit({
            item: itemObject,
            occupiedSpaces,
            viewPortMeasures,
            canvas,
          })
        ) {
          placeItem({ x, y, item, occupiedSpaces, viewPortMeasures, canvas });
          x += imageWidth;
          placed = true;
          break;
        } else {
          x++;
        }
      }
      if (placed) break;
    }

    if (placed) break;

    // Try to place with rotation if not placed
    // if (!placed) {
    //   let rotatedWidth = imageHeight;
    //   let rotatedHeight = imageWidth;
    //   for (let y = 0; y <= canvasHeight - rotatedHeight; y++) {
    //     for (let x = 0; x <= canvasWidth - rotatedWidth; ) {
    //       const item = { x, y, width: rotatedWidth, height: rotatedHeight };
    //       if (
    //         checkFit({
    //           item,
    //           occupiedSpaces,
    //           viewPortMeasures,
    //           canvas,
    //         })
    //       ) {
    //         placeItem({
    //           x,
    //           y,
    //           item,
    //           occupiedSpaces,
    //           rotated: true,
    //           canvas,
    //           viewPortMeasures,
    //         });
    //         placed = true;

    //         x += imageWidth;

    //         break;
    //       } else {
    //         x++;
    //       }
    //     }
    //     if (placed) break;
    //   }
    // }

    if (!placed) {
      console.log(`Could not place item ${item._originalElement.src}`);
    }
  }

  return occupiedSpaces;
}

function checkFit({ item, occupiedSpaces, viewPortMeasures, canvas }) {
  const [, ...allItems] = canvas._objects;
  const { x: canvasX, y: canvasY } = viewPortMeasures;
  let fits = true;
  const canvasObjectList = allItems ?? [];

  // isOverlapping(, y1, w1, h1, item, canvasX, canvasY);
  for (let itemObject of canvasObjectList) {
    if (
      isOverlapping(
        item.x,
        item.y,
        item.width,
        item.height,
        itemObject,
        canvasX,
        canvasY
      )
    ) {
      // x = x + item.width * item.scaleX;
      fits = false;
      break;
    }
  }
  return fits;
  // return calculateItemPositionAndSize(item, viewPortMeasures, allItems);
}

async function placeItem({
  x,
  y,
  item,
  canvas,
  viewPortMeasures,
  occupiedSpaces,
  rotated = false,
}) {
  await drawSingleImage({
    canvas,
    item,
    image: item._originalElement,
    viewPortConfig: viewPortMeasures,
  });
  occupiedSpaces.push({
    x: x,
    y: y,
    width: rotated ? item.height : item.width,
    height: rotated ? item.width : item.height,
  });
}

// New function
//
/***
 * For building images automatically
 * @param items - The selected object list
 */
function autoBuildImages({
  items,
  canvasMeasures,
  margin = 0,
  takeItemScale = false,
  canvasInitMeasures,
}) {
  const updatedItems = items.reduce((acc, currentItem) => {
    const requiredArray = Array.from({ length: currentItem.noOfItems }, () => ({
      ...currentItem,
    }));

    return acc.concat(requiredArray);
  }, []);

  const positions = placeImages({
    images: updatedItems,
    margin,
    takeItemScale,
    canvasMeasures,
    canvasInitMeasures,
  });

  return positions;
}

function placeImages({
  images,
  margin = 0,
  takeItemScale = false,
  canvasMeasures,
  canvasInitMeasures,
}) {
  const {
    // availableWidth: canvasWidth,
    // availableHeight: canvasHeight,
    x: canvasX,
    y: canvasY,
  } = canvasMeasures;
  const {
    x: canvasInitX,
    y: canvasInitY,
    availableWidth: canvasInitWidth,
    availableHeight: canvasInitHeight,
  } = canvasInitMeasures;
  // Initialize an array to store the positions of each image
  const positions = [];

  const xDiff = canvasInitX - canvasX;
  const yDiff = canvasInitY - canvasY;

  // Available spaces to place the next image
  let availableSpaces = [
    {
      x: xDiff,
      y: yDiff,
      width: canvasInitWidth,
      height: canvasInitHeight,
    },
  ];
  // Sort images by their largest dimension (width or height) for better packing
  images.sort((a, b) =>
    takeItemScale
      ? Math.max(b.width * b.scaleX, b.height * b.scaleY) -
        Math.max(a.width * a.scaleX, a.height * a.scaleY)
      : Math.max(b.width, b.height) - Math.max(a.width, a.height)
  );

  // Function to check if an image fits in a given space
  function fitsInSpace(space, imageWidth, imageHeight) {
    return space.width >= imageWidth && space.height >= imageHeight;
  }

  // Function to split space after placing an image
  function splitSpace(space, imageWidth, imageHeight) {
    const newSpaces = [];

    // Space to the right
    if (space.width > imageWidth) {
      newSpaces.push({
        x: space.x + imageWidth,
        y: space.y,
        width: space.width - imageWidth,
        height: imageHeight,
      });
    }

    // Space below
    if (space.height > imageHeight) {
      newSpaces.push({
        x: space.x,
        y: space.y + imageHeight,
        width: space.width,
        height: space.height - imageHeight,
      });
    }

    return newSpaces;
  }

  // Loop through each image and place it in the best available space
  images.forEach((image) => {
    let placed = false;

    let imageWidth = image.width * image.scaleX;
    let imageHeight = image.height * image.scaleY;
    const start = 0 + margin;
    for (let i = start; i < availableSpaces.length; i++) {
      const space = availableSpaces[i];

      const shouldRotate =
        canvasInitWidth / imageHeight > canvasInitWidth / imageWidth;

      if (!shouldRotate) {
        // Check if image fits without rotation
        if (fitsInSpace(space, imageWidth, imageHeight)) {
          positions.push({
            x: space.x,
            y: space.y,
            originalWidth: image.width,
            originalHeight: image.height,
            element: image._originalElement,
            scaleX: image.scaleX,
            scaleY: image.scaleY,
            width: imageWidth,
            height: imageHeight,
            rotation: 0,
          });

          // Update available spaces
          availableSpaces = [
            ...availableSpaces.slice(0, i),
            ...splitSpace(space, imageWidth, imageHeight),
            ...availableSpaces.slice(i + 1),
          ];
          placed = true;
          break;
        }
      } else {
        // Check if image fits with 90-degree rotation
        if (fitsInSpace(space, imageHeight, imageWidth, image)) {
          positions.push({
            x: space.x,
            y: space.y,
            originalWidth: image.width,
            element: image._originalElement,
            originalHeight: image.height,
            scaleX: image.scaleX,
            scaleY: image.scaleY,
            width: imageHeight,
            height: imageWidth,
            rotation: 90,
          });

          // Update available spaces
          availableSpaces = [
            ...availableSpaces.slice(0, i),
            ...splitSpace(space, imageHeight, imageWidth, image),
            ...availableSpaces.slice(i + 1),
          ];
          placed = true;
          break;
        }
      }
    }

    // If not placed, continue with next image (if some images cannot be placed)
    if (!placed) {
      console.warn('Image could not be placed:', image);
    }
  });

  return positions;
}

// End of auto build
//
//
//
function checkForObjectOverlap(canvas, activeObject) {
  // const canvasInstance = e.target.canvas;

  if (!activeObject) return;

  const objects = canvas.getObjects();
  for (let i = 0; i < objects.length; i++) {
    const obj = objects[i];
    if (obj === activeObject) continue; // Skip comparing with itself

    if (isObjectOverlapping(activeObject, obj)) {
      // isObjectOverlapping([obj]);
      return true; // Exit as soon as overlap is detected
    } else {
      return false;
      // isObjectOverlapping([]);
    }
  }
}

// Function to determine if two objects are overlapping
function isObjectOverlapping(obj1, obj2) {
  const obj1BoundingRect = obj1.getBoundingRect();
  const obj2BoundingRect = obj2.getBoundingRect();

  return !(
    obj1BoundingRect.left > obj2BoundingRect.left + obj2BoundingRect.width ||
    obj1BoundingRect.left + obj1BoundingRect.width < obj2BoundingRect.left ||
    obj1BoundingRect.top > obj2BoundingRect.top + obj2BoundingRect.height ||
    obj1BoundingRect.top + obj1BoundingRect.height < obj2BoundingRect.top
  );
}

export const TEXT_FONTS = [
  {
    id: 1,
    name: 'New Amsterdam',
    label: 'New Amsterdam',
    availableWeight: [{ weight: 400, label: 'Normal', isDefault: true }],
  },
  {
    id: 2,
    name: 'Roboto',
    label: 'Roboto',
    availableWeight: [{ weight: 400, label: 'Normal', isDefault: true }],
  },
  {
    id: 3,
    name: 'Oswald',
    label: 'Oswald',
    availableWeight: [{ weight: 400, label: 'Normal', isDefault: true }],
  },
  {
    id: 4,
    name: 'Sevillana',
    label: 'Sevillana',
    availableWeight: [{ weight: 400, label: 'Normal', isDefault: true }],
  },
];

function snapToGrid(activeObject, canvas) {
  var snapThreshold = 10; // Set your desired snapping threshold
  var objects = canvas.getObjects();

  for (var i = 0; i < objects.length; i++) {
    if (objects[i] === activeObject) continue; // Skip the currently moving object

    // Check horizontal alignment
    if (Math.abs(objects[i].left - activeObject.left) < snapThreshold) {
      activeObject.set('left', objects[i].left);
    }
    // Check vertical alignment
    if (Math.abs(objects[i].top - activeObject.top) < snapThreshold) {
      activeObject.set('top', objects[i].top);
    }
  }
  activeObject.setCoords(); // Update the object's coordinates
  canvas.renderAll(); // Re-render the canvas
}

function generateNextSheetName(sheetMap) {
  const sheetNames = Object.keys(sheetMap);

  const sheetNumbers = sheetNames
    .map((name) => {
      const match = name.match(/^Sheet(\d+)$/);
      return match ? parseInt(match[1], 10) : null;
    })
    .filter((num) => num !== null);

  const maxNumber = sheetNumbers.length > 0 ? Math.max(...sheetNumbers) : 0;

  // Generate the next sheet name
  const nextNumber = maxNumber + 1;
  const nextSheetName = `Sheet${nextNumber}`;

  return nextSheetName;
}

const generateDownloadCanvas = (viewPortMeasures, oldCanvas) => {
  const {
    availableHeight,
    availableWidth,
    scale,
    x: viewPortX,
    y: viewPortY,
  } = viewPortMeasures;

  const newCanvas = document.createElement('canvas');
  newCanvas.width = availableWidth / scale;
  newCanvas.height = availableHeight / scale;

  const highResCanvas = new fabric.Canvas(newCanvas, {
    width: availableWidth / scale,
    height: availableHeight / scale,
  });

  // const rect = new fabric.Rect({
  //   width: availableWidth / scale - 5,
  //   height: availableHeight / scale - 5, // minus stroke width
  //   stroke: 'white',
  //   fill: 'transparent',
  //   strokeWidth: 5,
  //   left: 0,
  //   top: 0,
  // });

  // highResCanvas.add(rect);

  const objects = oldCanvas.getObjects();

  objects.forEach((obj) => {
    const scaleXDiff = 1 / scale;
    const scaleYDiff = 1 / scale;
    const newObj = new fabric.FabricImage(obj._originalElement, {
      left: (obj.left - viewPortX) * scaleXDiff,
      top: (obj.top - viewPortY) * scaleYDiff,
      width: obj.width,
      height: obj.height,
      scaleX: obj.scaleX / scale,
      scaleY: obj.scaleX / scale,
      selectable: false,
    });

    highResCanvas.add(newObj);
  });

  highResCanvas.renderAll();

  return { fabricObj: highResCanvas, canvas: newCanvas };
};

const getImageItemCount = ({
  srcToCompare,
  canvas,
  sheetMap,
  currentSheetName,
  filterRequiredObject = () => true,
}) => {
  if (!srcToCompare) {
    return 0;
  }
  const sheetMapArray = Object.entries(sheetMap)
    .map(([key, value]) => ({ ...value, sheetName: key }))
    .filter((item) => item.sheetName !== currentSheetName);

  const currentImageCount = canvas?._objects?.filter(
    (item) => item?._originalElement?.src === srcToCompare
  )?.length;

  const objectsInOtherSheets = sheetMapArray.flatMap(
    (item) => JSON.parse(item.canvas).objects
  );

  const requiredObjectsInOtherSheet = objectsInOtherSheets?.filter(
    (item) => item?.src === srcToCompare
  );

  const imageInOtherSheetCount = requiredObjectsInOtherSheet.length ?? 0;
  return currentImageCount + imageInOtherSheetCount;
};

const generateDownloadableCanvasForSvg = (viewPortMeasures, oldCanvas) => {
  const {
    availableHeight,
    availableWidth,
    scale,
    x: viewPortX,
    y: viewPortY,
  } = viewPortMeasures;

  const dpiScaleFactor =
    canvasConstants.CANVAS_SVG_DPI / canvasConstants.CANVAS_DPI;

  const canvasWidth = (availableWidth / scale) * dpiScaleFactor;
  const canvasHeight = (availableHeight / scale) * dpiScaleFactor;

  const newCanvas = document.createElement('canvas');
  // newCanvas.width = canvasWidth;
  // newCanvas.height = canvasHeight;

  const backgroundRect = new fabric.Rect({
    width: canvasWidth - 1,
    height: canvasHeight - 1,
    stroke: 'black',
    left: 0,
    top: 0,
    fill: 'transparent',
    strokeWidth: 1,
  });

  const highResCanvas = new fabric.Canvas(newCanvas, {
    width: canvasWidth,
    height: canvasHeight,
    // backgroundColor: 'transparent',
  });

  const objects = oldCanvas.getObjects();

  objects.forEach((obj) => {
    if (
      obj.type?.toLowerCase() !== 'i-text' &&
      obj.type?.toLowerCase() !== 'image'
    ) {
      return;
    }

    if (obj?.type?.toLowerCase() === 'image') {
      const elementClone = obj._originalElement.cloneNode(true);
      elementClone.width = obj.width;
      elementClone.height = obj.height;
      elementClone.crossOrigin = 'Anonymous';

      const newObj = new fabric.FabricImage(elementClone, {
        left: ((obj.left - viewPortX) / scale) * dpiScaleFactor,
        top: ((obj.top - viewPortY) / scale) * dpiScaleFactor,
        width: obj.width,
        height: obj.height,
        fill: 'transparent',
        scaleX: (obj.scaleX / scale) * dpiScaleFactor,
        scaleY: (obj.scaleX / scale) * dpiScaleFactor,
        selectable: false,
        angle: obj.angle,
      });

      highResCanvas.add(newObj);
    }
    if (obj?.type?.toLowerCase() === 'i-text') {
      console.log('ADDING TEXT');

      const text = new fabric.IText(obj.text, {
        fontFamily: obj.fontFamily,
        width: obj.width,
        height: obj.height,
        fontSize: obj.fontSize,
        left: ((obj.left - viewPortX) / scale) * dpiScaleFactor,
        top: ((obj.top - viewPortY) / scale) * dpiScaleFactor,
        scaleX: (obj.scaleX / scale) * dpiScaleFactor,
        scaleY: (obj.scaleX / scale) * dpiScaleFactor,
      });
      highResCanvas.add(text);
    }
  });

  highResCanvas.add(backgroundRect);

  highResCanvas.renderAll();

  console.log('CANVAS ITEMS:', highResCanvas.getObjects());

  return { fabricObj: highResCanvas, canvas: newCanvas };

  //   const newObj = new fabric.FabricImage(obj._originalElement, {
  //     left: obj.left - x,
  //     top: obj.top - y,
  //     width: obj.width,
  //     height: obj.height,
  //     scaleX: 1,
  //     scaleY: 1,
  //   });

  //   highResCanvas.add(newObj);
  // }

  // return highResCanvas;
};

// New auto build function
function autoBuildImagesV2({
  items,
  canvasMeasures,
  margin = 0,
  takeItemScale = false,
  canvasInitMeasures,
}) {
  const updatedItems = items.reduce((acc, currentItem) => {
    const requiredArray = Array.from({ length: currentItem.noOfItems }, () => ({
      ...currentItem,
    }));

    return acc.concat(requiredArray);
  }, []);

  const { positions, unplacedImages } = placeImagesV2({
    images: updatedItems,
    margin,
    takeItemScale,
    canvasMeasures,
    canvasInitMeasures,
  });

  return { positions, unplacedImages };
}

function shouldRotateImageOld(
  imgWidth,
  imgHeight,
  availableWidth,
  availableHeight
) {
  // Normal orientation
  const normalFitHorizontally = Math.floor(availableWidth / imgWidth);
  const normalFitVertically = Math.floor(availableHeight / imgHeight);
  const normalTotalImages = normalFitHorizontally * normalFitVertically;

  // Rotated orientation
  const rotatedFitHorizontally = Math.floor(availableWidth / imgHeight);
  const rotatedFitVertically = Math.floor(availableHeight / imgWidth);
  const rotatedTotalImages = rotatedFitHorizontally * rotatedFitVertically;

  // Return true if rotated orientation fits more images
  return rotatedTotalImages > normalTotalImages;
}
function shouldRotateImage(
  imgWidth,
  imgHeight,
  availableWidth,
  availableHeight
) {
  // Calculate the number of images that can fit in normal orientation
  const normalFitHorizontally = Math.floor(availableWidth / imgWidth);
  const normalFitVertically = Math.floor(availableHeight / imgHeight);
  const normalTotalImages = normalFitHorizontally * normalFitVertically;
  const normalWastedSpace =
    (availableWidth % imgWidth) * availableHeight +
    availableWidth * (availableHeight % imgHeight);

  // Calculate the number of images that can fit in rotated orientation
  const rotatedFitHorizontally = Math.floor(availableWidth / imgHeight);
  const rotatedFitVertically = Math.floor(availableHeight / imgWidth);
  const rotatedTotalImages = rotatedFitHorizontally * rotatedFitVertically;
  const rotatedWastedSpace =
    (availableWidth % imgHeight) * availableHeight +
    availableWidth * (availableHeight % imgWidth);

  // Choose the orientation that gives the least wasted space and fits more images
  if (rotatedTotalImages > normalTotalImages) {
    return true; // Rotate image
  } else if (rotatedTotalImages === normalTotalImages) {
    return rotatedWastedSpace < normalWastedSpace; // Rotate if it reduces wasted space
  }
  return false; // Don't rotate image
}

function placeImagesV2({
  images,
  margin = 0,
  takeItemScale = false,
  canvasMeasures,
  canvasInitMeasures,
}) {
  const { x: canvasX, y: canvasY } = canvasMeasures;
  const {
    x: canvasInitX,
    y: canvasInitY,
    availableWidth: canvasInitWidth,
    availableHeight: canvasInitHeight,
  } = canvasInitMeasures;

  const positions = [];
  const unplacedImages = [];

  const xDiff = canvasInitX - canvasX;
  const yDiff = canvasInitY - canvasY;

  let availableSpaces = [
    {
      x: xDiff,
      y: yDiff,
      width: canvasInitWidth - margin,
      height: canvasInitHeight - margin,
    },
  ];

  // Sort images by their largest dimension (width or height) for better packing
  images.sort((a, b) =>
    takeItemScale
      ? Math.max(b.width * b.scaleX, b.height * b.scaleY) -
        Math.max(a.width * a.scaleX, a.height * a.scaleY)
      : Math.max(b.width, b.height) - Math.max(a.width, a.height)
  );

  function fitsInSpace(space, imageWidth, imageHeight) {
    return (
      space.width >= imageWidth + margin && space.height >= imageHeight + margin
    );
  }

  function splitSpace(space, imageWidth, imageHeight) {
    const newSpaces = [];

    // Adjust for margin when splitting space
    const adjustedImageWidth = imageWidth + margin;
    const adjustedImageHeight = imageHeight + margin;

    // Space to the right
    if (space.width > adjustedImageWidth) {
      newSpaces.push({
        x: space.x + adjustedImageWidth,
        y: space.y,
        width: space.width - adjustedImageWidth,
        height: adjustedImageHeight,
      });
    }

    // Space below
    if (space.height > adjustedImageHeight) {
      newSpaces.push({
        x: space.x,
        y: space.y + adjustedImageHeight,
        width: space.width,
        height: space.height - adjustedImageHeight,
      });
    }

    return newSpaces;
  }

  images.forEach((image) => {
    let placed = false;
    const imageWidth = image.width * image.scaleX;
    const imageHeight = image.height * image.scaleY;

    for (let i = 0; i < availableSpaces.length; i++) {
      const space = availableSpaces[i];

      // const shouldRotate =
      //   canvasInitWidth / imageHeight > canvasInitWidth / imageWidth;
      // const shouldRotate = shouldRotateImage(
      //   images,
      //   canvasInitWidth,
      //   canvasInitHeight
      // );
      const shouldRotate = shouldRotateImage(
        imageWidth,
        imageHeight,
        canvasInitWidth,
        canvasInitHeight
      );

      if (!shouldRotate) {
        // Check if image fits without rotation
        if (fitsInSpace(space, imageWidth, imageHeight)) {
          positions.push({
            x: space.x + margin, // center the image within the margin
            y: space.y + margin,
            originalWidth: image.width,
            originalHeight: image.height,
            element: image._originalElement,
            scaleX: image.scaleX,
            scaleY: image.scaleY,
            width: imageWidth,
            height: imageHeight,
            rotation: 0,
          });

          availableSpaces = [
            ...availableSpaces.slice(0, i),
            ...splitSpace(space, imageWidth, imageHeight),
            ...availableSpaces.slice(i + 1),
          ];
          placed = true;
          break;
        }
      } else {
        // Check if image fits with 90-degree rotation
        if (fitsInSpace(space, imageHeight, imageWidth)) {
          positions.push({
            x: space.x + margin,
            y: space.y + margin,
            originalWidth: image.width,
            originalHeight: image.height,
            element: image._originalElement,
            scaleX: image.scaleX,
            scaleY: image.scaleY,
            width: imageHeight,
            height: imageWidth,
            rotation: 90,
          });

          availableSpaces = [
            ...availableSpaces.slice(0, i),
            ...splitSpace(space, imageHeight, imageWidth),
            ...availableSpaces.slice(i + 1),
          ];
          placed = true;
          break;
        }
      }
    }

    if (!placed) {
      unplacedImages.push(image);
      console.warn('Image could not be placed:', image);
    }
  });

  return { positions, unplacedImages };
}

function placeImagesV2Modified({
  images,
  margin = 0,
  takeItemScale = false,
  canvasMeasures,
  canvasInitMeasures,
}) {
  const { x: canvasX, y: canvasY } = canvasMeasures;
  const {
    x: canvasInitX,
    y: canvasInitY,
    availableWidth: canvasInitWidth,
    availableHeight: canvasInitHeight,
  } = canvasInitMeasures;

  const positions = [];
  const unplacedImages = [];

  const xDiff = canvasInitX - canvasX;
  const yDiff = canvasInitY - canvasY;

  let availableSpaces = [
    {
      x: xDiff,
      y: yDiff,
      width: canvasInitWidth - margin,
      height: canvasInitHeight - margin,
    },
  ];

  // Determine if we are placing top-to-bottom or left-to-right
  const isTallCanvas = canvasInitHeight > canvasInitWidth;

  // Sort images by their largest dimension (width or height) for better packing
  images.sort((a, b) =>
    takeItemScale
      ? Math.max(b.width * b.scaleX, b.height * b.scaleY) -
        Math.max(a.width * a.scaleX, a.height * a.scaleY)
      : Math.max(b.width, b.height) - Math.max(a.width, a.height)
  );

  function fitsInSpace(space, imageWidth, imageHeight) {
    return (
      space.width >= imageWidth + margin && space.height >= imageHeight + margin
    );
  }

  // Split space according to the layout (top-to-bottom or left-to-right)
  function splitSpace(space, imageWidth, imageHeight) {
    const newSpaces = [];

    // Adjust for margin when splitting space
    const adjustedImageWidth = imageWidth + margin;
    const adjustedImageHeight = imageHeight + margin;

    if (isTallCanvas) {
      // For top-to-bottom placement (vertical split first)
      if (space.height > adjustedImageHeight) {
        newSpaces.push({
          x: space.x,
          y: space.y + adjustedImageHeight, // Shift down
          width: space.width,
          height: space.height - adjustedImageHeight,
        });
      }
      if (space.width > adjustedImageWidth) {
        newSpaces.push({
          x: space.x + adjustedImageWidth,
          y: yDiff, // No vertical shift here
          width: space.width - adjustedImageWidth,
          height: space.height,
        });
      }
    } else {
      // For left-to-right placement (horizontal split first)
      if (space.width > adjustedImageWidth) {
        newSpaces.push({
          x: space.x + adjustedImageWidth, // Shift right
          y: space.y,
          width: space.width - adjustedImageWidth,
          height: adjustedImageHeight,
        });
      }
      if (space.height > adjustedImageHeight) {
        newSpaces.push({
          x: space.x,
          y: space.y + adjustedImageHeight, // Shift down
          width: space.width,
          height: space.height - adjustedImageHeight,
        });
      }
    }

    return newSpaces;
  }

  images.forEach((image) => {
    let placed = false;
    const imageWidth = image.width * image.scaleX;
    const imageHeight = image.height * image.scaleY;

    for (let i = 0; i < availableSpaces.length; i++) {
      const space = availableSpaces[i];

      const shouldRotate = shouldRotateImage(
        imageWidth,
        imageHeight,
        canvasInitWidth,
        canvasInitHeight
      );

      if (!shouldRotate) {
        // Check if image fits without rotation
        if (fitsInSpace(space, imageWidth, imageHeight)) {
          positions.push({
            x: space.x + margin, // center the image within the margin
            y: space.y + margin, // start from top
            originalWidth: image.width,
            originalHeight: image.height,
            element: image._originalElement,
            scaleX: image.scaleX,
            scaleY: image.scaleY,
            width: imageWidth,
            height: imageHeight,
            rotation: 0,
          });

          availableSpaces = [
            ...availableSpaces.slice(0, i),
            ...splitSpace(space, imageWidth, imageHeight),
            ...availableSpaces.slice(i + 1),
          ];
          placed = true;
          break;
        }
      } else {
        // Check if image fits with 90-degree rotation
        if (fitsInSpace(space, imageHeight, imageWidth)) {
          positions.push({
            x: space.x + margin,
            y: space.y + margin,
            originalWidth: image.width,
            originalHeight: image.height,
            element: image._originalElement,
            scaleX: image.scaleX,
            scaleY: image.scaleY,
            width: imageHeight,
            height: imageWidth,
            rotation: 90,
          });

          availableSpaces = [
            ...availableSpaces.slice(0, i),
            ...splitSpace(space, imageHeight, imageWidth),
            ...availableSpaces.slice(i + 1),
          ];
          placed = true;
          break;
        }
      }
    }

    if (!placed) {
      unplacedImages.push(image);
      console.warn('Image could not be placed:', image);
    }
  });

  return { positions, unplacedImages };
}

function binPackingPlaceImages({
  images,
  margin = 0,
  takeItemScale = false,
  canvasMeasures,
  canvasInitMeasures,
}) {
  const { x: canvasX, y: canvasY } = canvasMeasures;
  const {
    x: canvasInitX,
    y: canvasInitY,
    availableWidth: canvasInitWidth,
    availableHeight: canvasInitHeight,
  } = canvasInitMeasures;

  const positions = [];
  const unplacedImages = [];

  const xDiff = canvasInitX - canvasX;
  const yDiff = canvasInitY - canvasY;

  // Start with the entire canvas space as the only available free rectangle
  let freeRectangles = [
    {
      x: xDiff,
      y: yDiff,
      width: canvasInitWidth - margin,
      height: canvasInitHeight - margin,
    },
  ];

  // Sort images by their largest dimension to pack large items first
  images.sort((a, b) =>
    takeItemScale
      ? Math.max(b.width * b.scaleX, b.height * b.scaleY) -
        Math.max(a.width * a.scaleX, a.height * a.scaleY)
      : Math.max(b.width, b.height) - Math.max(a.width, a.height)
  );

  // Helper to check if a rectangle can fit in a free space
  function fitsInSpace(space, imageWidth, imageHeight) {
    return (
      space.width >= imageWidth + margin && space.height >= imageHeight + margin
    );
  }

  // Split the free rectangle into smaller spaces when an image is placed
  function splitFreeRectangles(freeRect, imageRect) {
    const newFreeSpaces = [];

    // Space to the right of the placed image
    if (freeRect.x + freeRect.width > imageRect.x + imageRect.width) {
      newFreeSpaces.push({
        x: imageRect.x + imageRect.width,
        y: freeRect.y,
        width: freeRect.x + freeRect.width - (imageRect.x + imageRect.width),
        height: freeRect.height,
      });
    }

    // Space below the placed image
    if (freeRect.y + freeRect.height > imageRect.y + imageRect.height) {
      newFreeSpaces.push({
        x: freeRect.x,
        y: imageRect.y + imageRect.height,
        width: freeRect.width,
        height: freeRect.y + freeRect.height - (imageRect.y + imageRect.height),
      });
    }

    return newFreeSpaces;
  }

  // Remove free spaces that are completely enclosed by other free spaces
  function pruneFreeSpaces(freeRectangles) {
    return freeRectangles.filter((rect, i) => {
      return !freeRectangles.some((otherRect, j) => {
        return (
          i !== j &&
          otherRect.x <= rect.x &&
          otherRect.y <= rect.y &&
          otherRect.x + otherRect.width >= rect.x + rect.width &&
          otherRect.y + otherRect.height >= rect.y + rect.height
        );
      });
    });
  }

  // Main loop to place images
  images.forEach((image) => {
    const imageWidth = image.width * image.scaleX;
    const imageHeight = image.height * image.scaleY;
    let placed = false;

    for (let i = 0; i < freeRectangles.length; i++) {
      const freeRect = freeRectangles[i];

      // Determine whether to rotate the image
      const shouldRotate = shouldRotateImage(
        imageWidth,
        imageHeight,
        canvasInitWidth,
        canvasInitHeight
      );

      if (!shouldRotate) {
        // Try placing without rotation
        if (fitsInSpace(freeRect, imageWidth, imageHeight)) {
          positions.push({
            x: freeRect.x + margin,
            y: freeRect.y + margin,
            originalWidth: image.width,
            originalHeight: image.height,
            element: image._originalElement,
            scaleX: image.scaleX,
            scaleY: image.scaleY,
            width: imageWidth,
            height: imageHeight,
            rotation: 0,
          });

          // Split the current free rectangle into smaller spaces
          freeRectangles = [
            ...freeRectangles.slice(0, i),
            ...splitFreeRectangles(freeRect, {
              x: freeRect.x,
              y: freeRect.y,
              width: imageWidth + margin,
              height: imageHeight + margin,
            }),
            ...freeRectangles.slice(i + 1),
          ];

          // Remove any redundant free spaces
          freeRectangles = pruneFreeSpaces(freeRectangles);
          placed = true;
          break;
        }
      } else {
        // Try placing with 90-degree rotation
        if (fitsInSpace(freeRect, imageHeight, imageWidth)) {
          positions.push({
            x: freeRect.x + margin,
            y: freeRect.y + margin,
            originalWidth: image.width,
            originalHeight: image.height,
            element: image._originalElement,
            scaleX: image.scaleX,
            scaleY: image.scaleY,
            width: imageHeight,
            height: imageWidth,
            rotation: 90,
          });

          // Split the current free rectangle into smaller spaces
          freeRectangles = [
            ...freeRectangles.slice(0, i),
            ...splitFreeRectangles(freeRect, {
              x: freeRect.x,
              y: freeRect.y,
              width: imageHeight + margin,
              height: imageWidth + margin,
            }),
            ...freeRectangles.slice(i + 1),
          ];

          // Remove any redundant free spaces
          freeRectangles = pruneFreeSpaces(freeRectangles);
          placed = true;
          break;
        }
      }
    }

    if (!placed) {
      unplacedImages.push(image);
      console.warn('Image could not be placed:', image);
    }
  });

  return { positions, unplacedImages };
}

const getZoomAndTransformValues = (canvas) => {
  return {
    zoom: canvas?.getZoom(),
    transform: canvas.viewportTransform,
  };
};

//  #Region Guillotine Bin Packing Algorithm

function placeImageGBP(bin, imageWidth, imageHeight) {
  if (imageWidth > bin.width || imageHeight > bin.height) {
    return null; // Image doesn't fit
  }

  // Calculate leftover space after placing the image
  let leftoverWidth = bin.width - imageWidth;
  let leftoverHeight = bin.height - imageHeight;

  let newBins = [];

  // Guillotine split: choose whether to split vertically or horizontally
  if (leftoverWidth > leftoverHeight) {
    // Split into two bins: right and bottom
    newBins.push({
      x: bin.x + imageWidth,
      y: bin.y,
      width: leftoverWidth,
      height: imageHeight,
    }); // Right bin

    newBins.push({
      x: bin.x,
      y: bin.y + imageHeight,
      width: bin.width,
      height: leftoverHeight,
    }); // Bottom bin
  } else {
    // Split into two bins: bottom and right
    newBins.push({
      x: bin.x,
      y: bin.y + imageHeight,
      width: imageWidth,
      height: leftoverHeight,
    }); // Bottom bin

    newBins.push({
      x: bin.x + imageWidth,
      y: bin.y,
      width: leftoverWidth,
      height: bin.height,
    }); // Right bin
  }

  return newBins; // Return the remaining spaces
}

function addImageGBP(imageWidth, imageHeight, viewPortMeasures) {
  const { availableHeight, availableWidth } = viewPortMeasures;
  let rootBin = {
    x: 0,
    y: 0,
    width: availableHeight, // Canvas width
    height: availableWidth, // Canvas height
  };
  let availableBins = [rootBin];
  for (let i = 0; i < availableBins.length; i++) {
    let bin = availableBins[i];
    let newBins = placeImageGBP(bin, imageWidth, imageHeight);
    if (newBins) {
      // Remove the bin that was just used
      availableBins.splice(i, 1);

      // Add the new bins created from the guillotine split
      availableBins.push(...newBins);
      return { x: bin.x, y: bin.y }; // Return the position where the image was placed
    }
  }
  return null; // No space available
}
// End

const resetTheZoomTransformation = (canvas) => {
  canvas.setViewportTransform([1, 0, 0, 1, 0, 0]); // Resets to default zoom (identity matrix)
  canvas.setZoom(1);
  canvas.renderAll();
};

const generateSheetsFromMaxRectAlgo = async ({
  availableHeight,
  availableWidth,
  allItems,
  autoBuildOptions = {},
  margins,
  payloadFromOutside = false,
  payloadData,
}) => {
  try {
    let payload = payloadData;

    const overSizedImages = [];

    if (!payloadFromOutside) {
      payload = {
        canvasDetails: {
          availableWidth,
          availableHeight,
          margins,
        },
        options: autoBuildOptions,
        objects: allItems
          .filter((itm) => itm.noOfItems > 0)
          .map((itm) => ({
            width: itm.width * itm.scaleX,
            src: itm._originalElement.src,
            height: itm.height * itm.scaleY,
            originalWidth: itm.width,
            originalHeight: itm.height,
            scaleX: itm.scaleX,
            scaleY: itm.scaleY,
            name: itm.name,
            // border: 0,
            noOfItems: parseInt(itm.noOfItems),
          })),
      };
    }

    const response = await imageServices.processWithBinPackerAlgo(payload);

    const validBins = response.data.bins.filter((bin) => {
      const placeableRects = bin.rects.filter((rect) => !rect?.oversized);
      overSizedImages.push(...bin.rects.filter((rect) => rect?.oversized));
      return placeableRects.length > 0;
    });

    const newSheets = validBins.map((bin, idx) => {
      return {
        objects: bin.rects,
        name: `sheet${idx + 1}`,
      };
    });

    return { newSheets, overSizedImages };
  } catch (err) {
    return { newSheets: [], overSizedImages: [], error: true, msg: err };
  }
};

const generateAutoBuildObject = async ({
  initCanvasMeasures,
  selectedOptions,
  sheets = [],
  canvasRef,
  viewPortMeasures,
  getImageElement = () => {},
  onImageAdd = () => {},
  onSingleImageDrawFinish = () => {},
}) => {
  const tempSheetMap = {};

  // sheets.forEach(async (sheet) => {
  for (const sheet of sheets) {
    canvasRef.current.clear();
    // Place images
    // for(sheet of sheets)
    for (const obj of sheet.objects) {
      try {
        const imageElement = getImageElement(obj);
        // const element = console.log(obj.x, obj.y, 'testSheet Objjj');
        await drawSingleImage({
          canvas: canvasRef.current,
          item: { ...obj, ...(obj.rot ? { rotation: 90 } : { rotation: 0 }) },
          viewPortConfig: viewPortMeasures,
          image: imageElement,
          shouldPlaceCenterOnRotate: true,
          //  obj._originalElement,
          takeItemScale: true,
          initCanvasMeasures: initCanvasMeasures,
        });
        onImageAdd();
      } catch (err) {
        console.log(err);
      } finally {
        onSingleImageDrawFinish();
      }
    }

    // sheet.objects.forEach(async (obj) => {
    // });
    // Create sheet
    const canvasJson = JSON.stringify(canvasRef.current.toJSON());
    tempSheetMap[sheet.name] = {
      canvas: canvasJson,
      noOfItems: canvasRef.current?.getObjects()?.length ?? 0,
      selectedSize: selectedOptions.size,
      lastViewPortMeasures: { ...viewPortMeasures },
      lastInitMeasures: { ...initCanvasMeasures },
      canvasOptions: canvasUtils.getZoomAndTransformValues(canvasRef.current),
    };
  }
  // });

  return { tempSheetMap, sheets };
};

async function autoBuildItemsUsingMaxRect({
  marginsInUnit,
  initCanvasMeasures,
  selectedUnit,
  canvasRef,
  allItems,
  autoBuildOptions,
}) {
  const { availableWidth, availableHeight, scale } = initCanvasMeasures;

  const result = {
    hasWarning: false,
    warningDetails: { title: null, message: null },
    maxRectSheets: null,
    hasError: false,
    error: '',
    showPreview: true,
  };

  const { artBoardMargin, imageMargin } = marginsInUnit;
  const margins = {
    artBoardMargin:
      (artBoardMargin * canvasConstants.CANVAS_DPI * scale) /
      selectedUnit.value,
    imageMargin:
      (imageMargin * canvasConstants.CANVAS_DPI * scale) / selectedUnit.value,
  };
  canvasUtils.resetTheZoomTransformation(canvasRef.current);
  // setViewPortMeasures(initCanvasMeasures);

  // setIsAutoBuilding(true);
  const { newSheets: maxRectSheets, overSizedImages } =
    await canvasUtils.generateSheetsFromMaxRectAlgo({
      availableHeight: availableHeight,
      availableWidth: availableWidth,
      allItems,
      autoBuildOptions,
      margins,
    });

  if (overSizedImages.length > 0) {
    result.hasWarning = true;
    result.warningDetails.message = `${overSizedImages.length} oversized image(s) found. They will be ignored.`;
    result.warningDetails.title = 'Oversized Images Detected';
  }

  if (!maxRectSheets || maxRectSheets.length <= 0) {
    result.hasError = true;
    result.error = 'No sheets produced';
    return result;
  }

  // If sheet length is 1, we can directly apply to the canvas
  if (maxRectSheets.length <= 1) {
    result.maxRectSheets = maxRectSheets;
    result.showPreview = false;
    return result;
  }

  result.maxRectSheets = maxRectSheets;
  return result;
}

const addAutoBuildItemsToCanvas = async ({
  sheets,
  addedImageElements,
  canvasRef,
  initCanvasMeasures,
  selectedOptions,
}) => {
  // setIsPageLoading(true);
  const { sheets: macRectFInalSheets, tempSheetMap } =
    await canvasUtils.generateAutoBuildObject({
      canvasRef,
      initCanvasMeasures,
      selectedOptions,
      viewPortMeasures: initCanvasMeasures, //We have reset viewPortMeasure to initCanvasMeasure above
      sheets: sheets,
      getImageElement: (obj) =>
        addedImageElements.find(
          (item) => item?._originalElement.src === obj.src
        )?._originalElement,
    });
  // setIsInAutoBuildPreview(false);
  // setSheetMap(tempSheetMap);
  const hasMultiplePage = Object.keys(tempSheetMap).length > 1;
  const sourceSheetName =
    macRectFInalSheets[macRectFInalSheets.length - 1].name;
  const targetSheetName = macRectFInalSheets[0].name;
  // if (hasMultiplePage) {
  //   switchSheet(sourceSheetName, targetSheetName, tempSheetMap);
  // } else {
  //   setCurrentSheetName(targetSheetName);
  // }
  // Switch to first page
  // setIsPageLoading(false);
  return { tempSheetMap, hasMultiplePage, sourceSheetName, targetSheetName };
};

const hasObjectsInCanvas = (canvas, sheetMap, currentSheetName = '') => {
  if (!canvas) {
    return {
      hasObjects: false,
      canvasObjectLength: 0,
      sheetMapObjectLength: 0,
    };
  }

  const objects = canvas.getObjects() ?? [];

  const sheetObjects =
    Object.entries(sheetMap)
      .map(([key, value]) => ({ ...value, sheetName: key }))
      .filter(
        (item) => item.noOfItems > 0 && item.sheetName !== currentSheetName
      )
      .map((item) => JSON.parse(item.canvas).objects) ?? [];

  const hasObjects = objects?.length > 0 || sheetObjects?.length > 0;
  console.log(hasObjects, objects.length, 'TESTSHEET');
  console.log(objects.length, hasObjects, 'SHEETMAP', sheetObjects.length);
  return {
    hasObjects,
    canvasObjectLength: objects.length ?? 0,
    sheetMapObjectLength: sheetObjects.length ?? 0,
  };
};

const generateUniqueNameFromFileData = (file) => {
  let uniqueName = '';
  const nameSplit = file.name.split('.');

  uniqueName += nameSplit[0];
  if (nameSplit.length > 1) {
    uniqueName += nameSplit.pop();
  }
  uniqueName += file.size;

  return uniqueName;
};

export const canvasUtils = {
  findViewPortMeasures,
  convertInchesToPixels,
  findViewPortDimension,
  drawImageOnViewPort,
  preloadImages,
  drawSingleImage,
  generateIdForAList,
  generateNextIdForMap,
  calculateItemPositionAndSize,
  removeActiveObjectFromCanvas,
  drawTextOnViewPort,
  generateSelectionItemsForAutoBuild,
  placeItems,
  autoBuildImages,
  checkForObjectOverlap,
  snapToGrid,
  generateNextSheetName,
  generateDownloadCanvas,
  generateDownloadableCanvasForSvg,
  autoBuildImagesV2,
  placeImagesV2,
  fetchAndResizeSVG,
  getUpdatedSvgElement,
  getZoomAndTransformValues,
  addImageGBP,
  getImageItemCount,
  resetTheZoomTransformation,
  generateSheetsFromMaxRectAlgo,
  generateAutoBuildObject,
  autoBuildItemsUsingMaxRect,
  addAutoBuildItemsToCanvas,
  generatePlainObject,
  hasObjectsInCanvas,
  generateUniqueNameFromFileData,
};
