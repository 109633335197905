import {
  LockKeyhole,
  LockKeyholeOpen,
  RotateCw,
  SquareMousePointer,
  Upload,
  X,
} from 'lucide-react';
import React, { useState } from 'react';
import AlertMessagePopUp from '../Component/AlertMessagePopUp';
import IconButton from '../Component/IconButton';
import { apiConstants } from '../Constants/apiurl.constant';
import { useFileUploadMutation } from '../Hooks/useFileUploadMutation';
import { imageServices } from '../service/image.service';
import { isSvgUrl } from '../Utils/UtilFunctions';
import { canvasConstants, canvasUtils } from './canvasUtils';
import { TextIcon, UploadIcon } from './Icons';

function SelectionAreaV2({
  selectedUploadImages = [],
  setSelectUploadImages = () => {},
  onUploadFinish = () => {},
  isInAutoBuild = false,
  triggerRender,
  onImageUploadSelection = () => {},
  onImageClick = () => {},
  initCanvasSize,
  canvas,
  selectedMarginInInches = 0,
  currentSheetName,
  sheetMap,
  selectedOptions,
  // activeItem,
  imgRefs = [],
  selectedUnit,
}) {
  const { progressMap, createSignedUrlAndUploadFile, isError } =
    useFileUploadMutation();
  const [errorObject, setErrorObject] = useState({
    message: null,
    title: null,
    isVisible: false,
  });
  const [addingImageDetails, setAddingImageDetails] = useState({
    isAdding: false,
    addingIndex: null,
  });

  // const preloadedImages = useRef({});
  const activeItem = canvas?.getActiveObject();

  const scale = initCanvasSize.scale;

  const addImageDataToLocalStorage = (file) => {
    const key = 'uploadedImages';
    const {
      isUploading,
      lastModified,
      fileName,
      name,
      ratioUnlocked,
      size,
      type,
      originalUrl,
      width,
      height,
      src,
      isSvg,
      isAddedImage,
      isEdited,
      isFailed,
    } = file;
    const updatedFile = {
      isUploading,
      lastModified,
      name,
      ratioUnlocked,
      fileName,
      size,
      type,
      originalUrl,
      width,
      height,
      src,
      isSvg,
      isAddedImage,
      isEdited,
      isFailed,
    };

    const cleanFileName = canvasUtils.generateUniqueNameFromFileData(file);

    if (!cleanFileName) {
      return;
    }

    const previousItem = localStorage.getItem(key)
      ? JSON.parse(localStorage.getItem(key))
      : {};
    const updatedItems = {
      ...previousItem,
      ...{ [cleanFileName]: { ...updatedFile } },
    };
    console.log(updatedItems, 'UPDATED_ITEMS');

    localStorage.setItem(key, JSON.stringify(updatedItems));
  };

  const handleImageAddToViewPort = async ({
    imageProps,
    imageElement,
    allCanvasObjects,
    shouldDiscardActiveObject = true,
    takeItemScale = false,
    isAdd = false,
  }) => {
    if (shouldDiscardActiveObject) {
      // Discarding the selection, otherwise it will be added on top of the image
      canvas?.discardActiveObject();
      canvas.renderAll();
    }
    // console.log(imageProps, 'props', imageElement);
    // const isSvg = imageElement?.src?.endsWith?.('.svg');

    imageProps.x = 0;
    imageProps.y = 0;

    // imageProps.isSvg = isSvg ?? false;

    const { x: newX = null, y: newY = null } =
      canvasUtils.calculateItemPositionAndSize(
        imageProps,
        {
          ...initCanvasSize,
          x: initCanvasSize.x,
          y: initCanvasSize.y,
          availableWidth: initCanvasSize.availableWidth - 5, //Adding a safe margin on viewport
          availableHeight: initCanvasSize.availableHeight - 5, //Adding a safe margin on viewport
          margin:
            selectedMarginInInches *
            canvasConstants.CANVAS_DPI *
            initCanvasSize.scale, //Adding a safe margin on viewport
        },
        allCanvasObjects
      );

    if (!newX && !newY && allCanvasObjects.length > 0) {
      setErrorObject({
        isVisible: true,
        title: 'Unable to Place Image',
        message:
          'The selected image could not be placed on the canvas due to size constraints or format issues. Please try a different image or adjust the canvas settings',
      });
      return;
    }

    if (newX) {
      imageProps.x = newX;
    }

    if (newY) {
      imageProps.y = newY;
    }

    if (imageProps.width && imageProps.height) {
      imageProps.ratio = imageProps.width / imageProps.height;
    }

    const imageItem = {
      type: 'image',
      ...imageProps,
      dpi: canvasConstants.CANVAS_DPI,
    };

    imageElement.crossOrigin = 'Anonymous';
    await canvasUtils.drawSingleImage({
      canvas,
      item: imageItem,
      image: imageElement,
      viewPortConfig: initCanvasSize,
      takeItemScale: takeItemScale,
      initCanvasMeasures: initCanvasSize,
      isAdd: isAdd,
    });
    canvas?.renderAll();
    return imageItem;
  };
  /***
   * Function to load the image
   * @param fileName - The name of the file
   * @returns {Promise<{src: string,originalUrl: string, isSvg:boolean, isUploading: boolean, _originalElement: any, isAddedImage: boolean, isEdited: boolean, isFailed: boolean, width: number,height:number, scaleX: number, scaleY: number, usedScale: number}>}
   */
  function loadImage(fileName) {
    return new Promise((resolve, reject) => {
      const imageElement = document.createElement('img');
      imageElement.src =
        apiConstants.imgUrlS3 + fileName + '?not-from-cache-please';

      imageElement.onload = () => {
        const width = imageElement.width;
        const height = imageElement.height;

        resolve({
          src: apiConstants.imgUrlS3 + fileName + '?not-from-cache-please',
          originalUrl: apiConstants.imgUrlS3 + fileName,
          isSvg: fileName?.endsWith('svg') ?? false,
          isUploading: false,
          _originalElement: imageElement,
          isAddedImage: true,
          isEdited: false,
          isFailed: false,
          width,
          height,
          scaleX: initCanvasSize.scale,
          scaleY: initCanvasSize.scale,
          usedScale: initCanvasSize.scale,
        });
      };

      imageElement.onerror = () => {
        reject({
          src: apiConstants.imgUrlS3 + fileName + '?not-from-cache-please',
          originalUrl: apiConstants.imgUrlS3 + fileName,
          isSvg: fileName?.endsWith('svg') ?? false,
          isUploading: false,
          isAddedImage: true,
          isEdited: false,
          isFailed: true, // Mark as failed when image can't be loaded
          scaleX: initCanvasSize.scale,
          scaleY: initCanvasSize.scale,
          usedScale: initCanvasSize.scale,
        });
      };
    });
  }

  const uploadFileExcludingEps = async (filesWithoutEps, files) => {
    // const fileWithUrl = {};
    for (const file of filesWithoutEps) {
      try {
        const { fileName, fileObj } = await createSignedUrlAndUploadFile(
          file.file,
          file.index
        );

        const response = await imageServices.addUploadedImages({
          name: fileName,
          type: fileObj.extension,
          size: fileObj.size,
        });

        // fileWithUrl[file.name] = { ...file, fileName };

        const data = await loadImage(fileName);

        // const {
        //   isAddedImage,
        //   isEdited,
        //   isFailed,
        //   isSvg,
        //   width,
        //   height,
        //   originalUrl,
        //   src,
        // } = data;

        // addImageDataToLocalStorage({
        //   ...file,
        //   width,
        //   height,
        //   fileName,
        //   originalUrl,
        //   src,
        //   isSvg,
        //   isAddedImage,
        //   isEdited,
        //   isFailed,
        //   isUploading: false,
        // });

        setSelectUploadImages((prev) =>
          prev.map((item, idx) => {
            if (idx === file.index) {
              return {
                ...item,
                ...data,
              };
            }
            return item;
          })
        );
      } catch (errorData) {
        console.log(errorData, 'ERROR UP');

        if (errorData?.isFailed) {
          setSelectUploadImages((prev) =>
            prev.map((item, idx) => {
              if (idx === file.index) {
                return {
                  ...item,
                  ...errorData,
                };
              }
              return item;
            })
          );
        }
      }
    }
  };

  const preloadImages = (data) => {
    const preloadPromises = data.map((item) => {
      return new Promise((resolve, reject) => {
        const imageElement = document.createElement('img');
        imageElement.src =
          apiConstants.imgUrlS3 + item.name + '?not-from-cache-please';

        imageElement.onload = () => {
          const width = imageElement.width;
          const height = imageElement.height;
          // preloadImage[item.name] = {
          //   _originalElement: imageElement,
          //   width,
          //   height,
          // };
          resolve({
            _originalElement: imageElement,
            width,
            height,
          });
        };

        imageElement.onerror = (error) => {
          reject(`Error loading image: ${item.name}`);
        };
      });
    });
    return Promise.all(preloadPromises);
  };

  const uploadEpsFilesV2 = async (epsFiles) => {
    // const fileWithUrl = {};
    for (const file of epsFiles) {
      try {
        const response = await imageServices.convertEpsToSvg([file.file]);
        const respData = response.data.files[0];
        const fileName = respData.name;
        const imageData = await loadImage(fileName);

        // const {
        //   isAddedImage,
        //   isEdited,
        //   isFailed,
        //   isSvg,
        //   isUploading,
        //   width,
        //   height,
        //   originalUrl,
        //   src,
        // } = imageData;

        // addImageDataToLocalStorage({
        //   ...file,
        //   fileName,
        //   isAddedImage,
        //   isEdited,
        //   isFailed,
        //   isSvg,
        //   isUploading,
        //   width,
        //   height,
        //   originalUrl,
        //   src,
        // });

        setSelectUploadImages((prev) =>
          prev.map((item, idx) => {
            if (idx === file.index) {
              return {
                ...item,
                ...imageData,
              };
            }
            return item;
          })
        );
      } catch (errData) {
        if (errData?.isFailed) {
          setSelectUploadImages((prev) =>
            prev.map((item, idx) => {
              if (idx === file.index) {
                return {
                  ...item,
                  ...errData,
                };
              }
              return item;
            })
          );
        }
      }
    }
    // console.log(fileWithUrl, 'FILEWITHURL');

    // canvasUtils.addImageDataToLocalStorage(fileWithUrl);
  };

  const uploadEpsFiles = async (epsFilesData) => {
    const files = epsFilesData.map((file) => file.file);

    try {
      if (files.length > 0) {
        const response = await imageServices.convertEpsToSvg(files);

        if (!response?.data?.files) {
          return;
        }

        const data = response.data.files;

        const preloadedImages = await preloadImages(data);

        setSelectUploadImages((prev) =>
          prev.map((item, idx) => {
            const epsFileIndex = epsFilesData.findIndex(
              (file) => file.index === idx
            );

            if (epsFileIndex !== -1) {
              const requiredObj = preloadedImages[epsFileIndex];
              return {
                ...item,
                src:
                  apiConstants.imgUrlS3 +
                  data[epsFileIndex].name +
                  '?not-from-cache-please',
                isSvg: data?.[epsFileIndex].name?.endsWith('svg') ?? false,
                isUploading: false,
                isAddedImage: true,
                preloadedImage: requiredObj,
                isFailed: false,
                scaleX: initCanvasSize.scale,
                scaleY: initCanvasSize.scale,
                usedScale: initCanvasSize.scale,
                ...requiredObj,
              };
            }
            return item;
          })
        );
      }
    } catch (error) {
      setSelectUploadImages((prev) =>
        prev.map((item, idx) => {
          const epsFileIndex = epsFilesData.findIndex(
            (file) => file.index === idx
          );
          if (epsFileIndex !== -1) {
            return {
              ...item,
              isUploading: false,
            };
          }
          return item;
        })
      );
      console.log(error, 'error');
    }
  };

  const isLoading = selectedUploadImages
    .filter((item) => !item?.isEdited || !item?.isFailed)
    .some((item) => item.isUploading);

  function clearFileInput() {
    const fileInput = document.getElementById('gs-file-upload');
    fileInput.value = '';
  }

  const checkFileFromLocalStorage = (file) => {
    const key = 'uploadedImages';
    const fileName = canvasUtils.generateUniqueNameFromFileData(file);
    const fileMap = localStorage.getItem(key)
      ? JSON.parse(localStorage.getItem(key))
      : null;
    if (!fileMap) {
      return { found: false };
    }

    const requiredFile = fileMap[fileName];

    if (!requiredFile) {
      return { found: false };
    }

    const isSizeSame = file.size === requiredFile.size;

    if (!isSizeSame) {
      return { found: false };
    }

    return {
      found: true,
      file: {
        ...requiredFile,
        isSuccess: true,
        isFailed: false,
      },
    };
  };

  return (
    <div
      className="px-3 py-4 d-flex flex-column"
      style={{
        backgroundColor: 'rgb(245, 245, 245)',
        borderRight: '1px solid #cfcfcfad',
        maxHeight: 'calc(100vh - 55px)',
        overflow: 'auto',
      }}
    >
      <div style={{ flex: 1 }}>
        <div className="mt-2 d-flex justify-content-center">
          <IconButton
            id="gs-file-upload"
            label={'Upload Image(s)'}
            icon={<UploadIcon size="18" color="black" />}
            subtext="png, jpeg, svg, eps"
          />

          <input
            type="file"
            accept=".jpg, .jpeg, .png, .eps, .svg"
            id="gs-file-upload"
            hidden
            multiple
            onChange={async (event) => {
              // setIsInUpload(true);
              const initialIndex = selectedUploadImages.length;
              const files = Array.from(event.target.files).map(
                (file, index) => {
                  // const { found, file: requiredFile } =
                  //   checkFileFromLocalStorage(file);
                  // if (found) {
                  //   // const imageData = await loadImage(file.fileName);
                  //   const imageElement = document.createElement('img');
                  //   imageElement.src =
                  //     apiConstants.imgUrlS3 +
                  //     requiredFile.fileName +
                  //     '?not-from-cache-please';
                  //   return {
                  //     ...requiredFile,
                  //     index: initialIndex + index,
                  //     _originalElement: imageElement,
                  //     scaleX: initCanvasSize.scale,
                  //     scaleY: initCanvasSize.scale,
                  //     usedScale: initCanvasSize.scale,
                  //   };
                  // }
                  return {
                    name: file.name,
                    size: file.size,
                    type: file.type,
                    lastModified: file.lastModified,
                    src: URL.createObjectURL(file),
                    file,
                    _originalElement: event.target,
                    isUploading: true,
                    isSuccess: false,
                    isFailed: false,
                    ratioUnlocked: false,
                    index: initialIndex + index,
                  };
                }
              );

              onImageUploadSelection(files);

              console.log(files, 'FILESSS');

              const fileDataWithoutEps = files.filter(
                (file) =>
                  !file.isSuccess && !file.name.toLowerCase().endsWith('.eps')
              );

              const epsFileData = files.filter(
                (file) =>
                  !file.isSuccess && file.name.toLowerCase().endsWith('.eps')
              );
              try {
                // UPLOAD EPS FILES
                await uploadEpsFilesV2(epsFileData, files);

                // UPLOAD FILE WITHOUT EPS
                await uploadFileExcludingEps(fileDataWithoutEps, files);
              } catch (err) {
                console.log(err, 'ERROR UP');

                setErrorObject({
                  isVisible: true,
                  message: 'Something went wrong while uploading',
                  title: 'Upload Error',
                });
              }

              onUploadFinish();

              clearFileInput();
            }}
          />
        </div>

        <div className="mt-2 d-flex justify-content-center">
          <IconButton
            label={'Add Text'}
            subtext="Add text to the viewport"
            icon={<TextIcon size={40} />}
            onClick={() =>
              canvasUtils.drawTextOnViewPort({
                canvas: canvas,
                viewPortConfig: initCanvasSize,
              })
            }
          />
        </div>
        {/* (<div>Selected Images</div>) */}
        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(auto-fill, minmax(100px,1fr))',
            marginTop: 30,
            gridGap: '5px',
          }}
        >
          {selectedUploadImages?.map((item, index) => {
            const isEps = item.name.toLowerCase()?.endsWith('.eps');
            const isEpsUploading = isEps && item.isUploading;
            const itemCount = canvasUtils.getImageItemCount({
              srcToCompare: imgRefs.current?.[index]?.src,
              sheetMap,
              canvas: canvas,
              currentSheetName: currentSheetName,
            });

            return (
              <div
                key={index}
                role="button"
                style={{
                  position: 'relative',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  background: '#fff',
                  borderRadius: '10px',
                  cursor: isInAutoBuild ? 'default' : 'pointer',
                  backgroundImage:
                    'conic-gradient( white 0deg, white 90deg, #ccc 90deg, #ccc 180deg, white 180deg, white 270deg, #ccc 270deg )',
                  backgroundSize: '10px 10px',
                }}
                onClick={async () => {
                  if (isInAutoBuild) {
                    return;
                  }

                  if (
                    item.isUploading ||
                    progressMap?.[index] ||
                    addingImageDetails.isAdding
                  ) {
                    return;
                  }
                  const imageElement = item._originalElement;
                  const imageProps = { ...item, isLoaded: false };
                  if (imageElement) {
                    // check for scale change.
                    const scaleRatio = scale / imageProps.usedScale;

                    imageProps.scaleX *= scaleRatio;
                    imageProps.scaleY *= scaleRatio;

                    // For svg  * (300 / 96)
                    imageProps.width =
                      imageElement.naturalWidth * (imageProps.scaleX ?? scale);
                    imageProps.height =
                      imageElement.naturalHeight * (imageProps.scaleY ?? scale);
                    imageProps.initScale = scale;
                    imageProps.originalWidth = imageElement.naturalWidth;
                    imageProps.originalHeight = imageElement.naturalHeight;
                  }
                  imageProps.x = 0;
                  imageProps.y = 0;
                  const [...allCanvasObjects] = canvas.getObjects();
                  setAddingImageDetails({
                    addingIndex: index,
                    isAdding: true,
                  });
                  try {
                    const imageItem = await handleImageAddToViewPort({
                      imageProps,
                      imageElement: imageElement.cloneNode(true),
                      allCanvasObjects,
                      isAdd: true,
                      takeItemScale: true,
                    });
                    setAddingImageDetails({
                      addingIndex: null,
                      isAdding: false,
                    });

                    if (!imageItem) {
                      return;
                    }

                    imageItem.file = item.file;
                  } catch (err) {
                    setErrorObject({
                      isVisible: true,
                      message: `Error: ${err?.message}`,
                      title: 'Error',
                    });
                    console.log(err);
                  } finally {
                    setAddingImageDetails({
                      addingIndex: null,
                      isAdding: false,
                    });
                  }
                }}
              >
                <div
                  role="button"
                  onClick={(e) => {
                    e.stopPropagation();
                    setSelectUploadImages((prev) =>
                      prev.filter((item, idx) => index !== idx)
                    );
                    canvas.discardActiveObject();
                    canvas.renderAll();
                  }}
                  style={{
                    position: 'absolute',
                    backgroundColor: 'black',
                    aspectRatio: 1,
                    display: 'flex',
                    borderRadius: 10,
                    padding: 2,
                    top: 0,
                    right: 0,
                  }}
                >
                  <X size={13} color="#FFFFFF" />
                </div>
                {isEpsUploading ? (
                  <div
                    className="d-flex align-items-center justify-content-center"
                    style={{ width: 100, height: 100 }}
                  >
                    <Upload size={60} />
                  </div>
                ) : (
                  <img
                    ref={(el) => {
                      imgRefs.current[index] = el;
                    }}
                    src={item.src}
                    style={{ objectFit: 'contain' }}
                    width={100}
                    height={100}
                    alt=""
                  />
                )}
                {addingImageDetails.isAdding &&
                  index === addingImageDetails.addingIndex && (
                    <>
                      <div
                        style={{
                          position: 'absolute',
                          inset: 0,
                          backgroundColor: 'rgba(0,0,0,0.4)',
                          color: 'white',
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          fontWeight: 600,
                          fontSize: 14,
                        }}
                      >
                        Adding...
                      </div>
                    </>
                  )}
                {item.isFailed ? (
                  <div
                    style={{
                      position: 'absolute',
                      inset: 0,
                      backgroundColor: 'rgba(0,0,0,0.4)',
                      color: 'white',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontWeight: 600,
                      fontSize: 14,
                      flexDirection: 'column',
                    }}
                    role="button"
                    onClick={() => {
                      setSelectUploadImages((prev) =>
                        prev.map((itm, idx) => {
                          if (itm.index === index)
                            return {
                              ...item,
                              isFailed: false,
                              isUploading: true,
                            };
                          return itm;
                        })
                      );
                      uploadEpsFiles([item]);
                    }}
                  >
                    <div>Failed</div>
                    <div
                      className="failed-retry"
                      style={{
                        background: 'rgba(0,0,0,0.5)',
                        padding: '5px',
                        display: 'flex',
                      }}
                    >
                      <RotateCw color={'#FFF'} size={16} />
                    </div>
                  </div>
                ) : null}
                {(progressMap?.[index] ||
                  (item.isUploading && !item.isFailed)) && (
                  <div
                    style={{
                      position: 'absolute',
                      inset: 0,
                      backgroundColor: 'rgba(0,0,0,0.4)',
                      color: 'white',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      fontWeight: 600,
                      fontSize: 14,
                    }}
                  >
                    {progressMap?.[index] ? progressMap[index] : 'Uploading..'}
                  </div>
                )}
                <div className="selected-image-count">{itemCount}</div>
              </div>
            );
          })}
        </div>
      </div>

      {selectedUploadImages.length > 0 && (
        <div
          style={{
            width: '100%',
            height: '1px',
            backgroundColor: '#cccccc73',
            margin: '20px 0px',
          }}
        />
      )}

      {/* Selected image edit */}
      <SelectedImageOptionWrapper
        hasItem={selectedUploadImages.length > 0 ?? false}
        activeItem={!isInAutoBuild && activeItem}
      >
        <SelectedImageOptions
          activeItem={activeItem}
          canvas={canvas}
          triggerRender={triggerRender}
          initCanvasSize={initCanvasSize}
          handleImageAddToViewPort={handleImageAddToViewPort}
          selectedUnit={selectedUnit}
        />
      </SelectedImageOptionWrapper>

      {/* {isInUpload && (
        <div
          style={{
            position: 'fixed',
            inset: '0px',
            height: '100vh',
            backgroundColor: 'rgba(0,0,0,0.5)',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: '93px',
            zIndex: 999999,
            justifyContent: 'center',
            width: '100vw',
          }}
        >
          <div
            className=" w-fit h-fit "
            style={{
              background: 'white',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
              padding: '10px',
            }}
          >
            <div className={`d-flex ${isLoading ? 'm-auto' : ''}`}>
              <EditableImageList
                isLoading={isLoading}
                allItems={selectedUploadImages.filter((item) => !item.isEdited)}
                selectedUnit={selectedUnit}
                viewportMeasures={initCanvasSize}
                isPlaceable={false}
                onHeightUpdate={(event, it, index) => {
                  const { value } = event.target;

                  // if (!item.isRatioLocked) activeItem.scaleX = requiredScale;

                  setSelectUploadImages((prev) =>
                    prev.map((item, idx) => {
                      if (idx === it.index) {
                        const requiredScale =
                          (value *
                            (canvasConstants.CANVAS_DPI *
                              initCanvasSize.scale)) /
                          (item.height * (selectedUnit.value ?? 1));

                        const aspectRatio = item.scaleX / item.scaleY;
                        const newScaleX = item.ratioUnlocked
                          ? item.scaleX
                          : aspectRatio * requiredScale;

                        return {
                          ...item,
                          scaleY: requiredScale,
                          scaleX: newScaleX,
                        };
                      }
                      return { ...item };
                    })
                  );
                }}
                onWidthUpdate={(event, itm, index) => {
                  const { value } = event.target;
                  setSelectUploadImages((prev) =>
                    prev.map((item, idx) => {
                      if (idx === itm.index) {
                        const requiredScale =
                          (value *
                            (canvasConstants.CANVAS_DPI *
                              initCanvasSize.scale)) /
                          (item.width * (selectedUnit.value ?? 1));

                        const aspectRatio = item.scaleX / item.scaleY;
                        const newScaleY = item.ratioUnlocked
                          ? item.scaleY
                          : aspectRatio * requiredScale;

                        return {
                          ...item,
                          scaleX: requiredScale,
                          scaleY: newScaleY,
                        };
                      }

                      return { ...item };
                    })
                  );
                }}
                onAspectRatioToggle={(idx) => {
                  setSelectUploadImages((prev) =>
                    prev.map((itm, index) => {
                      if (index === idx) {
                        return {
                          ...itm,
                          ratioUnlocked: !itm.ratioUnlocked,
                        };
                      }
                      return itm;
                    })
                  );
                }}
                updateSelectedItem={setSelectUploadImages}
              />
            </div>
            <button
              className="default-btn mt-3"
              onClick={async () => {
                setSelectUploadImages((prev) =>
                  prev.map((item) => ({ ...item, isEdited: true }))
                );

                // Auto-build with the selection
                const result = await canvasUtils.autoBuildItemsUsingMaxRect({
                  canvasRef: { current: canvas },
                  allItems: selectedUploadImages,
                  initCanvasMeasures: initCanvasSize,
                  autoBuildOptions: '',
                  marginsInUnit: '',
                  selectedUnit: selectedUnit,
                });

                if (result.hasError) {
                  //TODO: Set error
                  return;
                }

                if (result.hasWarning) {
                  // TODO: Show Warning, but should continue to next step
                }

                if (!result.showPreview) {
                  // TODO:Add items to canvas and close modal
                }

                const addedSheetDetails = canvasUtils.addAutoBuildItemsToCanvas(
                  {
                    addedImageElements: '',
                    canvasRef: { current: canvas },
                    initCanvasMeasures: initCanvasSize,
                    // selectedOptions:selected
                    selectedOptions,
                    sheets: result.maxRectSheets,
                  }
                );

                // Open preview

                setIsInUpload(false);
              }}
            >
              Confirm
            </button>
          </div>
        </div>
      )} */}
      <AlertMessagePopUp
        isVisible={errorObject.isVisible}
        message={errorObject.message}
        title={errorObject.title}
        onClose={() => {
          setErrorObject({
            message: null,
            isVisible: false,
            title: null,
          });
        }}
      />
    </div>
  );
}

const SelectedImageOptionWrapper = ({ hasItem, activeItem, children }) => {
  // if (!hasItem) {
  //   return null;
  // }

  if (!activeItem) {
    return (
      <div
        className="d-flex justify-content-center align-items-center"
        style={{ flex: 1 }}
      >
        <div className="text-center">
          <SquareMousePointer size={40} color="grey" />
          <div className="mt-2" style={{ color: 'grey' }}>
            Select an object to edit
          </div>
        </div>
      </div>
    );
  }

  return children;
};

const SelectedImageOptions = ({
  activeItem,
  canvas,
  triggerRender,
  initCanvasSize,
  handleImageAddToViewPort,
  selectedUnit,
  hasItem,
}) => {
  const [quantity, setQuantity] = useState(1);

  const isMlControllerVisible = () => {
    const activeObject = activeItem;
    if (activeObject) {
      return activeObject?.controlsVisibility?.ml ?? false;
    }
    return false;
  };

  const [isAspectRatioLocked, setIsAspectRatioLocked] = useState(
    isMlControllerVisible()
  );

  const handleDuplication = async () => {
    const isSvg = isSvgUrl(activeItem._originalElement.src);
    const adjustedOffset = isSvg ? 60 / 300 : 1;

    const imageProps = {
      width: activeItem.getScaledWidth() * adjustedOffset,
      scaleX: activeItem.scaleX,
      scaleY: activeItem.scaleY,
      height: activeItem.getScaledHeight() * adjustedOffset,
    };

    // if (quantity > 1) {
    for (let i = 0; i < quantity; i++) {
      const allCanvasItems = canvas.getObjects();
      const clonedElement = activeItem._originalElement.cloneNode(true);
      await handleImageAddToViewPort({
        imageElement: clonedElement,
        imageProps,
        allCanvasObjects: allCanvasItems,
        shouldDiscardActiveObject: false,
        takeItemScale: true,
      });
    }
    // } else {
    //   handleImageAddToViewPort({
    //     imageElement: activeItem._originalElement,
    //     imageProps,
    //     allCanvasObjects: allCanvasItems,
    //     shouldDiscardActiveObject: false,
    //     takeItemScale: true,
    //   });
    // }
    triggerRender();
  };

  return (
    <div style={{ flex: 1 }}>
      <div className="row">
        <div className="col-6">
          <label>Width ({selectedUnit.unit}): </label>
        </div>
        <div className="col-6">
          <input
            type="number"
            className="w-100"
            min={0}
            step={selectedUnit.value === 1 ? 0.2 : 1}
            value={(
              ((activeItem?.width * activeItem?.scaleX) /
                (canvasConstants.CANVAS_DPI * initCanvasSize.scale)) *
              selectedUnit.value
            ).toFixed(2)}
            onChange={(event) => {
              const { value } = event.target;
              const requiredScale =
                (value * (canvasConstants.CANVAS_DPI * initCanvasSize.scale)) /
                (activeItem.width * (selectedUnit.value ?? 1));

              activeItem.scaleX = requiredScale;
              if (!isAspectRatioLocked) activeItem.scaleY = requiredScale;
              activeItem.setCoords();
              triggerRender();
            }}
          />
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-6">
          <label>Height ({selectedUnit.unit}): </label>
        </div>
        <div className="col-6">
          <input
            type="number"
            min={0}
            step={selectedUnit.value === 1 ? 0.2 : 1}
            className="w-100"
            onChange={(event) => {
              const { value } = event.target;
              const requiredScale =
                (value * (canvasConstants.CANVAS_DPI * initCanvasSize.scale)) /
                (activeItem.height * (selectedUnit.value ?? 1));

              if (!isAspectRatioLocked) activeItem.scaleX = requiredScale;
              activeItem.scaleY = requiredScale;
              activeItem.setCoords();
              triggerRender();
              canvasUtils.checkForObjectOverlap(canvas, activeItem);
            }}
            // onChange}
            value={(
              ((activeItem?.height * activeItem?.scaleY) /
                (canvasConstants.CANVAS_DPI * initCanvasSize.scale)) *
              selectedUnit.value
            ).toFixed(2)}
          />
        </div>
      </div>

      {/* Aspect Ratio */}
      <div className="row mt-2">
        <div className="col-6">
          <label>Aspect Ratio: </label>
        </div>
        <div className="col-6">
          <input
            type="number"
            disabled
            min={0}
            step={0.05}
            className="w-100"
            onChange={(event) => {
              const { value } = event.target;
              const requiredScale =
                (value * (canvasConstants.CANVAS_DPI * initCanvasSize.scale)) /
                activeItem.height;

              activeItem.scaleX = requiredScale;
              activeItem.scaleY = requiredScale;
              activeItem.setCoords();
              triggerRender();
              canvasUtils.checkForObjectOverlap(canvas, activeItem);
            }}
            // onChange}
            value={(
              (activeItem.width * activeItem?.scaleX) /
              (activeItem.height * activeItem?.scaleY)
            ).toFixed(2)}
          />

          <div
            role="button"
            className="my-2 d-flex align-items-center justify-content-end"
            onClick={() => {
              setIsAspectRatioLocked((prev) => !prev);
              if (!isAspectRatioLocked) {
                activeItem.setControlsVisibility({
                  mt: true, // middle top
                  mb: true, // middle bottom
                  ml: true, // middle left
                  mr: true, // middle right
                  bl: true, // bottom left
                  br: true, // bottom right
                  tl: true, // top left
                  tr: true, // top right
                });
              } else {
                // Show only corner handlers
                activeItem.setControlsVisibility({
                  mt: false, // middle top
                  mb: false, // middle bottom
                  ml: false, // middle left
                  mr: false, // middle right
                  bl: true, // bottom left
                  br: true, // bottom right
                  tl: true, // top left
                  tr: true, // top right
                });
              }
              canvas.requestRenderAll();
            }}
          >
            {isAspectRatioLocked ? (
              <LockKeyholeOpen size={18} />
            ) : (
              <LockKeyhole size={18} />
            )}
          </div>
        </div>
      </div>

      <form
        onSubmit={(e) => {
          e.preventDefault();
          handleDuplication();
        }}
      >
        {
          <div className="row fz-14px-rem">
            <div className="col-6">Quantity </div>
            <div className="col-6">
              <input
                value={quantity}
                onChange={(event) => setQuantity(event.target.value)}
                type="number"
                style={{ width: '10ch' }}
                min={1}
                step={1}
              />
            </div>
          </div>
        }

        <div className="mt-2 row">
          <div className="offset-6 col-6">
            <button type="submit" className="default-btn">
              Duplicate
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default SelectionAreaV2;
