import * as fabric from 'fabric';
import {
  LockKeyhole,
  LockKeyholeOpen,
  RotateCcw,
  TrashIcon,
} from 'lucide-react';
import { useRef, useState } from 'react';
import { canvasConstants } from './canvasUtils';
import { CloseIcon } from './Icons';
import { ViewableCanvas } from './ViewableCanvas';

const AutoBuildSelectionPreview = ({
  onClose,
  viewportMeasures,
  isAutoBuilding = false,
  selectionList = [],
  setSelectionList = () => {},
  addedImageList = [],
  initCanvasMeasures,
  onRefresh = () => {},
  autoBuildOptions,
  onAutoBuildOptionChange = () => {},
  selectedUnit,
  onSubmit,
  defaultMargin = 0.5,
  addAutoBuildItemsToCanvas,
  selectedOptions,
}) => {
  const [margins, setMargins] = useState({
    artBoardMargin: defaultMargin * selectedUnit.value,
    imageMargin: defaultMargin * selectedUnit.value,
  });
  const [previewSheets, setPreviewSheets] = useState([]);
  const [prevMeasures, setPrevMeasures] = useState({ height: 0, width: 0 });
  const [stepIndex, setStepIndex] = useState(0); //0 - Selection, 1-preview

  const allItems = selectionList;
  return (
    <div className="auto-build-area fade-in">
      {/* // TopBar */}
      <div className="d-flex justify-content-between mb-3">
        <div>Auto Build</div>
        <div className="d-flex gap-2">
          {stepIndex === 0 && (
            <div role="button" onClick={onRefresh}>
              <RotateCcw size={18} color="#4d5053" />
            </div>
          )}
          <div role={'button'} onClick={onClose}>
            <CloseIcon size={20} />
          </div>
        </div>
      </div>

      {/* AUtoBuildSelectionView */}
      {stepIndex === 0 && (
        <AutoBuildSelectionView
          allItems={allItems}
          isAutoBuilding={isAutoBuilding}
          autoBuildOptions={autoBuildOptions}
          margins={margins}
          onAutoBuildOptionChange={onAutoBuildOptionChange}
          onSubmit={async (allItems, margins) => {
            const sheets = await onSubmit(allItems, margins);
            if (sheets) {
              const { availableHeight, availableWidth } = initCanvasMeasures;
              const aspectRatio = availableWidth / availableHeight;
              const prevHeight = 300;
              const prevWidth = prevHeight * aspectRatio;
              setPreviewSheets(sheets);
              setPrevMeasures({
                width: prevWidth,
                height: prevHeight,
              });
              setStepIndex(1); //Moving to preview section
            }
          }}
          selectedUnit={selectedUnit}
          setMargins={setMargins}
          setSelectionList={setSelectionList}
          viewportMeasures={viewportMeasures}
        />
      )}

      {stepIndex === 1 && (
        <AutoBuildPreviewView
          previewSheets={previewSheets}
          onStepChange={(index) => setStepIndex(index)}
          initCanvasMeasures={initCanvasMeasures}
          prevMeasures={prevMeasures}
          selectionList={selectionList}
          addAutoBuildItemsToCanvas={addAutoBuildItemsToCanvas}
          selectedOptions={selectedOptions}
          selectedUnit={selectedUnit}
        />
      )}

      {allItems.length <= 0 && (
        <div
          style={{
            color: 'rgb(141, 140, 140)',
            textAlign: 'center',
            marginTop: '50px',
          }}
        >
          Nothing&apos;s here. Add some images
        </div>
      )}
    </div>
  );
};

const AutoBuildPreviewView = ({
  previewSheets = [],
  initCanvasMeasures,
  prevMeasures,
  selectionList = [],
  addAutoBuildItemsToCanvas,
  onStepChange = () => {},
  selectedOptions,
  selectedUnit,
}) => {
  const sheetLength = previewSheets.length ?? 0;
  const canvasRefs = useRef([]);

  return (
    <>
      <div
        className="mt-3"
        style={{
          display: 'grid',
          gridTemplateColumns: '300px 1fr',
          gap: 10,
        }}
      >
        <div>
          <div
            className="d-flex justify-content-between align-items-center px-3 py-2"
            style={{ border: '1px solid #e6e6e6' }}
          >
            <div style={{ fontSize: 16, fontWeight: 700 }}>
              {(selectedOptions.size.width * selectedUnit.value).toFixed(1)}{' '}
              {selectedUnit.short} x{' '}
              {(selectedOptions.size.height * selectedUnit.value).toFixed(1)}{' '}
              {selectedUnit.short}{' '}
            </div>
            <div> x {sheetLength}</div>
          </div>
          <div className="mt-3 d-flex gap-2 ">
            <button
              className="default-btn"
              style={{ flex: 1 }}
              onClick={() => {
                onStepChange(0);
              }}
            >
              Back & Adjust
            </button>
            <button
              style={{ flex: 1 }}
              className="default-btn primary-bg-color"
              onClick={() => addAutoBuildItemsToCanvas(previewSheets)}
            >
              Continue
            </button>
          </div>
        </div>
        <div style={{ display: 'flex', flexWrap: 'wrap', gap: 20 }}>
          {previewSheets.map((sheet, idx) => {
            const { availableWidth, availableHeight, scale } =
              initCanvasMeasures;
            return (
              <div className="viewable-canvas-container" key={sheet.name}>
                <div></div>
                <ViewableCanvas
                  prevMeasures={prevMeasures}
                  onLoad={(fabricCanvas, canvasRef) => {
                    // Assume that each sheet has a `originalWidth` and `originalHeight` that represents its original size.
                    // const originalWidth = availableHeight;
                    // const originalHeight = availableWidth;

                    // Calculate the scaling factors for width and height
                    const scaleX = prevMeasures.width / availableWidth;
                    const scaleY = prevMeasures.height / availableHeight;

                    // Use the smaller scale to maintain aspect ratio
                    const scaleFactor = Math.min(scaleX, scaleY);

                    sheet.objects.forEach(async (obj, idx) => {
                      // const imageElement = selectionList.find(
                      //   (item) => item.src === obj.src
                      // )?._originalElement;
                      // If the object is an image
                      const shouldRotate = obj.rot;
                      const correctedLeft = shouldRotate
                        ? obj.originalHeight * obj.scaleY * scaleFactor
                        : 0;

                      const fabImage = await fabric.FabricImage.fromURL(
                        obj.src,
                        { crossOrigin: 'anonymous' },
                        {
                          left: obj.x * scaleFactor + correctedLeft,
                          top: obj.y * scaleFactor,
                          // width: obj.width,
                          // height: obj.height,
                          scaleX: obj.scaleX * scaleFactor,
                          scaleY: obj.scaleY * scaleFactor,
                          // ...(obj.rot ? { angle: 90 } : {}),
                          selectable: false,
                          angle: obj.rot ? 90 : 0,
                        }
                      );
                      fabricCanvas.add(fabImage);
                      fabricCanvas.renderAll();
                    });
                  }}
                  fabricData={sheet.canvas}
                  refer={(canvas) => (canvasRefs.current[idx] = canvas)}
                />
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

const AutoBuildSelectionView = ({
  allItems,
  setMargins,
  margins,
  selectedUnit,
  viewportMeasures,
  setSelectionList,
  autoBuildOptions,
  onAutoBuildOptionChange,
  isAutoBuilding,
  onSubmit,
}) => {
  const handleMarginChange = (key, value) => {
    setMargins((prev) => ({
      ...prev,
      [key]: value,
    }));
  };

  const removeSelectedItem = (idx) => {
    setSelectionList((prev) => prev.filter((_, index) => index !== idx));
  };
  const duplicateSelectedItem = (idx) => {
    const item = allItems[idx];
    setSelectionList((prev) => [...prev, item]);
  };

  const updateHeight = (item, value, idx) => {
    const requiredScale =
      (value * (canvasConstants.CANVAS_DPI * viewportMeasures.scale)) /
      (item.height * (selectedUnit.value ?? 1));

    const isUnlocked = item?.ratioUnlocked;

    setSelectionList((prev) =>
      prev.map((itm, index) => {
        if (idx === index) {
          return isUnlocked
            ? { ...itm, scaleY: requiredScale }
            : { ...itm, scaleX: requiredScale, scaleY: requiredScale };
        }
        return itm;
      })
    );
  };
  const updateWidth = (item, value, idx) => {
    const requiredScale =
      (value * (canvasConstants.CANVAS_DPI * viewportMeasures.scale)) /
      (item.width * (selectedUnit.value ?? 1));

    const isUnlocked = item.ratioUnlocked;

    setSelectionList((prev) =>
      prev.map((itm, index) => {
        if (idx === index) {
          return isUnlocked
            ? { ...itm, scaleX: requiredScale }
            : { ...itm, scaleX: requiredScale, scaleY: requiredScale };
        }
        return itm;
      })
    );
  };

  const toggleRatioLock = (idx) => {
    setSelectionList((prev) =>
      prev.map((itm, index) => {
        if (idx === index) {
          return { ...itm, ratioUnlocked: !itm?.ratioUnlocked };
        }
        return itm;
      })
    );
  };

  return (
    <>
      {/* Margin options */}
      {allItems.length > 0 && (
        <div
          style={{ fontSize: 14 }}
          className="d-flex gap-2 align-items-center"
        >
          <div>
            <label>Image Margin</label>
            <input
              style={{ width: '8ch' }}
              value={margins.imageMargin}
              className="ms-1"
              step={0.5}
              type="number"
              min={'0'}
              onChange={(event) => {
                handleMarginChange('imageMargin', event.target.value);
              }}
            />
            <label className="ms-1">{selectedUnit.short}</label>
          </div>
          {/* Border */}
          <div style={{ height: 25, width: 1, backgroundColor: '#e6e6e6' }} />
          <div>
            <label>Artboard Margin</label>
            <input
              value={margins.artBoardMargin}
              className="ms-1"
              style={{ width: '8ch' }}
              step={0.5}
              type="number"
              min={'0'}
              onChange={(event) => {
                handleMarginChange('artBoardMargin', event.target.value);
              }}
            />
            <label className="ms-1">{selectedUnit.short}</label>
          </div>
        </div>
      )}

      <EditableImageList
        allItems={allItems}
        selectedUnit={selectedUnit}
        viewportMeasures={viewportMeasures}
        isPlaceable={true}
        onWidthUpdate={(event, item, idx) => {
          updateWidth(item, event.target.value, idx);
        }}
        onHeightUpdate={(event, item, idx) => {
          updateHeight(item, event.target.value, idx);
        }}
        onAspectRatioToggle={toggleRatioLock}
        onDuplicate={duplicateSelectedItem}
        onItemRemove={removeSelectedItem}
        updateSelectedItem={setSelectionList}
      />

      {allItems.length > 0 && (
        <>
          <div className="d-flex mt-2 align-items-center gap-3">
            <div>
              <input
                type="checkbox"
                id="smart-autobuild"
                checked={autoBuildOptions.isSmart}
                onChange={() =>
                  onAutoBuildOptionChange('isSmart', !autoBuildOptions.isSmart)
                }
              />{' '}
              <label htmlFor="smart-autobuild">Smart Build</label>
            </div>
            <div>
              <input
                type="checkbox"
                id="max-area-logic"
                checked={autoBuildOptions.isMaxArea}
                onChange={() =>
                  onAutoBuildOptionChange(
                    'isMaxArea',
                    !autoBuildOptions.isMaxArea
                  )
                }
              />{' '}
              <label htmlFor="max-area-logic">Minimize Rotation</label>
            </div>
          </div>
          <button
            disabled={isAutoBuilding}
            className="shdw-button primary-bg-color mt-4 px-4"
            onClick={() => onSubmit(allItems, margins)}
          >
            Apply{isAutoBuilding ? 'ing...' : ''}
          </button>
        </>
      )}
    </>
  );
};

export const EditableImageList = ({
  isLoading = false,
  allItems,
  viewportMeasures,
  selectedUnit,
  onWidthUpdate = () => {},
  onHeightUpdate = () => {},
  onAspectRatioToggle = () => {},
  onDuplicate = () => {},
  onItemRemove = () => {},
  updateSelectedItem = () => {},
  isPlaceable = false,
}) => {
  if (isLoading) {
    return `Loading...`;
  }

  const getSource = (item) => {
    if (item?._originalElement?.src) {
      return item._originalElement.src;
    }
    return item.src;
  };

  return (
    <div
      className={`mt-3 d-flex gap-2 flex-wrap overflow-auto ${isPlaceable ? '' : 'grid-placement'}`}
    >
      {allItems.map((item, idx) => {
        const src = getSource(item);
        const itemWidth = (
          ((item.width * item.scaleX) /
            (canvasConstants.CANVAS_DPI * viewportMeasures.scale)) *
          selectedUnit.value
        ).toFixed(2);
        const itemHeight = (
          ((item.height * item.scaleY) /
            (canvasConstants.CANVAS_DPI * viewportMeasures.scale)) *
          selectedUnit.value
        ).toFixed(2);

        return (
          <div key={idx} className="auto-build-item ">
            <div
              className="d-flex gap-2"
              style={{
                opacity:
                  isPlaceable && parseInt(item?.noOfItems ?? 0) === 0 ? 0.5 : 1,
                cursor:
                  isPlaceable && parseInt(item?.noOfItems ?? 0) === 0
                    ? 'not-allowed'
                    : 'pointer',
              }}
            >
              <div
                style={{
                  // width: 100,
                  // width: 135px;
                  background: 'rgb(209 213 219)',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  aspectRatio: 1,
                  height: '135px',
                  padding: '3px',
                  maxHeight: '100%',
                }}
              >
                <img src={src} alt="dfs" />
              </div>
              <div>
                {/* <div>Width: {itemWidth} in</div> */}
                {/* <div>Height: {itemHeight} in</div> */}
                <div className="row mt-2">
                  <div className="col-6">
                    <label style={{ whiteSpace: 'nowrap' }}>
                      Width ({selectedUnit.unit}):{' '}
                    </label>
                  </div>
                  <div className="col-6">
                    <input
                      type={'number'}
                      onChange={(event) => {
                        onWidthUpdate(event, item, idx);
                        // updateWidth(item, event.target.value, idx);
                      }}
                      min={'0'}
                      style={{ width: '10ch' }}
                      step={0.2}
                      value={itemWidth}
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-6">
                    <label style={{ whiteSpace: 'nowrap' }}>
                      Height ({selectedUnit.unit}):{' '}
                    </label>
                  </div>
                  <div className="col-6">
                    <input
                      type={'number'}
                      onChange={(event) => {
                        // updateHeight(item, event.target.value, idx);
                        onHeightUpdate(event, item, idx);
                      }}
                      min={'0'}
                      style={{ width: '10ch' }}
                      step={0.2}
                      value={itemHeight}
                    />
                  </div>
                </div>
                <div className="row mt-2">
                  <div className="col-6">
                    <div className="text-nowrap">Aspect Ratio:</div>
                  </div>
                  <div className="col-6 d-flex gap-2">
                    <div> {(itemWidth / itemHeight).toFixed(2)}</div>
                    <div
                      onClick={() => onAspectRatioToggle(idx)}
                      className="d-flex default-btn"
                    >
                      {item.ratioUnlocked ? (
                        <LockKeyholeOpen size={16} />
                      ) : (
                        <LockKeyhole size={16} />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {isPlaceable && (
              <div className="mt-3 d-flex gap-2 justify-content-between align-items-center">
                <input
                  type={'number'}
                  value={item?.noOfItems ?? 0}
                  min="0"
                  style={{ width: '10ch' }}
                  onChange={(event) => {
                    const { value } = event.target;
                    updateSelectedItem((prev) =>
                      prev.map((itm, index) => {
                        if (idx === index) {
                          return { ...itm, noOfItems: value };
                        }
                        return itm;
                      })
                    );
                  }}
                  maxLength={5}
                />

                <div className="d-flex gap-2 ">
                  <div className="default-btn" onClick={() => onDuplicate(idx)}>
                    Duplicate
                  </div>
                  <div
                    className="default-btn d-flex align-items-center justify-content-center"
                    onClick={() => onItemRemove(idx)}
                  >
                    <TrashIcon size={16} />
                  </div>
                </div>
              </div>
            )}
          </div>
        );
      })}
    </div>
  );
};

export default AutoBuildSelectionPreview;
