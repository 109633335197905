/* eslint-disable react-hooks/exhaustive-deps */
import { useCallback, useEffect, useRef, useState } from 'react';

const useCanvasDrawer = (
  {
    selectedResolutionInInch = { width: 0, height: 0 },
    onCanvasInit = () => {},
  } = {
    selectedResolutionInInch: { width: 0, height: 0 },
    onCanvasInit: () => {},
  }
) => {
  const [canvasSize, setCanvasSize] = useState({
    fullWidth: 0,
    fullHeight: 0,
    x: 0,
    y: 0,
    availableWidth: 0,
    availableHeight: 0,
    scale: 0,
  });
  //   const [resolutionInPx, setResolutionInPx] = useState({ width: 0, height: 0 });

  const innerCanvasRef = useRef();

  const convertInchesToPixels = (inches, dpi = 300) => {
    return inches * dpi;
  };

  const findScaleAndDimensionAfterScreenFit = ({
    fullWidth,
    fullHeight,
    padding = 50,
    viewportWidthInches,
    viewportHeightInches,
    dpi = 300,
  }) => {
    // Subtract padding from the available space
    const paddedWidth = fullWidth - padding * 2;
    const paddedHeight = fullHeight - padding * 2;

    // Convert the viewport dimensions from inches to pixels
    let viewportWidthPixels = convertInchesToPixels(viewportWidthInches, dpi);
    let viewportHeightPixels = convertInchesToPixels(viewportHeightInches, dpi);

    // Calculate the scaling factor to fit the viewport within the padded space
    const widthScale = paddedWidth / viewportWidthPixels;
    const heightScale = paddedHeight / viewportHeightPixels;
    const scale = Math.min(widthScale, heightScale);

    viewportWidthPixels *= scale;
    viewportHeightPixels *= scale;

    return {
      scale,
      viewportHeightPixels,
      viewportWidthPixels,
      paddedWidth,
      paddedHeight,
    };
  };

  const fitViewportOnCanvas = (
    viewportWidthInches,
    viewportHeightInches,
    fullWidth,
    fullHeight,
    padding = 50,
    dpi = 300
  ) => {
    const { scale, viewportHeightPixels, viewportWidthPixels } =
      findScaleAndDimensionAfterScreenFit({
        fullWidth,
        fullHeight,
        padding,
        viewportHeightInches,
        viewportWidthInches,
        dpi,
      });
    // // Subtract padding from the available space
    // const paddedWidth = fullWidth - padding * 2;
    // const paddedHeight = fullHeight - padding * 2;

    // // Convert the viewport dimensions from inches to pixels
    // let viewportWidthPixels = convertInchesToPixels(viewportWidthInches, dpi);
    // let viewportHeightPixels = convertInchesToPixels(viewportHeightInches, dpi);

    // // Calculate the scaling factor to fit the viewport within the padded space
    // const widthScale = paddedWidth / viewportWidthPixels;
    // const heightScale = paddedHeight / viewportHeightPixels;
    // const scale = Math.min(widthScale, heightScale);
    // Apply the scaling factor to the viewport dimensions

    // Calculate the position to center the viewport on the canvas, considering padding
    const xPosition = (fullWidth - viewportWidthPixels) / 2;
    const yPosition = (fullHeight - viewportHeightPixels) / 2;
    // Only loads on canvas init.
    onCanvasInit({
      availableWidth: viewportWidthPixels,
      availableHeight: viewportHeightPixels,
      x: xPosition,
      y: yPosition,
      padding: padding,
      scale,
      widthInInches: viewportWidthInches,
      heightInInches: viewportHeightInches,
      fullHeight,
      fullWidth,
    });

    return {
      width: viewportWidthPixels,
      height: viewportHeightPixels,
      x: xPosition,
      y: yPosition,
      padding: padding,
      scale,
    };
  };

  const getInitVPSizeAndPosition = useCallback(() => {
    if (!canvasSize.fullWidth || !canvasSize.fullHeight) {
      return { width: 0, height: 0 };
    }

    return fitViewportOnCanvas(
      selectedResolutionInInch.width,
      selectedResolutionInInch.height,
      canvasSize.fullWidth,
      canvasSize.fullHeight
    );
  }, [canvasSize.fullWidth, canvasSize.fullHeight]);

  const resetPosition = () => {
    const { height, width, x, y } = getInitVPSizeAndPosition();

    setCanvasSize({
      ...canvasSize,
      availableHeight: height,
      availableWidth: width,
      x,
      y,
    });
  };

  const redrawViewPort = () => {
    if (!innerCanvasRef.current) {
      return;
    }
    const ctx = innerCanvasRef.current.getContext('2d');

    ctx.fillStyle = 'transparent';
    ctx.fillRect(
      canvasSize.x,
      canvasSize.y,
      canvasSize.availableWidth,
      canvasSize.availableHeight
    );
  };

  const drawViewPort = (newValue, isFromListener = false) => {
    if (!innerCanvasRef.current) {
      return;
    }
    const ctx = innerCanvasRef.current.getContext('2d');
    if (
      canvasSize.x &&
      canvasSize.y &&
      canvasSize.availableHeight &&
      canvasSize.availableWidth
    ) {
      ctx.clearRect(
        canvasSize.x,
        canvasSize.y,
        canvasSize.availableWidth,
        canvasSize.availableHeight
      );
    }

    ctx.fillStyle = 'transparent';

    const {
      x: newX = canvasSize.x,
      y: newY = canvasSize.y,
      width: newWidth = canvasSize.availableWidth,
      height: newHeight = canvasSize.availableHeight,
    } = newValue;

    if (isFromListener) {
      setCanvasSize((prev) => ({
        ...prev,
        x: prev.x - newX,
        y: prev.y - newY,
        scale: newValue?.scale ?? 1,
        width: newValue.availableWidth
          ? newValue.availableWidth * 0.8
          : canvasSize.availableWidth,
        height: newValue.availableHeight
          ? newValue.availableHeight * 0.8
          : canvasSize.availableHeight,
      }));
    } else {
      setCanvasSize((prev) => ({
        ...prev,
        x: newX,
        y: newY,
        availableWidth: newWidth,
        availableHeight: newHeight,
      }));
    }

    ctx.fillRect(newX, newY, newWidth, newHeight);
  };

  const addImageToViewPort = (imageSrc, x, y, ex, ey) => {
    const ctx = innerCanvasRef.current.getContext('2d');
    const image = new Image();
    image.src = imageSrc;
    image.width = 20;
    image.height = 20;

    image.onload = () => {
      ctx.drawImage(image, x, y, ex, ey);
    };
  };

  useEffect(() => {
    if (innerCanvasRef.current) {
      // Get the top bar height from const saved in :root  in the style.css
      const topBarHeight = getComputedStyle(
        document.documentElement
      ).getPropertyValue('--top-bar-height');
      //   Set the context height and width
      innerCanvasRef.current.width = window.innerWidth - 300;
      innerCanvasRef.current.height =
        window.innerHeight - (parseInt(topBarHeight) + 40);
      innerCanvasRef.current.style.width = `${window.innerWidth - 300}px`;
      innerCanvasRef.current.style.height = `${window.innerHeight - (parseInt(topBarHeight) + 40)}px`;

      // Draw image

      const { height, width, x, y, scale } = fitViewportOnCanvas(
        selectedResolutionInInch.width,
        selectedResolutionInInch.height,
        innerCanvasRef.current.width,
        innerCanvasRef.current.height
      );

      setCanvasSize((prev) => ({
        ...prev,
        fullWidth: innerCanvasRef.current.width,
        fullHeight: innerCanvasRef.current.height,
        scale,
      }));

      drawViewPort({ x, y, width, height });
    }
  }, [selectedResolutionInInch.width, selectedResolutionInInch.height]);

  return {
    innerCanvasRef,
    canvasSize,
    fitViewportOnCanvas,
    getInitVPSizeAndPosition,
    drawViewPort,
    resetPosition,
    addImageToViewPort,
    findScaleAndDimensionAfterScreenFit,
  };
};

export default useCanvasDrawer;
