import { TEXT_FONTS } from './canvasUtils';

const FontOptions = ({
  onFontChange = () => {},
  canvas,
  onColorChange = () => {},
}) => {
  if (canvas?.getActiveObject()?.type !== 'i-text') {
    return null;
  }

  const currentColor = canvas.getActiveObject().fill;
  const currentFont = canvas.getActiveObject().fontFamily;

  return (
    <div className="d-flex gap-2">
      {/* // Font Family */}
      <select
        value={currentFont}
        onChange={(event) => onFontChange(event.target.value)}
      >
        {TEXT_FONTS.map((font) => {
          return (
            <option key={font.id} value={font.value}>
              {font.label}
            </option>
          );
        })}
      </select>
      <input
        type="color"
        value={currentColor}
        onChange={(event) => onColorChange(event.target.value)}
      />
    </div>
  );
};

export default FontOptions;
