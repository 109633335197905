import { useEffect, useRef, useState } from 'react';

function usePinchHandler({
  canvasConfig,
  drawViewPort = () => {},
  onChange = () => {},
  dependency = [],
}) {
  const [isCanvasMoving, setIsCanvasMoving] = useState(false);
  const containerRef = useRef(null);
  const containerElement = containerRef.current;
  const {
    x,
    y,
    availableWidth,
    availableHeight,
    fullWidth,
    fullHeight,
    scale,
  } = canvasConfig;

  useEffect(() => {}, [isCanvasMoving]);
  const animationId = useRef(null);

  useEffect(() => {
    const handleCanvasMove = (event) => {
      event.preventDefault();
      setIsCanvasMoving(true);

      let scale = 1;
      if (event.deltaMode === 1) {
        // Line-based scroll (mouse)
        scale = 30; // You can tweak this based on your needs
      }

      const deltaX = event.deltaX * scale;
      const deltaY = event.deltaY * scale;

      // if (!isCanvasMoving) {
      cancelAnimationFrame(animationId.current);
      animationId.current = requestAnimationFrame(() => {
        // Calculate the new scale based on the event
        const newScale = deltaY * -0.01; // Adjust the multiplier as needed

        // Ensure scale stays within a reasonable range
        const clampedScale = Math.min(Math.max(newScale, 0.5), 3);

        const adjustedWidth = clampedScale * availableWidth;
        const adjustedHeight = clampedScale * availableHeight;

        drawViewPort(
          {
            x: deltaX,
            y: deltaY,
            scale: clampedScale,
            width: adjustedWidth,
            height: adjustedHeight,
          },
          true
        );
        onChange();
      });
      setIsCanvasMoving(false);
      // };
    };

    containerElement?.addEventListener('wheel', handleCanvasMove, {
      passive: false,
    });

    containerElement?.addEventListener('gesturestart', handleCanvasMove);
    containerElement?.addEventListener('touchmove', handleCanvasMove, {
      passive: false,
    });
    return () => {
      containerElement?.removeEventListener('wheel', handleCanvasMove);

      containerElement?.removeEventListener('gesturestart', handleCanvasMove);
      containerElement?.removeEventListener('touchmove', handleCanvasMove);
    };
  }, [containerElement, isCanvasMoving, animationId.current, ...dependency]);

  return { containerRef };
}

export default usePinchHandler;
