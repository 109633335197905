import React, { useEffect, useRef, useState } from 'react';

import { SelectBox } from './SelectBox';
//input [{"color":"#a68f9b","row":11} ...]
import {
  hexToRgb,
  isColorMatch,
  resizeImageSize,
  getDistinctColors,
  rgbStringToHex,
  //rowColorList,
  getAvailableColorGenerated,
  quantizeImage,
  findNearestColors,
  getNearestPantoneColor,
  getTextColor,
  posterizeImageData,
} from './_miscFunction.js';
import ImageUploadComponent from './Component/ImageUploadComponent.jsx';

let _img = null;

export const ImageColorReplace = () => {
  const canvasRef = useRef(null);
  const [colors, setColors] = useState([]);
  const [originalImg, setOriginalImg] = useState(null);
  const [pantoneColors, setPantoneColors] = useState([]);

  const [timeoutId, setTimeoutId] = useState(null);
  const [imgUrl, setImgUrl] = useState(null);

  const [tab, setTab] = useState(1);

  const [noOfColors, setNoOfColors] = useState(5);

  useEffect(() => {
    setTimeout(() => {
      _img && imageColorInit(_img, noOfColors);
    }, 1000);
  }, [tab, noOfColors]);

  const removeDuplicatesAndProcess = (colors) => {
    const uniqueColors = new Map();
    const uniqueColorsSet = new Set();
    console.log('colors removeDuplicatesAndProcess', colors);
    colors.forEach((item) => {
      if (!uniqueColorsSet.has(item.colorHex)) {
        uniqueColorsSet.add(item.colorHex);
        uniqueColors.set(item.colorHex, item);
      }
    });

    return Array.from(uniqueColors.values());
  };
  const getPantoneColors = async (originalColors) => {
    const pantoneColors = await getNearestPantoneColor(originalColors);
    console.log('pantoneColors', pantoneColors);
    setPantoneColors(pantoneColors);
  };

  const updateBulkCanvasColor = (toUpdateColors) => {
    const { ctx, canvas } = getCanvasContext();
    if (!ctx) {
      console.error('Canvas context not found.');
      return;
    }

    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

    const colorMap = new Map();
    toUpdateColors.forEach(({ originalColor, newColor }) => {
      const key = `${originalColor.R},${originalColor.G},${originalColor.B}`;
      colorMap.set(key, newColor);
    });

    for (let i = 0; i < imageData.data.length; i += 4) {
      const key = `${imageData.data[i]},${imageData.data[i + 1]},${
        imageData.data[i + 2]
      }`;
      if (colorMap.has(key)) {
        const newColor = colorMap.get(key);
        imageData.data[i] = newColor.R;
        imageData.data[i + 1] = newColor.G;
        imageData.data[i + 2] = newColor.B;
      }
    }

    ctx.putImageData(imageData, 0, 0);
  };

  const imageColorInit = (img, _noOfColors) => {
    if (!img) {
      setOriginalImg(null);
      return;
    }
    _img = img;

    const { imageWidth, imageHeight, ctx } = drawImageOnCanvas(
      img,
      _noOfColors
    );

    const colors = extractColorsProcess(imageWidth, imageHeight, ctx);

    setColors(colors);
  };

  const getCanvasContext = () => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    return { ctx, canvas };
  };

  const drawImageOnCanvas = (img, _noOfColors) => {
    const { ctx, canvas } = getCanvasContext();
    const { newWidth, newHeight } = resizeImageSize(img.width, img.height);
    canvas.width = newWidth;
    canvas.height = newHeight;
    ctx.imageSmoothingEnabled = false;
    ctx.drawImage(img, 0, 0, newWidth, newHeight);
    let imageData = ctx.getImageData(0, 0, newWidth, newHeight);
    //imageData = posterizeImageData(imageData);
    imageData = quantizeImage(imageData, _noOfColors || noOfColors);
    ctx.putImageData(imageData, 0, 0);

    return { imageWidth: newWidth, imageHeight: newHeight, ctx };
  };

  // Replaces each color with the nearest available color and returns the updated list
  const replaceWithAvailableColor = (colors) => {
    const toUpdate = [];
    colors.forEach((item) => {
      if (
        item.colorSelected.colorHex.toUpperCase() !==
        item.colorHex.toUpperCase()
      ) {
        const originalColor = hexToRgb(item.colorHex);
        const newColor = hexToRgb(item.colorSelected.colorHex);
        toUpdate.push({ originalColor, newColor });
        item.colorHex = item.colorSelected.colorHex;
        item.colorRgb = item.colorSelected.colorRgb;
      }
    });

    if (toUpdate.length > 0) {
      updateBulkCanvasColor(toUpdate);
    }

    return colors;
  };
  const extractColorsProcess = (width, height, ctx) => {
    const imageData = ctx.getImageData(0, 0, width, height);
    const originalColors = getDistinctColors(imageData.data);
    console.log('Extracted Colors:', originalColors); // Debugging
    getPantoneColors(originalColors);

    const availableColors = getAvailableColorGenerated(
      tab === 1 ? 'thread' : tab === 2 ? 'fabric' : 'woven'
    );
    const mappedColors = originalColors.map((color) => {
      const nearestColors = findNearestColors(color.colorRgb, availableColors);
      return {
        colorHex: rgbStringToHex(color.colorRgbString),
        colorRgb: color.colorRgb,
        count: color.count,
        colorSelected: nearestColors[0], // Closest available color
        nearestColors: nearestColors,
      };
    });

    // Replace colors with the nearest available colors and remove duplicates
    const updatedColors = replaceWithAvailableColor(mappedColors);
    const uniqueColors = removeDuplicatesAndProcess(updatedColors);

    return uniqueColors;
  };

  const updateCanvasColor = (originalColor, newColor) => {
    const canvas = canvasRef.current;
    if (!canvas) {
      console.error('Canvas element not found.');
      return;
    }

    const ctx = canvas.getContext('2d');
    if (!ctx) {
      console.error('Canvas context not found.');
      return;
    }

    const imageData = ctx.getImageData(0, 0, canvas.width, canvas.height);

    //const originalColorRgb = typeof originalColor =='object'?originalColor: hexToRgb(originalColor);
    const originalColorRgb = hexToRgb(originalColor);
    const newColorRgb = hexToRgb(newColor);

    for (let i = 0; i < imageData.data.length; i += 4) {
      if (isColorMatch(imageData.data.slice(i, i + 4), originalColorRgb)) {
        imageData.data[i] = newColorRgb.R;
        imageData.data[i + 1] = newColorRgb.G;
        imageData.data[i + 2] = newColorRgb.B;
      }
    }

    ctx.putImageData(imageData, 0, 0);
  };

  // const isColorMatch = (pixelData, colorRgb) => {
  //   const [R, G, B] = pixelData;
  //   return R=== colorRgb.R && G=== colorRgb.G&& B === colorRgb.B;
  // };

  //color is in hex and selectColorObj is object with hex and row
  const handleColorChange = (originalColor, selectColorObj) => {
    console.log('handleColorChange', originalColor, selectColorObj, colors);
    // Update the colors array with new value
    let updatedColors = colors.map((colorObj) =>
      colorObj.colorSelected.colorHex === originalColor
        ? {
            ...selectColorObj,
            colorSelected: selectColorObj,
            nearestColors: findNearestColors(
              selectColorObj.colorRgb,
              getAvailableColorGenerated(
                tab === 1 ? 'thread' : tab === 2 ? 'fabric' : 'woven'
              )
            ),
          }
        : colorObj
    );
    //replace color
    updateCanvasColor(originalColor, selectColorObj.colorHex);

    //-Just remove duplicates in array
    updatedColors = removeDuplicatesAndProcess(updatedColors);
    setColors(updatedColors);
  };

  const handleChangeSliderColors = (event) => {
    setNoOfColors(event.target.value);
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    const newTimeoutId = setTimeout(() => {
      if (!originalImg) return;
      imageColorInit(originalImg, event.target.value); // Call imageInitFunction after reset
    }, 1000); // 1 second

    setTimeoutId(newTimeoutId);
  };
  console.log('originalImg', originalImg);

  return (
    <div className="card min-500">
      <div className="card-header">
        <img
          src="/img/picker.png"
          alt="Color Picker"
          className="mr-2 card-header-icon"
        />
        Image Color Picker
      </div>
      <div className="card-body">
        <div className="row">
          <div className="col-md-3">
            {!originalImg && (
              <>
                <ImageUploadComponent
                  onSelectImage={(imgURL) => {
                    const img = new Image();
                    img.onload = () => {
                      setOriginalImg(img);

                      imageColorInit(img);
                    };
                    setImgUrl(imgURL);
                    img.src = imgURL;
                  }}
                />
              </>
            )}
            <div className={originalImg ? 'd-flex' : 'd-none'}>
              {imgUrl && (
                <>
                  <img src={imgUrl} alt="Original" style={{ width: '100%' }} />
                </>
              )}
            </div>
            {originalImg && (
              <div
                className="cursor"
                style={{
                  color: 'blue',
                  fontSize: '12px',
                }}
                onClick={() => setOriginalImg(null)}
              >
                remove X
              </div>
            )}
            <div>
              {originalImg && (
                <div className="NoOfColors-slider container ">
                  <label htmlFor="slider" className="form-label">
                    Density : {noOfColors}
                  </label>
                  <input
                    type="range"
                    className="form-range"
                    id="slider"
                    min="2"
                    max="20"
                    name="NoOfColors"
                    value={noOfColors}
                    onChange={handleChangeSliderColors}
                  />
                  <div
                    className="NoOfColors-preview mt-3 p-3"
                    style={{ filter: `saturate(${noOfColors}%)` }}
                  ></div>
                </div>
              )}
            </div>
            <br /> <br /> <br /> <br /> <br />
          </div>
          <div className={'col-md-3 ' + (originalImg ? '' : 'hidden')}>
            <h4>Pantone color</h4>
            {pantoneColors.map((colorObj, index) => (
              <div key={index}>
                <div>
                  {!!colorObj?.pantone && (
                    <div
                      style={{
                        backgroundColor: colorObj?.pantone?.colorHex,
                        width: '100%',
                        color: getTextColor(colorObj?.pantone?.colorHex),

                        padding: '7px',
                        borderRadius: '5px',
                        display: 'inline-block',
                      }}
                      className="mb-3"
                    >
                      <div>
                        {colorObj?.pantone?.name} ({colorObj?.pantone?.colorHex}
                        )
                      </div>
                    </div>
                  )}
                </div>
                {/* <div
                  style={{
                    backgroundColor: rgbStringToHex(colorObj.colorRgbString),
                    width: '100%',
                    height: '20px',
                    display: 'inline-block',
                  }}
                >
                  original{JSON.stringify(colorObj)}
                </div> */}
              </div>
            ))}
          </div>
          <div className={'col-md-3 ' + (originalImg ? '' : 'hidden')}>
            <div className={originalImg ? 'd-flex' : 'd-none'}>
              <canvas ref={canvasRef} style={{ width: '100%' }}></canvas>
            </div>
          </div>

          <div className={'col-md-3 ' + (originalImg ? '' : 'hidden')}>
            <h4>Available color</h4>

            <select
              className="form-select form-control"
              onChange={(e) => setTab(Number(e.target.value))}
              value={tab}
            >
              <option value={1}>Embroidery Thread</option>
              <option value={2}>Fabric Color</option>
              <option value={3}>Woven Thread</option>
            </select>
            <br />

            {/* <ul className="nav nav-tabs card-header-tabs">
              <li className="nav-item" onClick={() => setTab(1)}>
                <a
                  className={'nav-link' + (tab === 1 ? ' active' : '')}
                  href="#tab1"
                  data-toggle="tab"
                >
                  Embroidery Thread
                </a>
              </li>
              <li className="nav-item" onClick={() => setTab(2)}>
                <a
                  className={'nav-link' + (tab === 2 ? ' active' : '')}
                  href="#tab2"
                  data-toggle="tab"
                >
                  Fabric Color
                </a>
              </li>
              <li className="nav-item" onClick={() => setTab(3)}>
                <a
                  className={'nav-link' + (tab === 3 ? ' active' : '')}
                  href="#tab3"
                  data-toggle="tab"
                >
                  Woven Thread
                </a>
              </li>
            </ul> */}

            <div className="color-sidebar">
              {colors.map((colorObj, index) => (
                <div key={index}>
                  <SelectBox
                    key={colorObj.colorSelected.name}
                    colorObj={colorObj}
                    //maximum 10 color
                    selects={colorObj.nearestColors.slice(0, 10)}
                    handleColorChange={handleColorChange}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
