// import { authHeader, authHeaderMulti, handleResponse } from '../_helpers';

import { apiConstants } from '../Constants/apiurl.constant';
import { authHeader } from '../helpers/auth-header';
import { authHeaderMulti } from '../helpers/auth-header-multi';
import { handleResponse } from '../helpers/response-handler';

export const miscService = {
  fileUpload,
  createSignedUploadUrl,
  b64toBlob,
  formatDate,
  formatTime,
  getParameterByName,
  getMetaDataFromUrl,
  initFunction,
};

function createSignedUploadUrl(data) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(data),
  };
  return fetch(
    apiConstants.apiUrl + '/get_signed_upload_url',
    requestOptions
  ).then(handleResponse);
}

function b64toBlob(b64Data, contentType, sliceSize) {
  contentType = contentType || '';
  sliceSize = sliceSize || 512;

  let byteCharacters = atob(b64Data);
  let byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    let slice = byteCharacters.slice(offset, offset + sliceSize);

    let byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    let byteArray = new Uint8Array(byteNumbers);

    byteArrays.push(byteArray);
  }

  let blob = new Blob(byteArrays, { type: contentType });
  return blob;
}

function fileUpload(file, name) {
  let data = new FormData();
  data.append('file', file, name);
  data.append('name', 'filename');

  const requestOptions = {
    method: 'POST',
    body: data,
    headers: authHeaderMulti(),
  };
  return fetch(apiConstants.apiUrl + '/uploadfileblob', requestOptions).then(
    handleResponse
  );
}

function formatDate(date) {
  let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();

  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;

  return [day, month, year].join('/');
}

function formatTime(date) {
  let d = new Date(date),
    hours = d.getHours(),
    minutes = '' + d.getMinutes(),
    a = ' AM';

  if (hours > 12) {
    hours = '' + hours - 12;
    a = ' PM';
  } else if (hours === 0) {
    hours = '12';
  } else {
    hours = '' + hours;
  }

  if (minutes.length < 2) minutes = '0' + minutes;

  return hours + ':' + minutes + a;
}

function getParameterByName(name, url) {
  if (!url) url = window.location.href;
  name = name.replace(/[[\]]/g, '\\$&');
  var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return '';
  return decodeURIComponent(results[2].replace(/\+/g, ' '));
}

async function getMetaDataFromUrl(url) {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({ url }),
  };
  const response = await fetch(
    `${apiConstants.apiUrl}/metadata`,
    requestOptions
  );
  return handleResponse(response);
}

async function initFunction() {
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify({ init: true }),
  };
  return fetch(apiConstants.apiUrl + '/initialize_data', requestOptions).then(
    handleResponse
  );
}
