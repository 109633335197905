import { useEffect, useState } from 'react';
import ColorBox from './Component/ColorBox';
import {
  availableColor,
  embroideryThreadColor,
  fabricColor,
  wovenThread,
} from './availableColors';

export const AvailableColorComponent = ({
  _colors = availableColor,
  width = 100,
  page = true,
}) => {
  const [searchTerm, setSearchTerm] = useState('');
  const [tab, setTab] = useState(1);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  const [colors, setColors] = useState(_colors);
  useEffect(() => {
    setSearchTerm('');

    if (tab === 1) {
      setColors(embroideryThreadColor);
    } else if (tab === 2) {
      setColors(fabricColor);
    } else if (tab === 3) {
      setColors(wovenThread);
    }
  }, [tab]);

  useEffect(() => {
    const _slectedcolors =
      tab === 1 ? embroideryThreadColor : tab === 2 ? fabricColor : wovenThread;
    if (searchTerm) {
      setColors(
        _slectedcolors.filter(
          (color) =>
            color.name.toLowerCase().includes(searchTerm) ||
            color.colorHex.toLowerCase().includes(searchTerm)
        )
      );
    } else {
      setColors(_slectedcolors);
    }
  }, [searchTerm, tab, colors]);

  return (
    <div className="container">
      <div className="card">
        <div className="card-header">
          <img
            src="
          /img/available.png"
            alt="Available Colors"
            className="mr-2 card-header-icon"
          />
          Available Colors
          <input
            type="text"
            className="form-control mt-2"
            placeholder="Search by name or hex code"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </div>
        {/* Bootstrap Tab embroideryThreadColor, fabricColor, wovenThread */}
        {page && (
          <div className="card-header">
            <ul className="nav nav-tabs card-header-tabs">
              <li className="nav-item" onClick={() => setTab(1)}>
                <a
                  className={'nav-link' + (tab === 1 ? ' active' : '')}
                  href="#tab1"
                  data-toggle="tab"
                >
                  Embroidery Thread
                </a>
              </li>
              <li className="nav-item" onClick={() => setTab(2)}>
                <a
                  className={'nav-link' + (tab === 2 ? ' active' : '')}
                  href="#tab2"
                  data-toggle="tab"
                >
                  Fabric Color
                </a>
              </li>
              <li className="nav-item" onClick={() => setTab(3)}>
                <a
                  className={'nav-link' + (tab === 3 ? ' active' : '')}
                  href="#tab3"
                  data-toggle="tab"
                >
                  Woven Thread
                </a>
              </li>
            </ul>
          </div>
        )}
        <div className="card-body">
          <div className="d-flex flex-wrap">
            {colors.map((color, index) => (
              <div key={index}>
                <ColorBox color={color} width={width} />
                <div className="color-title">
                  {color.name} <br /> {color.colorHex}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
