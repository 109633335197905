// Layout.js
import React from 'react';
import Menu from './Header/Menu';

const Layout = ({ children }) => {
  return (
    <>
      <header>
        <Menu />
      </header>
      <main className="page">
        <div className="container-t">{children}</div>
      </main>

      {/* <footer>
        <br />
        <br />
        <br />
      </footer> */}
    </>
  );
};

export default Layout;
