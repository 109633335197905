import React from 'react';
import { canvasConstants } from '../Test/canvasUtils';

function SelectedImageDetails({
  selectedItem = null,
  viewportMeasures = null,
  initCanvasMeasures,
  selectedUnit,
}) {
  if (!selectedItem) {
    return null;
  }

  const getColorByDPI = (dpi) => {
    if (dpi >= 300) {
      return 'green';
    } else if (dpi >= 150) {
      return 'orange';
    } else if (dpi >= 72) {
      return 'red';
    } else if (dpi < 72) {
      return 'red';
    }
  };
  const isSvg = selectedItem?._originalElement?.src?.endsWith('.svg');

  const svgResAdjustment = isSvg
    ? canvasConstants.CANVAS_DPI / canvasConstants.CANVAS_SVG_DPI
    : 1;

  const selectedDPI =
    svgResAdjustment *
    parseInt(
      canvasConstants.CANVAS_DPI *
        (viewportMeasures.scale / selectedItem.scaleX)
    );

  const isImage = selectedItem.type === 'image';

  const isRotated = Boolean(selectedItem?.angle);

  const selectedItemWidth = (
    ((selectedItem?.width * selectedItem.scaleX) /
      (canvasConstants.CANVAS_DPI * viewportMeasures.scale)) *
    selectedUnit.value
  ).toFixed(2);

  const selectedItemHeight = (
    ((selectedItem?.height * selectedItem.scaleY) /
      (canvasConstants.CANVAS_DPI * viewportMeasures.scale)) *
    selectedUnit.value
  ).toFixed(2);

  const leftOffset = isRotated ? selectedItemHeight : 0;

  const leftValue = (
    ((selectedItem.left - initCanvasMeasures.x) /
      (canvasConstants.CANVAS_DPI * viewportMeasures.scale)) *
      selectedUnit.value -
    leftOffset
  ).toFixed(2);

  const topValue = (
    ((selectedItem.top - initCanvasMeasures.y) /
      (canvasConstants.CANVAS_DPI * viewportMeasures.scale)) *
    selectedUnit.value
  ).toFixed(2);

  return (
    <div
      style={{
        position: 'absolute',
        left: 30,
        top: 30,
        zIndex: 999,
        backgroundColor: 'white',
        padding: 10,
        borderRadius: 5,
        fontSize: 14,
      }}
    >
      <div>
        Left: {leftValue} {selectedUnit.short}
      </div>
      <div>
        Top: {topValue} {selectedUnit.short}
      </div>
      <div>
        Width: {selectedItemWidth} {selectedUnit.short}
      </div>
      <div>
        Height: {selectedItemHeight} {selectedUnit.short}
      </div>

      {isImage && (
        <div>
          Resolution:{' '}
          <span style={{ color: getColorByDPI(selectedDPI) }}>
            {selectedDPI} dpi
          </span>
        </div>
      )}
    </div>
  );
}

export default SelectedImageDetails;
