import { apiConstants } from '../Constants/apiurl.constant';
import { authHeader } from '../helpers/auth-header';
import { handleResponse } from '../helpers/response-handler';

const removeImageBackground = (file) => {
  const formData = new FormData();
  formData.append('original_image', file);

  const url = `http://localhost:8000/api/upload/`;

  const requestOptions = {
    method: 'POST',
    // headers: {
    //   'Content-Type': 'multipart/form-data',
    // },
    body: formData,
  };
  return fetch(url, requestOptions).then(handleResponse);
};
const upscaleImage = (file) => {
  const formData = new FormData();
  formData.append('original_image', file);

  const url = `http://localhost:8000/api/upscale/`;

  const requestOptions = {
    method: 'POST',
    // headers: {
    //   'Content-Type': 'multipart/form-data',
    // },
    body: formData,
  };
  return fetch(url, requestOptions).then(handleResponse);
};

const fileUrlToBlob = async (upscaledImageUrl) => {
  const fileResponse = await fetch(upscaledImageUrl);
  if (!fileResponse.ok) {
    throw new Error('Network response was not ok');
  }

  const blob = await fileResponse.blob();

  return blob;
};
// COnvert eps to svg
const convertEpsToSvg = async (files) => {
  // Generate service to convert eps to svg
  const formData = new FormData();
  // formData.append('file', files);
  // I need to send this file in the object key epsFiles
  // formData.append('epsFiles', files);

  files.forEach((file) => {
    formData.append('file', file);
  });

  const url = apiConstants.apiUrl + `/process-file/`;

  const requestOptions = {
    method: 'POST',
    body: formData,
  };
  return fetch(url, requestOptions).then(handleResponse);
};

// const convertMultipleEpsToSvg = async (epsFiles) => {
//   const formData = new FormData();

//   // epsFiles.forEach((file, index) => {
//   //   formData.append(`epsFiles[${index}]`, file);
//   // });

//   formData.append('file', epsFiles);
//   formData.append('name', 'filename');

//   const url = `http://localhost:2020/api/v1/process-file/`;

//   const requestOptions = {
//     method: 'POST',
//     body: formData,
//   };

//   return fetch(url, requestOptions).then(handleResponse);
// };

const addUploadedImages = async (payload) => {
  const url = apiConstants.apiUrl + '/add-uploaded-images';
  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(payload),
  };
  return fetch(url, requestOptions).then(handleResponse);
};
// Convert svg to eps
const convertSvgToEps = async (epsFiles) => {
  const formData = new FormData();

  formData.append('file', epsFiles);
  formData.append('name', 'filename');

  const url = `${apiConstants.apiUrl}/svg-to-eps/`;

  const requestOptions = {
    method: 'POST',
    body: formData,
  };

  return fetch(url, requestOptions).then(handleResponse);
};
// Convert svg to eps
const convertSvgToRasterizedEps = async (epsFiles) => {
  const formData = new FormData();

  formData.append('file', epsFiles);
  formData.append('name', 'filename');

  const url = `${apiConstants.apiUrl}/svg-to-eps-rasterized/`;

  const requestOptions = {
    method: 'POST',
    body: formData,
  };

  return fetch(url, requestOptions).then(handleResponse);
};

const convertSvgToRasterizedEpsSecondary = async (epsFiles) => {
  const formData = new FormData();

  formData.append('file', epsFiles);
  formData.append('name', 'filename');

  const url = `${apiConstants.apiUrl}/svg-to-eps-rasterized-secondary/`;

  const requestOptions = {
    method: 'POST',
    body: formData,
  };

  return fetch(url, requestOptions).then(handleResponse);
};

const convertSvgToRasterizedEpsV3 = async (epsFiles) => {
  const formData = new FormData();

  formData.append('file', epsFiles);
  formData.append('name', 'filename');

  const url = `${apiConstants.apiUrl}/svg-to-eps-rasterized-v3/`;

  const requestOptions = {
    method: 'POST',
    body: formData,
  };

  return fetch(url, requestOptions).then(handleResponse);
};

const convertSvgToRasterizedEpsV3ViaPdf = async (epsFiles) => {
  const formData = new FormData();

  formData.append('file', epsFiles);
  formData.append('name', 'filename');

  const url = `${apiConstants.apiUrl}/svg-to-eps-rasterized-v3-pdf/`;

  const requestOptions = {
    method: 'POST',
    body: formData,
  };

  return fetch(url, requestOptions).then(handleResponse);
};

// Convert svg to eps
const convertSvgToPdf = async (epsFiles) => {
  const formData = new FormData();

  formData.append('file', epsFiles);
  formData.append('name', 'filename');

  const url = `${apiConstants.apiUrl}/svg-to-pdf/`;

  const requestOptions = {
    method: 'POST',
    body: formData,
  };

  return fetch(url, requestOptions).then(handleResponse);
};
// Convert svg with url to svg
const processSvgWithUrl = async (epsFiles) => {
  const formData = new FormData();

  formData.append('file', epsFiles);
  formData.append('name', 'filename');

  const url = `${apiConstants.apiUrl}/process-svg-url/`;

  const requestOptions = {
    method: 'POST',
    body: formData,
  };

  return fetch(url, requestOptions).then(handleResponse);
};

// Convert svg with url to svg-v2
const processSvgWithUrlV2 = async (epsFiles) => {
  const formData = new FormData();

  formData.append('file', epsFiles);
  formData.append('name', 'filename');

  const url = `${apiConstants.apiUrl}/process-svg-url-v2/`;

  const requestOptions = {
    method: 'POST',
    body: formData,
  };

  return fetch(url, requestOptions).then(handleResponse);
};

const processWithBinPackerAlgo = async (payload) => {
  const url = `${apiConstants.apiUrl}/bin-packer-algo/`;

  const requestOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(payload),
  };

  return fetch(url, requestOptions).then(handleResponse);
};

const generateDtfConfirmation = (payload) => {
  const url = `${apiConstants.apiUrl}/generate-confirmation-pdf`;
  const requiredOptions = {
    method: 'POST',
    headers: authHeader(),
    body: JSON.stringify(payload),
  };

  return fetch(url, requiredOptions).then(handleResponse);
};

export const imageServices = {
  generateDtfConfirmation,
  removeImageBackground,
  upscaleImage,
  fileUrlToBlob,
  convertEpsToSvg,
  convertSvgToRasterizedEps,
  convertSvgToRasterizedEpsSecondary,
  convertSvgToRasterizedEpsV3,
  convertSvgToRasterizedEpsV3ViaPdf,
  // convertMultipleEpsToSvg,
  addUploadedImages,
  convertSvgToEps,
  processSvgWithUrl,
  processSvgWithUrlV2,
  convertSvgToPdf,
  processWithBinPackerAlgo,
};
