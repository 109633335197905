export const TRANSFORM_ACTION = {
  SCROLL_UP: 'SCROLL_UP',
  SCROLL_DOWN: 'SCROLL_DOWN',
  SCROLL_RIGHT: 'SCROLL_RIGHT',
  SCROLL_LEFT: 'SCROLL_LEFT',
  ZOOM_IN: 'ZOOM_IN',
  ZOOM_OUT: 'ZOOM_OUT',
  RESET_ZOOM: 'RESET_ZOOM',
  RESET_SCROLL: 'RESET_SCROLL',
  RESET_ALL: 'RESET_ALL',
  SET_ZOOM: 'SET_ZOOM',
};

export const GANG_SHEET_SIZE = {
  WH2224: { key: 'W22H24', label: `22" x 24"`, width: 22, height: 24 },
  WH2260: { key: 'W22H60', label: `22" x 60"`, width: 22, height: 60 },
  WH22120: { key: 'W5H10', label: `5" x 10"`, width: 5, height: 10 },
  W21P65H39P3701: {
    key: 'W21P65H39P3701',
    label: '21.65" X 39.37"',
    width: 22.04724409448819,
    height: 39.3701,
  },
  W22P44H39P3701: {
    key: 'W22P44H39P3701',
    label: '22.44" X 39.37"',
    width: 22.44094488189,
    height: 39.3701,
  }, // 570mm x 1000mm
};

export const getLabelInCustomUnit = (width, height, unitDiff, unit = 'mm') => {
  return `${parseInt(width * unitDiff)}mm x ${parseInt(height * unitDiff)}mm`;
};

export const GANG_SHEET_SIZE_OPTIONS = [
  {
    label: `22"x24"`,
    value: { width: 22, height: 24 },
    key: 'W22H24',
    isDefault: false,
  },
  {
    label: `22"x60"`,
    value: { width: 22, height: 60 },
    key: 'W22H60',
    isDefault: false,
  },
  {
    label: `22"x120"`,
    value: { width: 22, height: 120 },
    key: 'W22H120',
    isDefault: false,
  },
  {
    label: `5"x10"`,
    value: { width: 5, height: 10 },
    key: 'W5H10',
    isDefault: false,
  },
  {
    label: '21.65" X 39.37"',
    value: { width: 22.04724409448819, height: 39.3701 },
    key: 'W21P65H39P3701',
    isDefault: false,
  },
  {
    label: '22.44" X 39.37"',
    value: { width: 22.44094488189, height: 39.3701 },
    key: 'W22P44H39P3701',
    isDefault: true,
  },
];

export const transformStateInIt = {
  x: 0,
  y: 0,
  z: 0,
};

export const transformStateReducer = (state, action) => {
  switch (action.type) {
    case TRANSFORM_ACTION.SCROLL_UP:
      return {
        ...state,
        y: state.y - action.scrolledDistance,
      };
    case TRANSFORM_ACTION.SCROLL_DOWN:
      return {
        ...state,
        y: state.y + action.scrolledDistance,
      };
    case TRANSFORM_ACTION.SCROLL_LEFT:
      return {
        ...state,
        x: state.x - action.scrolledDistance,
      };
    case TRANSFORM_ACTION.SCROLL_RIGHT:
      return {
        ...state,
        x: state.x + action.scrolledDistance,
      };
    case TRANSFORM_ACTION.ZOOM_IN:
      return {
        ...state,
        z: state.currentZoomScale + (action?.zoomScale ?? 1.2),
      };
    case TRANSFORM_ACTION.ZOOM_OUT:
      return {
        ...state,
        z: state.currentZoomScale - (action?.zoomScale ?? 0.2),
      };
    case TRANSFORM_ACTION.RESET_ZOOM:
      return {
        ...state,
        z: 1,
      };
    case TRANSFORM_ACTION.RESET_ALL:
      return {
        ...transformStateInIt,
      };
    case TRANSFORM_ACTION.SET_ZOOM:
      return {
        ...state,
        z: action.zoomScale,
      };
    default:
      return state;
  }
};

// export function isOverlapping(img1, img2) {
//   return !(
//     img1.x + img1.width < img2.x ||
//     img1.x > img2.x + img2.width ||
//     img1.y + img1.height < img2.y ||
//     img1.y > img2.y + img2.height
//   );
// }

export function findOverlaps(images) {
  const overlappingPairs = [];
  for (let i = 0; i < images.length; i++) {
    for (let j = i + 1; j < images.length; j++) {
      if (isOverlapping(images[i], images[j])) {
        overlappingPairs.push([images[i], images[j]]);
      }
    }
  }
  return overlappingPairs;
}

// export function applyTransform(ctx, viewportData) {
//   ctx.setTransform(
//     viewportData.scale,
//     0,
//     0,
//     viewportData.scale,
//     viewportData.x,
//     viewportData.y
//   );
// }

export function applyTransform(ctx, viewportData) {
  const { x, y, scale, width, height } = viewportData;

  ctx.clearRect(0, 0, ctx.canvas.width, ctx.canvas.height);

  ctx.setTransform(
    scale,
    0,
    0,
    scale,
    // Translate based on the center of the viewport
    x + (width / 2) * (1 - scale),
    y + (height / 2) * (1 - scale)
  );
}

// Image Redraw Functions

// export function drawImage(image, ctx) {
//   image.onload = () => {
//     ctx.drawImage(image, x + item.x, y + item.y, imgWidth, imgHeight);
//   };
// }

export function findImagePositionAndSize(item, canvasConfig, allItems) {
  const { availableWidth, availableHeight } = canvasConfig;

  const calcObj = {};

  const position = findFitPosition(
    availableWidth,
    availableHeight,
    item.width,
    item.height,
    allItems
  );

  // if (position?.x && position?.y) {
  calcObj.x = position?.x ? position.x : item.x;
  calcObj.y = position?.y ? position.y : item.y;
  // }

  // TODO: find x and y position of the image based on viewport size

  return { ...calcObj, isImage: true };
}

export function calculateItemPositionAndSize(item, canvasConfig, allItems) {
  // if (item.type === 'image') {
  return findImagePositionAndSize(item, canvasConfig, allItems);
  // }
}

function findFitPosition(
  canvasWidth,
  canvasHeight,
  imageWidth,
  imageHeight,
  placedItems
) {
  let position = null;

  // 486 78.05454545454546 407.94545454545454 413.1000000000001 FitChekc

  // Iterate over potential starting positions
  for (let y = 0; y <= canvasHeight - imageHeight; y++) {
    for (let x = 0; x <= canvasWidth - imageWidth; x++) {
      let fits = true;

      // Check if the current position overlaps with any placed item
      for (let item of placedItems) {
        if (isOverlapping(x, y, imageWidth, imageHeight, item)) {
          fits = false;
          break;
        }
      }

      if (fits) {
        position = { x, y };
        break;
      }
    }
    if (position) break;
  }

  return position;
}

// function findFitPosition(
//   canvasWidth,
//   canvasHeight,
//   imageWidth,
//   imageHeight,
//   placedItems
// ) {
//   let position = null;

//   // Check for fit in both original and rotated orientations
//   function canFit(x, y, w, h) {
//     for (let item of placedItems) {
//       if (isOverlapping(x, y, w, h, item)) {
//         return false;
//       }
//     }
//     return true;
//   }

//   // Iterate over potential starting positions
//   for (let y = 0; y <= canvasHeight - Math.min(imageHeight, imageWidth); y++) {
//     for (let x = 0; x <= canvasWidth - Math.min(imageHeight, imageWidth); x++) {
//       // Check original orientation
//       if (
//         x <= canvasWidth - imageWidth &&
//         y <= canvasHeight - imageHeight &&
//         canFit(x, y, imageWidth, imageHeight)
//       ) {
//         position = { x, y, rotated: false };
//         break;
//       }

//       // Check rotated orientation
//       if (
//         x <= canvasWidth - imageHeight &&
//         y <= canvasHeight - imageWidth &&
//         canFit(x, y, imageHeight, imageWidth)
//       ) {
//         position = { x, y, rotated: true };
//         break;
//       }
//     }
//     if (position) break;
//   }

//   return position;
// }

function isOverlapping(x1, y1, w1, h1, item) {
  // Check if the image overlaps with the item
  return !(
    x1 + w1 <= item.x ||
    x1 >= item.x + item.width ||
    y1 + h1 <= item.y ||
    y1 >= item.y + item.height
  );
}

// DRAWING FUCNTIONS

// END DRAWING

// End Of Image Redraw Functions
