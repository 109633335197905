import React from 'react';

function AlertActionPopUp({
  message,
  title,
  positiveButtonText = 'Yes',
  negativeButtonText = 'No',
  onSubmit = () => {},
  onClose = () => {},
}) {
  if (!message) {
    return null;
  }
  return (
    <div
      style={{
        position: 'fixed',
        zIndex: 99999,
        userSelect: 'none',
        inset: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(0,0,0,0.5)',
      }}
    >
      <div
        style={{
          backgroundColor: 'white',
          padding: 15,
          display: 'flex',
          minWidth: 300,
          gap: 10,
        }}
      >
        <div className="w-100">
          <div style={{ fontWeight: 600 }}>{title}</div>
          <div className="mt-1" style={{ fontSize: 14 }}>
            {message}
          </div>
          <div className="d-flex gap-2 mt-3 justify-content-end">
            <button
              style={{ fontSize: 14 }}
              className="default-btn"
              onClick={onClose}
            >
              {negativeButtonText}
            </button>
            <button
              style={{ fontSize: 14 }}
              className="default-btn primary-bg-color"
              onClick={() => {
                onSubmit();
                onClose();
              }}
            >
              {positiveButtonText}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AlertActionPopUp;
