// import CircularAvatar from '../Myteam/CircularAvatar';
// --Project Name convert into project-name
import * as fabric from 'fabric';
import { canvasConstants, canvasUtils } from '../Test/canvasUtils';
export const sanitizeFileName = (fileName) => {
  // Remove special characters, spaces, dots, %, and #
  const sanitizedFileName = fileName.replace(/[^\w.%#-\s]/g, '');
  // Remove all spaces
  const fileNameWithoutSpaces = sanitizedFileName.replace(/\s/g, '');
  // Remove file extension
  const fileNameWithoutExtension = fileNameWithoutSpaces
    .split('.')
    .slice(0, -1)
    .join('');
  return fileNameWithoutExtension;
};

export const getFileType = (extension) => {
  const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg'];
  const videoExtensions = ['mp4', 'avi', 'mkv', 'mov', 'wmv', 'flv'];
  const audioExtensions = ['mp3', 'wav', 'aac', 'ogg', 'wma', 'flac'];
  const pdfExtensions = ['pdf'];

  const lowerCaseExtension = extension.toLowerCase();

  if (imageExtensions.includes(lowerCaseExtension)) {
    return 'image';
  } else if (videoExtensions.includes(lowerCaseExtension)) {
    return 'video';
  } else if (audioExtensions.includes(lowerCaseExtension)) {
    return 'audio';
  } else if (pdfExtensions.includes(lowerCaseExtension)) {
    return 'pdf';
  } else {
    return 'document';
  }
};

export const loadFabricJsonData = (jsonData) => {
  // const clonedData = { ...jsonData };
  return new Promise(async (resolve, reject) => {
    // Parse the JSON string into an object if necessary
    if (typeof jsonData === 'string') {
      jsonData = JSON.parse(jsonData);
    }

    // Extract the objects from the JSON data
    try {
      const enlivenedObjects = await fabric.util.enlivenObjects(
        jsonData.objects
      );
      resolve(enlivenedObjects);
    } catch (err) {
      reject(err);
    }
  });
};

export const getOriginalValuesFromFabricObject = ({
  allObjects = [],
  viewportMeasures,
  marginsInUnit,
  selectedUnit,
}) => {
  const { artBoardMargin, imageMargin } = marginsInUnit;
  const { availableHeight, availableWidth, scale } = viewportMeasures;

  const canvasDetails = {
    availableHeight: parseInt(availableHeight / scale),
    availableWidth: parseInt(availableWidth / scale),
    margins: {
      artBoardMargin:
        (artBoardMargin * canvasConstants.CANVAS_DPI) / selectedUnit.value,
      imageMargin:
        (imageMargin * canvasConstants.CANVAS_DPI) / selectedUnit.value,
    },
  };

  const objects = allObjects
    .filter((item) => item.noOfItems > 0)
    .map((item) => {
      const isSvg = item?.isSvg ?? false;

      const adjustedScale = isSvg
        ? scale * (canvasConstants.CANVAS_DPI / canvasConstants.CANVAS_SVG_DPI)
        : scale;

      const effectiveScaleX = item.scaleX / adjustedScale;
      const effectiveScaleY = item.scaleY / adjustedScale;

      const width = isSvg
        ? item.width *
          (canvasConstants.CANVAS_DPI / canvasConstants.CANVAS_SVG_DPI)
        : item.width;
      const height = isSvg
        ? item.height *
          (canvasConstants.CANVAS_DPI / canvasConstants.CANVAS_SVG_DPI)
        : item.height;
      return {
        noOfItems: item.noOfItems,
        isSvg: item.isSvg,
        width: width * effectiveScaleX,
        height: height * effectiveScaleY,
        originalWidth: item.width,
        originalHeight: item.height,
        name: item.name,
        src: getSource(item),
        scaleX: item.scaleX,
        scaleY: item.scaleY,
      };
    });

  return { canvasDetails, objects };
};

const getSource = (item) => {
  if (item?._originalElement?.src) {
    return item._originalElement.src;
  }
  return item.src;
};

export const getScaledValueFromOriginalObject = ({ objects = [], scale }) => {
  return objects.map((bin) => {
    return {
      ...bin,
      objects: bin.objects.map((obj) => {
        return {
          ...obj,
          x: obj.x * scale,
          y: obj.y * scale,
          width: obj.width * obj.scaleX,
          height: obj.height * obj.scaleY,
        };
      }),
    };
  });
};

export function isSvgUrl(url) {
  if (!url) {
    return false;
  }

  // Extract the part before the query parameters (if any)
  const cleanUrl = url.split('?')[0];

  // Check if the URL ends with .svg (case-insensitive)
  return cleanUrl.toLowerCase().endsWith('.svg');
}

export function hexToNormalizedRgb(hex) {
  // Remove the # if present
  hex = hex.replace(/^#/, '');

  // Parse the hex color to RGB
  let bigint = parseInt(hex, 16);
  let r = (bigint >> 16) & 255;
  let g = (bigint >> 8) & 255;
  let b = bigint & 255;

  // Normalize each component to a range of 0 to 1
  return {
    r: r / 255,
    g: g / 255,
    b: b / 255,
  };
}

export const getAllObject = (
  canvas,
  currentSheetName,
  sheetMap,
  initCanvasMeasures
) => {
  // const objects = [];

  const sheetMapArray = Object.entries(sheetMap)
    .map(([key, value]) => ({ ...value, sheetName: key }))
    .filter(
      (item) =>
        item.sheetName !== currentSheetName &&
        item?.lastInitMeasures?.scale?.toFixed(2) ===
          initCanvasMeasures?.scale?.toFixed(2)
    );

  const [...restObjects] = canvas.getObjects();
  const otherSheetObject = sheetMapArray.flatMap(
    (item) => JSON.parse(item.canvas).objects
  );
  const allObjects = [...restObjects, ...otherSheetObject];

  console.log(restObjects, otherSheetObject, 'OBJECTSSS');

  const selectionList = canvasUtils.generatePlainObject({
    canvasItemList: allObjects ?? [],
    viewPortMeasures: initCanvasMeasures,
  });

  console.log(selectionList, 'selectionListt');

  return selectionList ?? [];
};
