// src/NearestColorFinder.js

import React, { useState } from 'react';
import { ColorBoxs } from './ColorBoxs';
import { findNearestColors, hexToRgb } from '../_miscFunction';

const NearestColorFinder = () => {
  const [hex, setHex] = useState('#aabbcc');
  const [rgb, setRgb] = useState({ R: 170, G: 187, B: 204 });
  const [nearestColors, setNearestColors] = useState([]);

  const handleHexInputChange = (event) => {
    const color = event.target.value;
    setHex(color);
    if (color.length === 7 && /^#[0-9A-Fa-f]{6}$/i.test(color)) {
      const rgb = hexToRgb(color);
      setRgb(rgb);
    }
  };

  const onSubmite = () => {
    // Dummy function for nearest colors
    // Replace with actual logic or API call

    setNearestColors(findNearestColors(rgb));
  };

  return (
    <div className="card mb-4">
      <div className="card-header">Nearest Color Finder</div>
      <div className="card-body">
        <input
          type="text"
          className="form-control mb-3"
          value={hex}
          onChange={handleHexInputChange}
          maxLength="7"
        />
        <p className="card-text">
          RGB: ({rgb.R}, {rgb.G}, {rgb.B})
        </p>
        <button className="btn btn-primary mb-3" onClick={onSubmite}>
          Find Nearest Colors
        </button>
        <ColorBoxs colors={nearestColors} />
      </div>
    </div>
  );
};

export default NearestColorFinder;
