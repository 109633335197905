import React, { useState } from 'react';
import { imageServices } from './service/image.service';

function BackgroundRemoval() {
  const [image, setImage] = useState({
    image: null,
    originalImageUrl: null,
    processedUrl: null,
  });
  const [isLoading, setIsLoading] = useState(false);

  const localApiRoot = `http://localhost:8000`;

  const handleChange = (event) => {
    const file = event.target.files[0];

    setImage((prev) => ({ ...prev, image: file }));
  };

  const handleSubmit = async (event) => {
    if (!image) {
      alert('Please select an image');
      return;
    }
    try {
      setIsLoading(true);
      const result = await imageServices.removeImageBackground(image.image);
      setImage((prev) => ({
        ...prev,
        originalImageUrl: result.original_image,
        processedUrl: result.processed_image,
      }));
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="card">
      <div className="card-header">
        <div>Remove Background</div>
      </div>
      <div className="card-body">
        <input
          onChange={handleChange}
          type="file"
          className="my-2"
          accept="images/*"
        />
        {image.image && (
          <>
            <div>
              <img
                src={URL.createObjectURL(image.image)}
                height={300}
                alt="uploaded-image"
              />
            </div>
            <button
              className="mt-2 btn btn-primary"
              onClick={() => handleSubmit()}
            >
              Remove Bg
            </button>
          </>
        )}
        {isLoading && <div>Loading....</div>}
        <div className="d-flex gap-3 ">
          {image.originalImageUrl && (
            <div>
              <div className="my-2">Original Image</div>

              <img
                src={localApiRoot + image.originalImageUrl}
                //   width={250}
                height={300}
                alt="uploaded-image"
              />
            </div>
          )}
          {image.processedUrl && (
            <div>
              <div className="my-2">Processed Image</div>

              <img
                src={localApiRoot + image.processedUrl}
                height={300}
                alt="uploaded-image"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
}

export default BackgroundRemoval;
