import React, { useState } from 'react';
import Upscaler from 'upscaler';
import { BeforeAfter } from './BeforeAfter';

const ImageUpscaler = () => {
  const [upscaledImage, setUpscaledImage] = useState(null);
  const [originalImage, setOriginalImage] = useState(null);
  const [scale, setScale] = useState(1);
  const [imageDimensions, setImageDimensions] = useState({
    original: null,
    upscaled: null,
  });

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const img = new Image();
        img.onload = () => {
          setImageDimensions((prev) => ({
            ...prev,
            original: { width: img.width, height: img.height },
          }));
          setOriginalImage(reader.result);
          handleUpscale(reader.result, scale, img.width, img.height);
        };
        img.src = reader.result;
      };
      reader.readAsDataURL(file);
    }
  };

  const handleUpscale = async (
    imageSrc,
    scale,
    originalWidth,
    originalHeight
  ) => {
    const upscaler = new Upscaler();
    let newScale = scale;
    let newWidth = originalWidth * scale;
    let newHeight = originalHeight * scale;
    console.log('newWidth', newWidth);
    console.log('newHeight', newHeight);
    console.log('originalWidth', originalWidth);

    if (newWidth > 800 || newHeight > 800) {
      alert('Image size is too large');
      return;
    }

    console.log('newWidth', newWidth);
    console.log('newHeight', newHeight);
    console.log('originalWidth', originalWidth);
    console.log('newScale', newScale);
    console.log('TIme Before', new Date().getTime());
    const upscaledSrc = await upscaler.upscale(imageSrc, { scale: newScale });
    console.log('TIme After', new Date().getTime());
    setUpscaledImage(upscaledSrc);
    setImageDimensions((prev) => ({
      ...prev,
      upscaled: { width: newWidth, height: newHeight },
    }));
  };

  const handleScaleChange = (e) => {
    const newScale = parseInt(e.target.value, 10);
    setScale(newScale);
    if (originalImage) {
      const img = new Image();
      img.onload = () => {
        handleUpscale(originalImage, newScale, img.width, img.height);
      };
      img.src = originalImage;
    }
  };

  return (
    <div>
      <h2>Image Upscaler</h2>

      <input type="file" accept="image/*" onChange={handleFileChange} />
      <label htmlFor="scale">Select upscale factor:</label>
      <select id="scale" value={scale} onChange={handleScaleChange}>
        <option value="1">1x</option>
        <option value="2">2x</option>
        <option value="3">3x</option>
      </select>
      <div style={{ display: 'flex', marginTop: '20px' }}>
        <div>
          <h3>Original Image</h3>
          {originalImage && (
            <>
              <img src={originalImage} alt="Original" width="300" />
              <p>
                Size: {imageDimensions.original?.width} x{' '}
                {imageDimensions.original?.height}
              </p>
            </>
          )}
        </div>
        <div>
          <h3>Upscaled Image</h3>
          {upscaledImage && (
            <>
              <img src={upscaledImage} alt="Upscaled" width="300" />
              <p>
                Size: {imageDimensions.upscaled?.width} x{' '}
                {imageDimensions.upscaled?.height}
              </p>
            </>
          )}
        </div>
      </div>

      {!!originalImage && !!upscaledImage && (
        <BeforeAfter beforeImage={originalImage} afterImage={upscaledImage} />
      )}
    </div>
  );
};

export default ImageUpscaler;
