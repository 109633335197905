import React, { useState } from 'react';
import useOutsideClickHandler from '../Hooks/useOutsideClickHandler';

const CustomDropDown = ({
  value,
  label = null,
  onChange,
  options = [],
  getOptionsLabel = () => {},
  getOptionsValue = () => {},
  hasCustomOption = false,
  renderCustomOption = () => {},
  minWidth = 120,
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const { containerRef } = useOutsideClickHandler({
    onClose: () => setIsDropdownOpen(false),
  });
  const [isCustomPopoverOpen, setIsCustomPopoverOpen] = useState(false);

  const selectedOption =
    options?.find((option) => value === getOptionsValue(option)) ?? options[0];

  const selectedLabel = getOptionsLabel(selectedOption);

  const handleSelect = (selectedOption) => {
    onChange(selectedOption);
    setIsDropdownOpen(false);
  };

  const onCustomPopoverClose = () => {
    setIsCustomPopoverOpen(false);
  };

  return (
    <div
      ref={containerRef}
      style={{ display: 'flex', alignItems: 'center', position: 'relative' }}
    >
      {label && (
        <div style={{ marginRight: 8, fontSize: 16, color: '#555' }}>
          {label}
        </div>
      )}
      <div
        style={{
          display: 'flex',
          border: '1px solid #ccc',
          borderRadius: 4,
          padding: '4px 12px',
          backgroundColor: '#f7f7f7',
          minWidth: minWidth,
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          fontWeight: 600,
        }}
        onClick={() => setIsDropdownOpen(!isDropdownOpen)}
      >
        {selectedLabel}
      </div>
      {isDropdownOpen && (
        <div
          style={{
            position: 'absolute',
            top: '100%',
            left: 0,
            zIndex: 9999,
            border: '1px solid #ccc',
            borderRadius: 4,
            backgroundColor: '#fff',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
            width: '100%',
            marginTop: 4,
          }}
        >
          {hasCustomOption && (
            <div
              style={{
                padding: '8px 12px',
                cursor: 'pointer',
                fontSize: 14,
                color: '#333',
                backgroundColor: '#fff',
                borderBottom: 'none',
              }}
              onClick={() => {
                setIsDropdownOpen(false);
                setIsCustomPopoverOpen(true);
              }}
            >
              Custom Input
            </div>
          )}
          {options.map((option, index) => {
            const optionLabel = getOptionsLabel(option);
            const optionValue = getOptionsValue(option);
            return (
              <div
                key={index}
                onClick={() => handleSelect(option)}
                style={{
                  padding: '8px 12px',
                  cursor: 'pointer',
                  fontSize: 14,
                  color: '#333',
                  backgroundColor:
                    optionValue === getOptionsValue(selectedOption)
                      ? '#e9e9e9'
                      : '#fff',
                  borderBottom:
                    index !== options.length - 1 ? '1px solid #eee' : 'none',
                }}
              >
                {optionLabel}
              </div>
            );
          })}
        </div>
      )}
      {hasCustomOption &&
        isCustomPopoverOpen &&
        renderCustomOption({ onClose: onCustomPopoverClose })}
    </div>
  );
};

export default CustomDropDown;
